import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import axios from "../../../util/axiosInst";
import { toast } from "react-toastify";
import {
  deleteSuccessMessage,
  unknownErrorMessage,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import Pagination from "../custom/Pagination"
const Accounts = ({ search }) => {
  const navigate = useNavigate();
  const [contents, setContents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const fetchContents = async (page) => {
    try {
      const searchCondition = search?.length ? `name=${search}` : "";
      const { data } = await axios.get(`/v1/user?role=admin&deleted=false&page=${page}&limit=10&${searchCondition}&sortBy=updatedAt:desc`)
      setContents(data)
      setCurrentPage(page)
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };
  const handleEditContent = async (id) => {
    navigate(`/edit-admin/${id}`);
  };
  const handleDeleteContent = async () => {
    if (deleteId) {
      try {
        await axios.delete(`v1/user/${deleteId}`);
        toast.success(deleteSuccessMessage);
        fetchContents(contents?.results?.length === 1 ? currentPage - 1 : currentPage);
        handleClose();
      } catch (error) {
        fetchContents(currentPage);
        handleClose();
      }
    }
  };
  useEffect(() => {
    fetchContents(1);
  }, [search]);
  return (
    <div className="mt-3 mx-3 rounded px-3 bg-white">
      <Table className=" ms-1 rounded">
        <thead className="border-bottom">
          <th className="text-body-tertiary pt-4 ">NAME</th>
          <th className="text-body-tertiary text-center">EMAIL</th>
          <th className="text-body-tertiary ">ACTIVE</th>
          <th className="text-body-tertiary text-end">ACTION</th>
        </thead>
        <tbody style={{ borderTop: "0px" }} className="mt-5">
          {contents && contents?.results?.length ? (
            contents?.results?.map((appointment, index) => (
              <tr>
                <td>{appointment?.name}</td>
                <td className="text-center ">{appointment?.email}</td>
                <td>
                  {appointment.active ? (
                    <span className="text-green">YES</span>
                  ) : (
                    <span className="text-danger">NO</span>
                  )}
                </td>
                {/* <td className=" text-center">
                <BsThreeDotsVertical className="text-body-tertiary" />
              </td> */}
                <td className="fontweight-bold h5  table-drop py-2 px-0">
                  <Dropdown className="text-end">
                    <Dropdown.Toggle className="threedrop " id="dropdown-basic">
                      <BsThreeDotsVertical className="text-body-tertiary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => { handleEditContent(appointment.id) }}
                        className="cursor-pointer"
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        handleShow();
                        setDeleteId(appointment?.id);
                      }}
                        className="cursor-pointer">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>)))
            : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
        </tbody>
      </Table>
      <Pagination
        onPageChange={(event) => fetchContents(event.selected + 1)}
        pageRangeDisplayed={10}
        totalPages={contents?.totalResults / 10}
        currentPageNo={currentPage - 1}
      />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteContent}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  );
};

export default Accounts;

import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { Tab, Tabs } from "react-bootstrap";
import Request from "./Request";
import Completed from "./Completed";
import { FaSearch } from "react-icons/fa";
import axios from "../../utils/axiosInst";

const Appoinment = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currenttab, setcurrenttab] = useState("Requests");

  const [requestappointments, setrequestappointments] = useState([]);
  const getRequestappointmentdata = async (e, search) => {
    const { data } = await axios.get(
      search.length > 2
        ? `/v1/schedule/appointment?limit=10&sortBy=updatedAt:desc&page=${e}&status=REQUESTED&name=${search}`
        : `/v1/schedule/appointment?limit=10&sortBy=updatedAt:desc&page=${e}&status=REQUESTED`
    );
    setrequestappointments(data);
  };

  const [scheduledata, setscheduledata] = useState([]);
  const getscheduledata = async (e, search) => {
    const { data } = await axios.get(
      search.length > 2
        ? `/v1/schedule/appointment?limit=10&sortBy=createdAt:desc&page=${e}&status=ACCEPTED&name=${search}`
        : `/v1/schedule/appointment?limit=10&sortBy=createdAt:desc&page=${e}&status=ACCEPTED`
    );
    setscheduledata(data);
  };

  const [completedppointments, setcompletedppointments] = useState([]);
  const getcompletedppointments = async (e, search) => {
    const { data } = await axios.get(
      search.length > 2
        ? `/v1/schedule/appointment?limit=10&sortBy=createdAt:desc&page=${e}&status=COMPLETED&name=${search}`
        : `/v1/schedule/appointment?limit=10&sortBy=createdAt:desc&page=${e}&status=COMPLETED`
    );
    setcompletedppointments(data);
  };

  const searchfunc = (data) => {
    setSearchTerm(data);
    if (data.length > 2) {
      if (currenttab === "Requests") {
        getRequestappointmentdata(1, data);
      }
      if (currenttab === "Scheduled") {
        getscheduledata(1, data);
      }
      if (currenttab === "Completed") {
        getcompletedppointments(1, data);
      }
    } else if (data.length < 1) {
      if (currenttab === "Requests") {
        getRequestappointmentdata(1, "");
      }
      if (currenttab === "Scheduled") {
        getscheduledata(1, "");
      }
      if (currenttab === "Completed") {
        getcompletedppointments(1, "");
      }
    }
  };

  const tabswitchfuc = (tab) => {
    if (currenttab !== tab) {
      if (tab === "Requests") {
        getRequestappointmentdata(1, "");
      }
      if (tab === "Scheduled") {
        getscheduledata(1, "");
      }
      if (tab === "Completed") {
        getcompletedppointments(1, "");
      }
      setcurrenttab(tab);
      setSearchTerm("");
    }
  };

  useEffect(() => {
    getRequestappointmentdata(1, "");
  }, []);

  return (
    <Layout h1="Appointments">
      <div
        className="float-right bg-white px-2"
        style={{ paddingTop: "11.5px", paddingBottom: "11px" }}
      >
        <div className="d-flex me-4">
          {searchTerm.length < 1 && (
            <FaSearch
              style={{
                transform: "translate(93px,5px)",
                color: "#AFAFAF",
              }}
            />
          )}
          <input
            type="text"
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => searchfunc(e.target.value)}
            className="ms-2 px-3 bg-super-light border-0 text-center borderradius-22px"
          />
        </div>
      </div>
      <Tabs
        defaultActiveKey={currenttab}
        className="pt-1 bg-white fontweight-bold text-black"
        variant="pills"
        onSelect={(e) => tabswitchfuc(e)}
      >
        <Tab eventKey="Requests" title="Requests">
          <div className="mt-3 mx-3 rounded px-3 bg-white">
            <Request
              getscheduledata={getscheduledata}
              searchTerm={searchTerm}
              requestappointments={requestappointments}
              getRequestappointmentdata={getRequestappointmentdata}
            />
          </div>
        </Tab>
        {/* <Tab eventKey="Scheduled" title="Scheduled" className="bg-super-light ">
          <div className="mt-3 mx-3 rounded px-3 bg-white">
            <Scheduled
              getscheduledata={getscheduledata}
              scheduledata={scheduledata}
              searchTerm={searchTerm}
            />
          </div>
        </Tab> */}
        <Tab eventKey="Completed" title="Completed ">
          <div className="mt-3 mx-3 rounded px-3 bg-white">
            <Completed
              completedppointments={completedppointments}
              getcompletedppointments={getcompletedppointments}
              searchTerm={searchTerm}
            />
          </div>
        </Tab>
      </Tabs>
    </Layout>
  );
};

export default Appoinment;

import axios from "../../../util/axiosInst";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteSuccessMessage } from "../../../util/constants";
import Pagination from "../custom/Pagination";
import { unknownErrorMessage } from "../../utils/constants";

const Created = ({ search }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [certifications, setCertifications] = useState([{}]);
  const getCertification = async (page) => {
    try {
      const searchCondition = search?.length ? `governingBody=${search}` : "";
      const { data } = await axios.get(`/v1/certification?&page=${page}&limit=10&${searchCondition}&sortBy=updatedAt:desc`);
      setCertifications(data);
      setCurrentPage(page)
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };
  const handleEditContent = async (id) => {
    navigate(`/editCertificate/${id}`);
  };
  const handleDeleteContent = async () => {
    if (deleteId) {
      try {
        await axios.delete(`v1/certification/${deleteId}`);
        toast.success(deleteSuccessMessage);
        getCertification(certifications?.results?.length === 1 ? currentPage - 1 : currentPage);
        handleClose();
      } catch (error) {
        getCertification(currentPage);
        handleClose();
      }
    }
  };
  useEffect(() => {
    getCertification(1);
  }, [search]);
  return (
    <div>
      <Table className=" ms-1 fs-13px">
        <thead className="border-bottom">
          <th className="text-body-tertiary pt-4 ">GOVERNING BODY</th>
          <th className="text-body-tertiary text-center">CERTIFICATION</th>
          <th className="text-body-tertiary text-end">ACTION</th>
        </thead>
        <tbody style={{ borderTop: "0px" }} className="mt-5">
          {certifications && certifications?.results?.length ? (
            certifications?.results?.map((certification) => (
              <tr>
                <td>{certification?.governingBody}</td>
                <td className="text-center">{certification?.certification}</td>
                {/* <td className="text-end">
              <BsThreeDotsVertical className="text-body-tertiary" />
            </td> */}
                <td className="fontweight-bold h5  table-drop py-2 px-0">
                  <Dropdown className="text-end">
                    <Dropdown.Toggle className="threedrop " id="dropdown-basic">
                      <BsThreeDotsVertical className="text-body-tertiary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => { handleEditContent(certification?.id) }}
                        className="cursor-pointer"
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        handleShow();
                        setDeleteId(certification?.id);
                      }}
                        className="cursor-pointer">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))) : (
            <tr>
              <td colSpan="6" className="text-center">
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination
        onPageChange={(event) => getCertification(event.selected + 1)}
        pageRangeDisplayed={10}
        totalPages={certifications?.totalResults / 10}
        currentPageNo={currentPage - 1}
      />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteContent}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Created;

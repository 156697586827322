import React, { useState } from "react";
import Layout from "../layout/Layout";
import { Tab, Tabs } from "react-bootstrap";
import Accounts from "./Accounts";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "../../utils/axiosInst";
import * as XLSX from "xlsx";
import { map } from "lodash";
const Admin = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const downloadExcel = async () => {
    let response;
    response = await axios.get(
      `/v1/user?role=admin&deleted=false&sortBy=updatedAt:desc&limit=100000`
    );
    const worksheet = XLSX.utils.json_to_sheet(
      map(
        response?.data?.results,
        ({ firstName, lastName, email, active, createdAt }) => ({
          Name: firstName + " " + lastName,
          Email: email,
          "Active": active === true ? "YES" : "NO",
          "Created Date": moment(createdAt).format("ll")
        })
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `admin_${moment().format("YYYY_MM_DD")}.xlsx`);
  };
  return (
    <Layout h1="Admin">
      <div className="float-right bg-white py-2">
        <div className="d-flex me-4">
          {searchTerm.length < 1 && (<FaSearch
            style={{
              transform: "translate(93px,5px)",
              color: "#AFAFAF",
            }}
          />
          )}
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="ms-2 px-3 bg-super-light border-0 text-center borderradius-22px me-2"
          />
          <button
            type="button"
            className="bg-green fontweight-bold btn text-white me-4"
            onClick={() => {
              navigate("/create-admin");
            }}>
            Add
          </button>
          <button
            type="button"
            className="bg-primary fontweight-bold btn text-white"
            onClick={() => {
              downloadExcel()
            }}
          >
            Download
          </button> </div>
      </div>
      <Tabs
        defaultActiveKey="Account"
        className="pt-1 bg-white fontweight-bold text-black"
        variant="pills"
      >
        <Tab eventKey="Account" title="Account" className="bg-super-light " >
          <div className=" mt-3 mx-3 rounded">
            <Accounts search={searchTerm} />
          </div>
        </Tab>
      </Tabs>
    </Layout>
  );
};

export default Admin;
import axios from "../../../util/axiosInst";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteSuccessMessage } from "../../utils/constants";
import Pagination from "../custom/Pagination";

const Created = ({ search }) => {
  const navigate = useNavigate();
  const [Education, setEducation] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const getEducation = (page) => {
    const searchCondition = search?.length ? `search=${search}` : "";
    axios.get(`/v1/content?page=${page}&limit=10&${searchCondition}&sortBy=updatedAt:desc&isAdmin=true`).then((res) => {
      setEducation(res?.data);
      setCurrentPage(page)
    });
  };
  const handleEditContent = async (id) => {
    navigate(`/edit-content/${id}`);
  };
  const handleDeleteContent = async () => {
    if (deleteId) {
      try {
        await axios.delete(`v1/content/admin/${deleteId}`);
        toast.success(deleteSuccessMessage);
        getEducation(Education?.results?.length === 1 ? currentPage - 1 : currentPage);
        handleClose();
      } catch (error) {
        getEducation(currentPage);
        handleClose();
      }
    }
  };
  useEffect(() => {
    getEducation(1);
  }, [search]);
  return (<>
    <Table className=" ms-1 fs-13px">
      <thead className="border-bottom">
        <th className="text-body-tertiary pt-4 px-2">TITLE</th>
        <th className="text-body-tertiary px-2">KEYWORDS</th>
        <th className="text-body-tertiary text-center pe-5">ACTIVE</th>
        <th className="text-body-tertiary text-end">ACTION</th>
      </thead>
      <tbody style={{ borderTop: "0px" }} className="mt-5">

        {Education && Education?.results?.length ? (
          Education?.results?.map((education) => (
            <tr>
              <td>{education?.title}</td>
              <td className="text-justify">{education?.keywords.join(" ")}</td>
              <td className="text-primary">
                {education.active ? (
                  <span className="text-green">YES</span>
                ) : (
                  <span className="text-danger">NO</span>
                )}
              </td>
              {/* <td className="text-end">
              <BsThreeDotsVertical className="text-body-tertiary" />
            </td> */}
              <td className="fontweight-bold h5  table-drop py-2 px-0">
                <Dropdown className="text-end">
                  <Dropdown.Toggle className="threedrop " id="dropdown-basic">
                    <BsThreeDotsVertical className="text-body-tertiary" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => { handleEditContent(education.id) }}
                      className="cursor-pointer"
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      handleShow();
                      setDeleteId(education?.id);
                    }}
                      className="cursor-pointer">Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>)))
          : (
            <tr>
              <td colSpan="6" className="text-center">
                No Data Found
              </td>
            </tr>
          )}
      </tbody>
    </Table>
    <Pagination
      onPageChange={(event) => getEducation(event.selected + 1)}
      pageRangeDisplayed={10}
      totalPages={Education?.totalPages}
      currentPageNo={currentPage - 1}
    />
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDeleteContent}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  );
};

export default Created;

import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingScreen = ({ color }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Spinner animation="border" variant={color} />
    </div>
  );
};

export default LoadingScreen;

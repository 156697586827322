import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axiosInst";
import Pagination from "../custom/Pagination";
import Modals from "../Modal/Patient";
import { toast } from "react-toastify";
import { Dropdown, Table } from "react-bootstrap";
import { unknownErrorMessage } from "../../utils/constants";
import { BsClipboardData, BsThreeDotsVertical } from "react-icons/bs";
import Patientpopup from "../Patientpopup";

const SignedUpPatient = ({ search }) => {
  const navigate = useNavigate();
  const [contents, setContents] = useState([]);
  const [show, setShow] = useState(false);
  const [patientpopup, setpatientpopup] = useState(false);
  const [patientpopid, setpatientpopid] = useState();
  const setpatientpopup_handleClose = () => setpatientpopup(false);
  const handleClose = () => setShow(false);
  const fetchContents = async (page) => {
    try {
      const searchCondition = search?.length ? `&name=${search}` : "";
      const { data } = await axios.get(`/v1/user?role=patient&deleted=false&page=${page}&limit=10&${searchCondition}&sortBy=updatedAt:desc`)
      setContents(data)
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };
  const handleEditContent = async (id) => {
    navigate(`/edit-patient/${id}`);
  };
  const handleResultContent = async (id) => {
    navigate(`/results/${id}`);
  };
  useEffect(() => {
    fetchContents(1);
  }, [search]);
  return (
    <div className="mt-3 mx-3 rounded px-3 bg-white">
      <Table className=" ms-1 rounded">
        <thead className="border-bottom">
          <th className="text-body-tertiary pt-4 ">NAME</th>
          <th className="text-body-tertiary text-center">EMAIL</th>
          <th className="text-body-tertiary">ACTIVE</th>
          <th className="text-body-tertiary text-uppercase">Result</th>
          <th className="text-body-tertiary text-end">ACTION</th>
        </thead>
        <tbody style={{ borderTop: "0px" }} className="mt-5">
          {contents && contents?.results?.length ? (
            contents?.results?.map((appointment, index) => (
              <tr>
                <td className="text-canvablue cursor-pointer" onClick={() => {
                  setpatientpopid(appointment?.id);
                  setpatientpopup(true);
                }}>{appointment?.name}</td>
                <td className="text-center ">{appointment?.email}</td>
                <td>
                  {appointment.active ? (
                    <span className="text-green">YES</span>
                  ) : (
                    <span className="text-danger">NO</span>
                  )}
                </td>
                <td onClick={() => { handleResultContent(appointment.id) }}
                  className="cursor-pointer">
                  <BsClipboardData className="mr-3 icon" />
                </td>
                <td className="fontweight-bold h5 table-drop py-2 px-0">
                  <Dropdown className="text-end">
                    <Dropdown.Toggle className="threedrop " id="dropdown-basic">
                      <BsThreeDotsVertical className="text-body-tertiary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => { handleEditContent(appointment.id) }}
                        className="cursor-pointer"
                      >
                        Edit
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>)))
            : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
        </tbody>
      </Table>
      <Pagination
        onPageChange={(event) => fetchContents(event.selected + 1)}
        pageRangeDisplayed={10}
        totalPages={contents?.totalResults / 10}
        forcePage={contents?.page}
      />

      {patientpopup && (
        <Patientpopup
          show={patientpopup}
          handleClose={setpatientpopup_handleClose}
          id={patientpopid}
        />
      )}
    </div>

  );
};
export default SignedUpPatient;
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import React from "react";
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import axios from "../../utils/axiosInst";
import Layout from "../layout/Layout";
const { useEffect, useState } = React;

const AddLetterprofile = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const schema = yup
    .object({
      banner: yup.string().nullable(),
      title: yup.string().required("Title is required field"),
      content: yup.string().required("Content is a required field"),
      active: yup.boolean(),
    })
    .required();
  const [isSendEmail, setisSendEmail] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (formData) => {
    try {
      const dataToSend = {
        ...formData,
        categoryId: "63d3ffb005622741c73e1fad",
        active: false,
        // createdBy: location.state.id,
        isSendEmail: isSendEmail
      };
      if (location?.pathname?.includes("edit")) {
        await axios.patch(`/v1/news-letter/${id}`, dataToSend);
        Swal.fire({
          icon: "success",
          title: "Updated successfully.",
        });
      } else {
        await axios.post("/v1/news-letter", dataToSend);
        Swal.fire({
          icon: "success",
          title: "Created successfully.",
          wordbreak: "break-word",
        });
      }
      navigate("/newsletter");
    } catch (e) { }
  };

  const getNewsletterDetails = async () => {
    if (location?.pathname?.includes("edit")) {
      const { data } = await axios.get(`v1/news-letter/${id}`);
      reset(data);
    }
  };

  useEffect(() => {
    getNewsletterDetails()
  }, []);

  return (
    <Layout h1="Newsletter">
      <div className="py-4">
        <section className="h-100">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container">
              <div className="row mt-3">
                <div className="col-md-9">
                  <div className="bg-white border rounded p-3">
                    <div className="mb-3 col-sm-6">
                      <label className="mb-1">Title *</label>
                      <input
                        type="text"
                        className={clsx(
                          "form-control",
                          errors.title && "is-invalid"
                        )}
                        placeholder="Enter the Title"
                        {...register("title")}
                        style={{ padding: "0.5rem 0.5rem" }}
                      />
                      <div className="invalid-feedback">
                        {errors.title?.message}
                      </div>
                    </div>
                    <div className="mb-3 col-sm-12">
                      <label className="mb-2">Content *</label>
                      <textarea rows="4" cols="50"
                        className={clsx(
                          "form-control",
                          errors.content && "is-invalid"
                        )}
                        placeholder="Enter the Content"
                        {...register("content")}
                        style={{
                          border: "1px solid #b5b5b5",
                          borderRadius: "8px",
                          padding: "0.5rem 0.5rem"
                        }}
                      />
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.content?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="border rounded bg-white mb-3 p-3">
                    <div className="d-flex flex-column">
                      <button
                        type="submit"
                        className="btn btn-outline-primary mt-3"
                        onClick={() => setisSendEmail(true)}
                      >
                        Publish
                      </button>
                      <button
                        type="submit"
                        className="btn btn-outline-info mt-3"
                      >
                        Save
                      </button>
                      <Link
                        to="/newsletter"
                        className="btn btn-outline-danger mt-3"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </Layout>

  );
};

export default AddLetterprofile;

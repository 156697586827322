import React, { useState, useEffect } from "react";
import { Calendar, dayjsLocalizer, momentLocalizer } from "react-big-calendar";
import Layout from "../layout/Layout";
import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

import dayjs from "../../../util/dayjs";
import axios from "../../../util/axiosInst";
import { formatIntlPhoneNumber } from "../../../util/utils";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const Schedule = () => {
  const [eventsData, setEventsData] = useState(events);
  const [apires, setApires] = useState([]);
  const [scheduledata, setScheduledata] = useState([]);
  const [popupdata, setPopupdata] = useState([]);
  const [accept, setAccept] = useState(false);

  const handleSelect = ({ start, end }) => {
    const title = window.prompt("Add Appointment");
    if (title)
      setEventsData([
        ...eventsData,
        {
          start,
          end,
          title,
        },
      ]);
  };

  const handleCloseaccept = () => {
    setAccept(false);
  };

  const getscheduledata = async (e) => {
    let tempdata = [{}];
    const { data } = await axios.get(
      `/v1/schedule/appointment?limit=100&sortBy=createdAt:desc&page=${e}&status=ACCEPTED`
    );
    setApires(data?.docs);
    console.log(data?.docs?.length);
    console.log(data?.docs);
    for (let i = 0; i < data?.docs?.length; i++) {
      let apptTime = moment(data?.docs[i].appointmentTime, "h:mm A");
      let a = {
        id: "",
        title: "",
        start: new Date(),
        end: new Date(),
      };
      let dt = new Date(data?.docs[i].appointmentDate);
      a.id = i;
      a.title = `${data?.docs[i].contactMode === "AUDIO"
        ? "Call"
        : data?.docs[i].contactMode === "VIDEO"
          ? "Video"
          : "Message"
        } Appointment`;
      a.start = new Date(
        dt.getFullYear(),
        dt.getMonth(),
        dt.getDate(),
        apptTime.format("h"),
        apptTime.format("mm")
      );
      a.end = new Date(
        dt.getFullYear(),
        dt.getMonth(),
        dt.getDate(),
        apptTime.format("h"),
        apptTime.format("mm")
      );
      tempdata.push(a);
    }
    setScheduledata(tempdata);
  };

  useEffect(() => {
    getscheduledata(1);
  }, []);
  return (
    <Layout h1="Schedule">
      <div className="bg-white mt-3 mx-3 rounded p-4">
        <Calendar
          views={["agenda", "month", "day"]}
          selectable
          localizer={localizer}
          // defaultDate={new Date()}
          defaultView="month"
          events={scheduledata}
          style={{ height: "77vh" }}
          onSelectEvent={(event) => {
            setAccept(true);
            setPopupdata(apires[event.id]);
          }}
          popup={true}
        // onSelectSlot={handleSelect}
        />
      </div>
      {accept && (
        <Modal show={accept} onHide={handleCloseaccept} centered>
          <Modal.Header closeButton className="border-0">
            <Modal.Title id="contained-modal-title-vcenter">
              <h4 className="ms-5">Appointment Info</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-3 pt-0">
            <div
              className={`px-3 text-center`}
              style={{
                height: "450px",
              }}
            >
              <div className="pt-2">
                <div className="row ps-5">
                  <div
                    className="col-3 text-start"
                    style={{ fontSize: "13px", fontWeight: "700" }}
                  >
                    <p className="text-primary">Name :</p>
                    <p className="text-primary">Visit Type :</p>
                    <p className="text-primary">Method :</p>
                    <p className="text-primary">Date :</p>
                    <p className="text-primary">Email ID :</p>
                    <p className="text-primary">Contact :</p>
                    <p className="text-primary">Reason :</p>
                    {popupdata?.pharmacy ? (
                      <p className="text-primary">Pharmacy :</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-9" style={{ fontSize: "13px" }}>
                    <p className="text-start">{popupdata?.patient?.name}</p>
                    <p className="text-start">{popupdata?.visitType}</p>
                    <p className="text-start text-capitalize">
                      {popupdata?.contactMode === "AUDIO"
                        ? "Call"
                        : popupdata?.contactMode === "VIDEO"
                          ? "Video"
                          : "Message"}{" "}
                      Appointment
                    </p>
                    <p className="text-start">
                      {dayjs(popupdata?.appointmentDateTime).format(
                        "MMMM DD, YYYY h:mm A"
                      )}
                    </p>
                    <p className="text-start">{popupdata?.patient?.email}</p>
                    <p className="text-start">
                      {formatIntlPhoneNumber(popupdata?.textNotificationNumber)}
                    </p>
                    <p className="text-start">{popupdata?.reason}</p>
                    <p className="text-start">
                      {popupdata?.pharmacy?.name}
                      <br />
                      {popupdata?.pharmacy?.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </Layout>
  );
};

export default Schedule;

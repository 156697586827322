import React from "react";
import Layout from "../layout/Layout";
// import axios from "../../../util/axiosInst";
import { Tab, Tabs } from "react-bootstrap";
import TableComponent from "../common/TableComponent";
import { pharmacy } from "../../utils/json";
import { FaSearch } from "react-icons/fa";
import { useState } from "react";
import Modals from "../Modal/Patient";

const Pharmacies = () => {
  const tableSkeleton = [
    {
      label: "Name",
      key: "name",
      onclick: () => {
        setshow(true);
      },
      tdclass: "cursor-pointer text-primary",
    },
    {
      label: "Email",
      key: "email",
      thclass: "text-center",
      tdclass: "text-center word-break",
    },
    {
      label: "Created Date",
      key: "createdAt",
      clue: "moment",
      format: "LLL",
      thclass: "text-center",
      tdclass: "text-center",
    },
    {
      label: "Published",
      key: "publish",
      thclass: " px-3",
      tdclass: "text-center",
      clue: "bool",
    },
    {
      label: "Active",
      key: "active",
      thclass: "text-end",
      tdclass: "text-end",
      clue: "bool",
    },
  ];
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  return (
    <Layout h1="Pharmacies">
      <div className="float-right bg-white p-2">
        <div className="d-flex me-4">
          <FaSearch
            style={{
              transform: "translate(93px,5px)",
              color: "#AFAFAF",
            }}
          />
          <input
            type="text"
            placeholder="Search"
            className="ms-2 px-3 bg-super-light border-0 text-center borderradius-22px"
          />

          <button type="button" className="btn btn-success text-white  px-3">
            Add
          </button>
        </div>
      </div>
      <Tabs
        defaultActiveKey="Created"
        className="pt-1 bg-white fontweight-bold text-black"
        variant="pills"
      >
        <Tab eventKey="Created" title="Created">
          <TableComponent tableSkeleton={tableSkeleton} tableData={pharmacy} />
        </Tab>
      </Tabs>
      <Modals show={show} handleClose={handleClose} type="Patient" />
    </Layout>
  );
};

export default Pharmacies;

import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import Uparrow from "../../assets/images/UpArrow.svg";
import Downarrow from "../../assets/images/DownArrow.svg";
import { useState } from "react";
import Modals from "../Modal/Patient";
import axios from "../../utils/axiosInst";
import { unknownErrorMessage } from "../../utils/constants";
import { toast } from "react-toastify";
import Notifiation from "./Notification";
import AnalyticsTile from "../custom/AnalyticsTile";
const Home = () => {
  const [show, setshow] = useState(false);
  const [notis, setNotis] = useState([]);
  const fetchContents = async () => {
    try {
      const { data } = await axios.get(`/v1/analytics/admin/home`);
      setNotis(data);
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };

  const handleClose = () => setshow(false);

  useEffect(() => {
    fetchContents();
  }, []);
  return (
    <Layout h1="Home">
      <div className="container pt-2">
        <div className="row p-3">
          <AnalyticsTile
            type={"Patients"}
            value={notis?.patientCount}
            arrow={
              notis?.patientPercentage === "Infinity%"
                ? ""
                : Number(notis?.patientPercentage?.replace("%", "")) > 0
                ? Uparrow
                : Downarrow
            }
            percentage={
              typeof notis?.patientPercentage === "string" &&
              !isNaN(parseFloat(notis?.patientPercentage.replace("%", ""))) &&
              Math.abs(parseFloat(notis?.patientPercentage.replace("%", "")))
            }
          />
          <AnalyticsTile
            type={"Providers"}
            value={notis?.providerCount}
            arrow={
              notis?.providerPercentage === "Infinity%"
                ? ""
                : Number(notis?.providerPercentage?.replace("%", "")) > 0
                ? Uparrow
                : Downarrow
            }
            percentage={
              typeof notis?.providerPercentage === "string" &&
              !isNaN(parseFloat(notis?.providerPercentage.replace("%", ""))) &&
              Math.abs(parseFloat(notis?.providerPercentage.replace("%", "")))
            }
          />
          <AnalyticsTile
            type={"Total MRR"}
            value={notis?.MRRCount}
            arrow={
              notis?.mrrPercentage === "Infinity%"
                ? ""
                : Number(notis?.mrrPercentage?.replace("%", "")) > 0
                ? Uparrow
                : Downarrow
            }
            percentage={
              typeof notis?.mrrPercentage === "string" &&
              !isNaN(parseFloat(notis?.mrrPercentage.replace("%", ""))) &&
              Math.abs(parseFloat(notis?.mrrPercentage.replace("%", "")))
            }
          />
          <AnalyticsTile
            type={"Total Visits"}
            value={notis?.visitsCount}
            arrow={
              notis?.visitsPercentage === "Infinity%"
                ? ""
                : Number(notis?.visitsPercentage?.replace("%", "")) > 0
                ? Uparrow
                : Downarrow
            }
            percentage={
              typeof notis?.visitsPercentage === "string" &&
              !isNaN(
                parseFloat(notis?.visitsPercentage.replace(/[-+]/g, ""))
              ) &&
              Math.abs(parseFloat(notis?.visitsPercentage.replace(/[-+]/g, "")))
            }
          />
        </div>
        <div className="bg-white mt-3 mx-3 p-3 rounded">
          <h3 className="mb-2">Notifications</h3>
          <Notifiation />
        </div>
      </div>
      <Modals show={show} handleClose={handleClose} type="Patient" />
    </Layout>
  );
};

export default Home;

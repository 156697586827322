import React from "react";
import { Modal, Button } from "react-bootstrap";

const MOPModal = ({ show, onHide, title, content, size, dialogClassName }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      scrollable
      size={size}
      dialogClassName={dialogClassName}
    >
      <Modal.Header closeButton>
        <Modal.Title className="fontweight-bold h3">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflowX-hidden">{content}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MOPModal;

import React, { useState } from "react";
import Layout from "../layout/Layout";
import { Tab, Tabs } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import ResultsPatients from "./resultsPatients";
import { useNavigate } from "react-router-dom";
import SignedUpPatient from "./SignedUpPatients";
import moment from "moment";
import * as XLSX from "xlsx";
import { map } from "lodash";
import axios from "../../utils/axiosInst";

const Patient = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState("Signed Up");
  const handleTabSelect = (tabKey) => {
    setSelectedTab(tabKey);
  };
  const downloadExcel = async () => {
    if (selectedTab === "Signed Up") {
      let response;
      response = await axios.get(
        `/v1/user?role=patient&sortBy=updatedAt:desc&limit=100000`
      );
      const worksheet = XLSX.utils.json_to_sheet(
        map(
          response?.data?.results,
          ({ firstName, lastName, email, active, createdAt }) => ({
            Name: firstName + " " + lastName,
            Email: email,
            Active: active === true ? "YES" : "NO",
            "Created Date": moment(createdAt).format("ll"),
          })
        )
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `signedUp_${moment().format("YYYY_MM_DD")}.xlsx`
      );
    } else {
      let response;
      response = await axios.get(
        `/v1/evaluation/history-report?&sortBy=updatedAt:desc&limit=100000`
      );
      const worksheet = XLSX.utils.json_to_sheet(
        map(
          response?.data?.results,
          ({ age, gender, result, active, createdAt }) => ({
            Age: age,
            Gender: gender,
            Condition: result?.conditions?.confidence,
            "Confidence %": result?.conditions?.name,
            "Created Date": moment(createdAt).format("ll"),
          })
        )
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `resultPatient_${moment().format("YYYY_MM_DD")}.xlsx`
      );
    }
  };
  return (
    <Layout h1="Patients">
      <div className="float-right bg-white py-2">
        <div className="d-flex me-4">
          {searchTerm.length < 1 && (
            <FaSearch
              style={{
                transform: "translate(93px,5px)",
                color: "#AFAFAF",
              }}
            />
          )}
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="ms-2 px-3 bg-super-light border-0 text-center borderradius-22px me-2"
          />
          <button
            type="button"
            className="bg-primary fontweight-bold btn text-white me-4"
            onClick={() => {
              downloadExcel();
            }}
          >
            Download
          </button>
        </div>
      </div>
      <Tabs
        defaultActiveKey="Signed Up"
        className="pt-1 bg-white fontweight-bold text-black"
        variant="pills"
        activeKey={selectedTab}
        onSelect={handleTabSelect}
      >
        <Tab eventKey="Signed Up" title="Signed Up">
          <SignedUpPatient search={searchTerm} />
        </Tab>
        <Tab eventKey="Results" title="Results ">
          <ResultsPatients search={searchTerm} />
        </Tab>
      </Tabs>
    </Layout>
  );
};

export default Patient;

import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import axios from "../../utils/axiosInst";
import { FaSort } from "react-icons/fa";
import Pagination from "../custom/Pagination";

const PatientOverviewTable = ({ type, search }) => {
  const [page, setPage] = useState(1);
  const [overview, setOverview] = useState({});
  const [sort, setSort] = useState({
    providerName: false,
    userType: false,
    [type?.value === "referralGiven" ? "referralCount" : type?.value]: false,
    recent: "providerName",
  });

  const getOverviewTable = (name, sortType, pages) => {
    axios
      .get(
        `/v1/analytics/admin/graphprovider?type=${type?.value}&sortBy=${name}:${sortType ? "desc" : "asc"
        }&page=${pages}&limit=10&providerName=${search}`
      )
      .then((res) => setOverview(res.data.Providers));
  };
  useEffect(() => {
    getOverviewTable("providerName", true, 1);
  }, [type, search]);

  return (
    <>
      <Table className="mt-4 bg-white rounded mb-5">
        <thead className="border-bottom text-uppercase">
          <th className=" pt-2 ps-5 text-uppercase">
            <span className="text-body-tertiary">
              Provider{" "}
              <FaSort
                className="cursor-pointer text-black fs--18 ms-2"
                onClick={() => {
                  setSort({
                    ...sort,
                    providerName: !sort.providerName,
                    recent: "providerName",
                  });
                  getOverviewTable("providerName", !sort.providerName, page);
                }}
              />
            </span>
          </th>
          <th className="pt-2 text-uppercase">
            <span className="text-body-tertiary">
              Provider Type
              <FaSort
                className="cursor-pointer text-black fs--18 ms-2"
                onClick={() => {
                  setSort({
                    ...sort,
                    userType: !sort.userType,
                    recent: "userType",
                  });
                  getOverviewTable("userType", !sort.userType, page);
                }}
              />
            </span>
          </th>
          <th className="text-uppercase">
            <span className="text-body-tertiary">Licensed In</span>
          </th>
          <th className="text-uppercase">
            <span className="text-body-tertiary">
              {type?.label}{" "}
              <FaSort
                className="cursor-pointer text-black fs--18 ms-2"
                onClick={() => {
                  let temp =
                    type?.value === "referralGiven"
                      ? "referralCount"
                      : type?.value;
                  setSort({ ...sort, temp: !sort[temp], recent: temp });
                  getOverviewTable([temp], !sort[temp], page);
                }}
              />
            </span>
          </th>
        </thead>
        <tbody style={{ borderTop: "0px" }} className="mt-5">
          {overview?.docs?.map((item) => (
            <tr>
              <td className="ps-5">{item.providerName}</td>
              <td className="text-capitalize">
                {item?.userType?.replace("_", " ")}
              </td>
              <td className="ps-4 px-0">
                {item?.license_info?.length
                  ? item?.license_info?.map((lisence) => lisence.state)
                  : "-"}
              </td>
              <td className="ps-5">
                {type?.value === "referralGiven"
                  ? item["referralCount"]
                  : type?.value === "provider-revenue" ? `$${item[type?.value]}` : item[type?.value]}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {overview?.totalPages ? (
        <Pagination
          onPageChange={(event) => {
            getOverviewTable(
              sort.recent,
              sort[sort.recent],
              event.selected + 1
            );
            setPage(event.selected + 1);
          }}
          pageRangeDisplayed={10}
          totalPages={overview?.totalPages}
          currentPageNo={overview?.page - 1}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default PatientOverviewTable;

import React, { useState, useRef } from "react";
import { useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ImgPreview } from "./ImgPreview";
import LoadingScreen from "../custom/LoadingScreen copy";

export default function ImageCropModal({ imgSrc, setValue, fileSrc }) {
  const [url, setUrl] = useState();
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 70,
    height: 90,
  });
  const [completedCrop, setCompletedCrop] = useState({
    height: 480,
    unit: "px",
    width: 340,
    x: 0,
    y: 0,
  });
  const [loading, setLoading] = useState(false);

  return (
    <div
      className="modal fade"
      id="imgCrop"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Crop Your Image
            </h5>
          </div>
          <div className="modal-body">
            {!url ? (
              <ReactCrop
                crop={crop}
                circularCrop={false}
                onChange={(_, percentCrop) => {
                  setCrop(percentCrop);
                }}
                locked
                ruleOfThirds
                onComplete={(c) => {
                  setCompletedCrop(c);
                }}
              >
                <img ref={imgRef} alt="before cropping" src={imgSrc} width={"340px"} height={"480px"} />
              </ReactCrop>
            ) : (
              <div className="text-center">
                <img
                    src={url}
                    className="img-fluid"
                    alt="after cropping"
                    width={"340px"}
                    height={"480px"}
                />
              </div>
            )}
          </div>
          <div className="modal-footer">
            {url ? (
              <>
                <button
                  className="btn btn-warning text-white"
                  onClick={() => {
                    setUrl();
                  }}
                >
                  Back
                </button>
                <button
                  className="btn btn-success text-white"
                  onClick={async () => {
                    if (url) {
                      setValue("banner", url);
                      setUrl("");
                      document
                        .querySelector("#imgCrop")
                        .classList.remove("show");
                      document.querySelector("#imgCrop").style.display = "none";
                    }
                  }}
                >
                  Success
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={loading}
                  onClick={async () => {
                    if (
                      completedCrop?.width &&
                      completedCrop?.height &&
                      imgRef.current
                    ) {
                      await ImgPreview(
                        imgRef.current,
                        completedCrop,
                        "cropped" + fileSrc.name,
                        1,
                        0,
                        setUrl,
                        setLoading
                      );
                      setLoading(true);
                    }
                  }}
                >
                  {loading ? <LoadingScreen color="primary" /> : "Crop"}
                </button>
                <button
                  className="btn btn-danger"
                  disabled={loading}
                  onClick={() => {
                    document.querySelector("#imgCrop").classList.remove("show");
                    document.querySelector("#imgCrop").style.display = "none";
                  }}
                >
                  {loading ? <LoadingScreen color="primary" /> : "Cancel"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

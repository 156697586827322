import React from "react";
import { BsChat, BsTelephone, BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown, Modal, Table } from "react-bootstrap";
import Pagination from "../custom/Pagination";
import { useState } from "react";
import axios from "../../utils/axiosInst";
import Swal from "sweetalert2";
import DownloadApptPDF from "../DownloadApptPDF";
import dayjs from "../../utils/dayjs";
import Patientpopup from "../Patientpopup";
import { formatIntlPhoneNumber } from "../../utils/utils";
import DefaultUserLogo from "../DefaultUserLogo";
const Request = ({
  getscheduledata,
  requestappointments,
  getRequestappointmentdata,
  searchTerm,
}) => {
  const [accept, setaccept] = useState(false);
  const [acceptid, setacceptid] = useState({});
  const [page, setPage] = useState(0);

  const getappointmentdata = async (e) => {
    getRequestappointmentdata(e, searchTerm);
    setPage(e - 1);
  };

  const handleCloseacceptpopup = () => {
    setaccept(false);
  };

  const handleCloseaccept = async () => {
    let response = await axios.patch(
      `v1/schedule/appointment/status?id=${acceptid?._id}&status=ACCEPTED`
    );
    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Appointment Scheduled successfully.",
      }).then((result) => {
        setacceptid({});
        setaccept(false);
        getappointmentdata(1);
        getscheduledata(1, "");
      });
    }
  };

  const [patientpopid, setpatientpopid] = useState();
  const [patientpopup, setpatientpopup] = useState(false);
  const setpatientpopup_handleClose = () => {
    setpatientpopup(false);
  };

  return (
    <>
      <Table className="table-borderless ms-1 rounded ">
        <thead className="border-bottom" style={{ fontSize: "14px" }}>
          <th
            className="text-body-tertiary pt-3 ps-4 rounded"
            style={{ paddingBottom: "10px ", width: "30%" }}
          >
            PATIENT
          </th>
          <th
            className="text-body-tertiary text-center ps-3"
            style={{ paddingBottom: "10px ", width: "10%" }}
          >
            TYPE
          </th>
          {/* PDF file code/* <th
            className="text-body-tertiary text-center ps-3"
            style={{ paddingBottom: "10px " }}
          >
            FILE
          </th> */}
          <th
            className="text-body-tertiary text-center"
            style={{ paddingBottom: "10px " }}
          >
            REQUEST DATE & TIME
          </th>
          <th className="text-body-tertiary text-center"
            style={{ paddingBottom: "10px " }}
          >STATUS</th>
        </thead>
        <tbody style={{ borderTop: "0px", fontSize: "13px" }}>
          {requestappointments?.docs?.map((appointment, index) => (
            <tr
              className={
                requestappointments?.docs.length - 1 !== index
                  ? "border-bottom"
                  : ""
              }
            >
              <td className="rounded py-0 ">
                <div
                  className="cursor-pointer d-flex align-items-center"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    setpatientpopid(appointment?.patient?.userId);
                    setpatientpopup(true);
                  }}
                >
                  <DefaultUserLogo />
                  <p className="mb-0">{appointment?.patient?.name}</p>
                </div>
              </td>
              <td className="text-primary text-center p-0 ps-4">
                <p
                  style={{ background: "#EBF5FF", color: "#0071FF" }}
                  className="p-1 rounded-pill mt-3 m-0"
                >
                  {appointment?.contactMode === "VIDEO"
                    ? "Video"
                    : appointment?.contactMode === "AUDIO"
                      ? "Phone"
                      : appointment?.contactMode === "CHAT"
                        ? "Chat"
                        : "Office"}
                </p>
              </td>
              {/* PDF file code/* <td className="text-primary fontweight-bold text-center p-0 ps-3">
                <DownloadApptPDF appointment={appointment} />
              </td> */}
              <td className="text-body-tertiary fontweight-bold text-center p-0">
                <p className="margintop--14px pt-3 px-0">
                  {dayjs(appointment?.appointmentDateTime).format(
                    "M/D/YY h:mm A"
                  )}
                </p>
              </td>
              <td className="text-center p-0">
                <p className="margintop--14px pt-3 px-0">
                  <span style={{ fontWeight: 700, color: "#ff9317" }}>
                    {appointment?.status}
                  </span>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {requestappointments?.docs?.length === 0 ? (
        <h3 className="text-center py-5">No Requested Appointments</h3>
      ) : (
        <Pagination
          onPageChange={(event) => {
            window.scrollTo(0, 0);
            getappointmentdata(event.selected + 1);
          }}
          pageRangeDisplayed={window.innerWidth < 769 ? 2 : 5}
          totalPages={requestappointments?.totalPages}
          currentPageNo={page}
        />
      )}
      {accept && (
        <Modal show={accept} onHide={handleCloseacceptpopup} centered>
          <Modal.Header closeButton className="border-0">
            <Modal.Title id="contained-modal-title-vcenter">
              <h4 className="ms-5">Appointment Request Info</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-3 pt-0">
            <div
              className={`px-3 text-center`}
              style={{
                height: "420px",
              }}
            >
              <div className="pt-2">
                <div className="row ps-5">
                  <div
                    className="col-3 text-start"
                    style={{ fontSize: "13px", fontWeight: "700" }}
                  >
                    <p className="text-primary">Name :</p>
                    <p className="text-primary">Visit Type :</p>
                    {acceptid?.visitType !== "office" && (
                      <p className="text-primary">Method :</p>
                    )}
                    <p className="text-primary">Date :</p>
                    <p className="text-primary">Email ID :</p>
                    <p className="text-primary">Contact :</p>
                    <p className="text-primary">Reason :</p>
                    {acceptid?.pharmacy ? (
                      <p className="text-primary">Pharmacy :</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-9" style={{ fontSize: "13px" }}>
                    <p className="text-start">{acceptid?.patient?.name}</p>
                    <p className="text-start text-capitalize">
                      {acceptid?.visitType}
                    </p>
                    {acceptid?.visitType !== "Office" && (
                      <p className="text-start">{acceptid?.contactMode}</p>
                    )}
                    <p className="text-start">
                      {dayjs(acceptid?.appointmentDateTime).format(
                        "ddd, MMM D, YYYY h:mm A"
                      )}
                    </p>
                    <p className="text-start">{acceptid?.patient?.email}</p>
                    <p className="text-start">
                      {`${formatIntlPhoneNumber(
                        acceptid?.textNotificationNumber
                      )}`}
                    </p>
                    <p className="text-start">{acceptid?.reason}</p>
                    <p className="text-start">
                      {acceptid?.pharmacy?.name}
                      <br />
                      {acceptid?.pharmacy?.address}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex flex-wrap w-100">
                  <div
                    className="d-flex flex-column me-1"
                    style={{ width: "47.5%" }}
                  >
                    <button
                      className=" form-control bg-primary text-white fw-600"
                      type="button"
                      onClick={() => handleCloseaccept()}
                    >
                      Accept
                    </button>
                  </div>
                  <div
                    className="d-flex flex-column ms-lg-1"
                    style={{ width: "47.5%" }}
                  >
                    <button
                      className=" form-control fw-600"
                      type="button"
                      onClick={() => setaccept(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {patientpopup && (
        <Patientpopup
          show={patientpopup}
          handleClose={setpatientpopup_handleClose}
          id={patientpopid}
        />
      )}
    </>
  );
};

export default Request;

import React from "react";
import BusinessInfo from "../BusinessInfo";
import ClinicInfo from "../Clinicinfo";
import RehabInfo from "../RehabInfo";
import AddressInfo from "../AddressInfo";
import { isEmpty } from "lodash";
import { formatPhoneNumber } from "../../../utils/utils";
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";

const ContactTab = ({ providerDetail }) => {
  const { phoneNumber, address, city, state, zipcode } = providerDetail;

  const mapAddress = !isEmpty(address)
    ? `https://maps.google.com/maps?q=${encodeURIComponent(
      `${address.replace("#", "")}, ${city}, ${state}, ${zipcode}`
    )}&t=&z=15&ie=UTF8&iwloc=1&output=embed`
    : "";


  return (
    <div className="p-3 container bg-muted-25 p-md-5 mt-4 card-scroll">
      <div className="row bg-white p-3 rounded-1">
        <div className="col-sm-6">
          {phoneNumber && <h4 className="text-black fw-semibold">Contact</h4>}
          {phoneNumber && (
            <p className="mt-4">
              <FaPhoneAlt className="me-3 fs-3 text-primary" />
              {formatPhoneNumber(phoneNumber)}
            </p>
          )}
          {(!isEmpty(address) ||
            !isEmpty(city) ||
            !isEmpty(state) ||
            !isEmpty(zipcode)) && (
              <h4 className="text-black fw-semibold">Location</h4>
            )}
          {(!isEmpty(address) ||
            !isEmpty(city) ||
            !isEmpty(state) ||
            !isEmpty(zipcode)) && (
              <p className="d-flex mt-4">
                <p>
                  <FaMapMarkerAlt className="me-3 fs-3 text-primary" />
                </p>
                {address && (
                  <>
                    {address} <br />
                  </>
                )}
                {city && `${city}, `}
                {state && `${state}, `}
                {zipcode && (
                  <>
                    {zipcode} <br />
                  </>
                )}
              </p>
            )}
        </div>

        <div className="col-sm-6">
          {!isEmpty(mapAddress) && (
            <iframe
              src={mapAddress}
              width={"100%"}
              height="100%"
              className="border-0"
              title="address"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactTab;

import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import React from "react";
import { useForm, useWatch } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactS3Uploader from "react-s3-uploader";
import Swal from "sweetalert2";
import * as yup from "yup";
import axios from "../utils/axiosInst";
import MediaPreview from "../components/custom/MediaPreview";
import Layout from "../components/layout/Layout";

const { useEffect, useState } = React;

const LatestBlogProfile = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const schema = yup
    .object({
      banner: yup.string().nullable(),
      video: yup.string().nullable(),
      title: yup.string().required("Title is required field"),
      categoryId: yup.string(),
      titleTag: yup.string().nullable(),
      metaDescription: yup.string().nullable(),
      content: yup.string().required("Content is a required field"),
      active: yup.boolean(),
    })
    .required();
  const [editId, setEditId] = useState(null);
  const [blogCategories, setBlogCategories] = useState([]);
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState(0);
  const [mediaType, setMediaType] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getBlogCategories = () => {
    axios.get(`v1/public/blog/categories/no-blogs`).then(({ data }) => {
      setBlogCategories(data?.results);
    });
  };

  const editorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: ["sans-serif"] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],

      ["link", "image"],
      ["clean"],
    ],

    clipboard: {
      matchVisual: false,
    },
  };

  const editorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const getSignedUrl = (file, callback) => {
    const params = {
      fileName: `/blogs/${file.name}`,
    };
    axios
      .get("/v1/s3/put-presigned-url", { params })
      .then(({ data }) => {
        callback({ signedUrl: data.url });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmit = async (formData) => {
    try {
      const dataToSend = {
        ...formData,
        categoryId: category,
        createdBy: location?.state?.id,
      };
      if (editId) {
        await axios.patch(`/v1/blog-news/${editId}`, dataToSend);
        Swal.fire({
          icon: "success",
          title: "Updated successfully.",
        });
      } else {
        await axios.post("/v1/blog-news", dataToSend);
        Swal.fire({
          icon: "success",
          title: "Created successfully.",
          wordbreak: "break-word",
        });
      }
      navigate(`/newsroom`)
    } catch (e) {
      console.log(e)
    }
  };
  let showTooltip = (el) => {
    let tool = el.className.replace("ql-", "");
    let tooltips = {
      1: "Header H1",
      2: "Header H2",

      font: "font",
      size: "size",
      bold: "Bold ",
      italic: "Italic ",
      underline: "Underline ",
      strike: "Strike",

      blockquote: "Blockquote",
      ordered: "Numbering",
      bullet: "Bullets",
      "-1": " Decrease indent",
      "+1": " Increase indent",
      link: "Link",
      image: "Image",
      clean: "Clear Text Formats",
    };
    let heading = el.value;
    if (tooltips[heading]) {
      el.setAttribute("aria-label", tooltips[heading]);
    }

    if (tooltips[tool]) {
      el.setAttribute("aria-label", tooltips[tool]);
    }
  };
  let toolbarElement = document.querySelector(".ql-toolbar");
  if (toolbarElement) {
    let matches = toolbarElement.querySelectorAll("button");
    for (let el of matches) {
      showTooltip(el);
    }
  }

  const getBlogDetails = async () => {
    if (params && params.id) {
      setEditId(params.id);
      const { data } = await axios.get(`/v1/blog-news/${params.id}`);
      setContent(data?.content);
      setValue("content", data?.content);
      setValue("title", data?.title);
      setValue("banner", data?.banner);
      setValue("video", data?.video);
      setValue("active", data?.active);
      setValue("titleTag", data?.titleTag);
      setValue("metaDescription", data?.metaDescription);
      setValue("categoryId", data?.categoryId?.id);
      setCategory(data?.categoryId?.id);
      if (data?.banner) {
        setMediaType("image")
      }
      if (data?.video) {
        setMediaType("video")
      }
    }
  };

  useEffect(() => {
    getBlogCategories();
    getBlogDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FileUploadComponent = ({ fileName, accept }) => {
    const file = useWatch({
      control,
      name: fileName,
    });

    return (
      <div className="mb-3">
        <div className="">
          <MediaPreview
            fileName={file || getValues(fileName)}
            type={mediaType}
            blog="w-100"
          />
        </div>
        <div className="upload-control">
          <ReactS3Uploader
            accept={accept}
            id={fileName}
            onProgress={(e) => {
              setStatus(e);
            }}
            className="d-none"
            disabled={mediaType === "" && "true"}
            getSignedUrl={getSignedUrl}
            signingUrlWithCredentials={true}
            uploadRequestHeaders={{}}
            onFinish={async (result, file) => {
              const { data } = await axios.get("/v1/s3/get-presigned-url", {
                params: {
                  fileName: `/blogs/${file.name}`,
                },
              });
              setValue(fileName, data.url);
            }}
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
            autoUpload={true}
          />
          <label htmlFor={fileName} className="w-100">
            {!getValues(fileName) && (
              <span className="border btn w-100" role="button">
                <>
                  <label
                    className={`mb-1 ${mediaType === "" ? "text-secondary" : "text-black"
                      }`}
                  >Set Thumnail</label>
                  <div className="border-dotted rounded p-3 d-flex align-items-center justify-content-center flex-fill">
                    <div className="text-center text-light">
                      <h1>
                        <i className="fa fa-image"></i>
                      </h1>
                      {mediaType === "image" ? "Upload Photo" : mediaType === "video" ? "Upload Video" : ""}
                    </div>
                  </div>
                </>
              </span>
            )}
            {status > 0 && !getValues(fileName) && (
              <span className=" mt-5">Uploading {status}%</span>
            )}

            {getValues(fileName) && (
              <span
                className="border btn btn-danger mt-4"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  setStatus(0);
                  setValue(fileName, null);
                }}
              >
                Remove
              </span>
            )}
          </label>
        </div>
      </div>
    );
  };
  const FileUploadComponent_video = ({ fileName, accept }) => {
    const file = useWatch({
      control,
      name: fileName,
    });

    return (
      <div className="mb-3">
        <div className="">
          <MediaPreview
            fileName={file || getValues(fileName)}
            type={mediaType}
            blog="w-100"
          />
        </div>
        <div className="upload-control">
          <ReactS3Uploader
            accept={accept}
            id={fileName}
            onProgress={(e) => {
              setStatus(e);
            }}
            className="d-none"
            disabled={mediaType === "" && "true"}
            getSignedUrl={getSignedUrl}
            signingUrlWithCredentials={true}
            uploadRequestHeaders={{}}
            onFinish={async (result, file) => {
              const { data } = await axios.get("/v1/s3/get-presigned-url", {
                params: {
                  fileName: `/blogs/${file.name}`,
                },
              });
              setValue(fileName, data.url);
            }}
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
            autoUpload={true}
          />
          <label htmlFor={fileName} className="w-100">
            {!getValues(fileName) && (
              <span className="border btn w-100" role="button">
                <>
                  <label
                    className={`mb-1 ${mediaType === "" ? "text-secondary" : "text-black"
                      }`}
                  >Set Thumnail</label>
                  <div className="border-dotted rounded p-3 d-flex align-items-center justify-content-center flex-fill">
                    <div className="text-center text-light">
                      <h1>
                        <i className="fa fa-image"></i>
                      </h1>
                      {mediaType === "image" ? "Upload Photo" : mediaType === "video" ? "Upload Video" : ""}
                    </div>
                  </div>
                </>
              </span>
            )}
            {status > 0 && !getValues(fileName) && (
              <span className=" mt-5">Uploading {status}%</span>
            )}

            {getValues(fileName) && (
              <span
                className="border btn btn-danger mt-4"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  setStatus(0);
                  setValue(fileName, null);
                }}
              >
                Remove
              </span>
            )}
          </label>
        </div>
      </div>
    );
  };

  const isCategoryAvailable = getValues("categoryId");
  return (
    <Layout h1="Newsroom">
      <div>
        <div className="wrapper mb-4 flex-fill">
          <section className="h-100">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container">
                <div className="row mt-3">
                  <div className="col-md-9">
                    <div className="bg-white border rounded p-3">
                      <div className="mb-3 col-sm-6">
                        <label className="mb-1">Title *</label>
                        <input
                          type="text"
                          className={clsx(
                            "form-control",
                            errors.title && "is-invalid"
                          )}
                          placeholder="Title"
                          {...register("title")}
                        />
                        <div className="invalid-feedback">
                          {errors.title?.message}
                        </div>
                      </div>

                      <div className="mb-3 col-sm-6">
                        <label className="mb-1">Categories</label>

                        <select
                          className={clsx(
                            "form-control px-2",
                            errors.categoryId &&
                            !isCategoryAvailable &&
                            "is-invalid"
                          )}
                          value={category}
                          onChange={(e) => {
                            setValue("categoryId", e.target.value);
                            setCategory(e.target.value);
                          }}
                        >
                          <option value="">Select Categories</option>
                          {blogCategories.map((cat, index) => {
                            return (
                              <option value={cat._id}>{cat.name}</option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {errors.categoryId?.message}
                        </div>
                      </div>

                      <div className="row mt-3 p-3">
                        <div className="col-md-6 mb-3">
                          <div className="input-group-swing">
                            <input
                              type="text"
                              placeholder=""
                              {...register("titleTag")}
                            />
                            <label>Title Tag:</label>
                            <p className="error">
                              {errors.keywords?.message}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="input-group-swing">
                            <input
                              type="text"
                              placeholder=""
                              {...register("metaDescription")}
                            />
                            <label>Meta Description:</label>
                            <p className="error">
                              {errors.keywords?.message}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="mb-1">Content *</label>
                        <ReactQuill
                          theme="snow"
                          {...register("content")}
                          modules={editorModules}
                          formats={editorFormats}
                          onChange={(value, delta, source, editor) => {
                            setContent(value);
                            setValue("content", value);
                          }}
                          className={clsx(errors.content && "is-invalid")}
                          value={content}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.content?.message}
                        </div>
                      </div>
                      <div className="mb-3 p-3">
                        <input
                          id="flexCheckDefault"
                          className="form-check-input"
                          type="checkbox"
                          {...register("active")}
                        />
                        <label className="mb-1" for="flexCheckDefault">
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="border rounded bg-white mb-3 p-3">
                      <div className="d-flex flex-column">
                        <div>
                          <select
                            className={
                              "form-control border rounded mb-3 px-2"
                            }
                            value={mediaType}
                            onChange={(e) => {
                              setMediaType(e.target.value);
                            }}
                          >
                            <option value="">Select Media Type</option>
                            <option value={"image"}>Image</option>
                            <option value={"video"}>Video</option>
                          </select>
                        </div>
                        {mediaType === "video" ?
                          <FileUploadComponent_video
                            fileName={"video"}
                            accept={`${mediaType}/*`}
                          /> :
                          <FileUploadComponent
                            fileName={"banner"}
                            accept={`${mediaType}/*`}
                          />
                        }
                        <Link
                          to={`/newsroom`}
                          className="btn btn-outline-danger mt-3"
                        >
                          Cancel
                        </Link>
                        <button
                          type="submit"
                          className="btn btn-outline-primary mt-3"
                        >
                          Publish
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default LatestBlogProfile;

import axios from "../../../util/axiosInst";
import { useEffect, useState } from "react";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteSuccessMessage } from "../../utils/constants";
import Pagination from "../custom/Pagination";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";

const ResultsPatients = ({ search }) => {
  const [resultsPatients, setResultsPatients] = useState([]);
  const [page, setPage] = useState(1)
  const getEducation = (page) => {
    const searchCondition = search?.length ? `name=${search}` : "";
    axios.get(`/v1/evaluation/history-report?page=${page}&limit=10&${searchCondition}&sortBy=updatedAt:desc`)
      .then((res) => {
        setPage(res?.data?.page)
        setResultsPatients(res?.data);
      });
  };
  useEffect(() => {
    getEducation(1);
  }, [search]);
  return (
    <div className="mt-3 mx-3 rounded px-3 bg-white">
      <Table className=" ms-1 fs-13px">
        <thead className="border-bottom">
          <th className="text-body-tertiary pt-4 ps-2">AGE</th>
          <th className="text-body-tertiary text-uppercase">Gender</th>
          <th className="text-body-tertiary text-uppercase"> Confidence %</th>
          <th className="text-body-tertiary">CONDITION</th>
          <th className="text-body-tertiary pe-5">CREATED DATE</th>
        </thead>
        <tbody style={{ borderTop: "0px" }} className="mt-5">
          {resultsPatients && resultsPatients?.results && resultsPatients?.results.length ?
            (resultsPatients && resultsPatients?.results && resultsPatients?.results.map((resultsPatients) => (
              < tr >
                <td>{resultsPatients?.age}</td>
                <td className="text-justify text-capitalize">{resultsPatients?.gender}</td>
                <td className="text-justify">{resultsPatients?.result?.conditions?.confidence}</td>
                <td className="text-justify">{resultsPatients?.result?.conditions?.name}</td>
                <td className="text-justify">
                  {moment(resultsPatients?.updatedAt).format("ll")}
                </td>
                {/* <td className="text-end">
                  <BsThreeDotsVertical className="text-body-tertiary" />
                </td> */}
              </tr>)))
            : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
        </tbody>
      </Table >
      <Pagination
        onPageChange={(event) => { getEducation(event.selected + 1) }}
        pageRangeDisplayed={10}
        totalPages={resultsPatients?.totalResults / 10}
        forcePage={resultsPatients?.page}
      />
    </div >
  );
};
export default ResultsPatients;
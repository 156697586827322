import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsEyeFill } from "react-icons/bs";
import axios from "../../../util/axiosInst";
import { useNavigate } from "react-router";
import { Dropdown, Table } from "react-bootstrap";

const ProvidersTable = ({ active, data }) => {
  const navigate = useNavigate();

  const approve = async (id, datas) => {
    await axios.patch(`/v1/provider/${id}`, {
      ...datas,
      active: true,
    });
  };

  return (
    <>
      <Table className=" ms-1 fs-13px">
        <thead className="border-bottom">
          <th className="text-body-tertiary pt-4 ps-2">NAME</th>
          <th className="text-body-tertiary pt-4">EMAIL</th>
          {/* <th className="text-body-tertiary pt-4">PROVIDER TYPE</th> */}
          <th className="text-body-tertiary">ACTIVE</th>
          <th className="text-body-tertiary">POST</th>
          {active && <th className="text-body-tertiary ">ACTION</th>}
        </thead>
        <tbody style={{ borderTop: "0px" }} className="mt-5">
          {data?.results?.map((newsLetter) => (
            <tr>
              <td>
                {newsLetter?.name ||
                  `${newsLetter.first_name} ${newsLetter.last_name}`}
              </td>
              <td>{newsLetter?.email}</td>
              <td>
                <span
                  className={newsLetter?.active ? "text-green" : "text-danger"}
                >
                  {newsLetter?.active ? "YES" : "NO"}
                </span>
              </td>
              <td>
                <BsEyeFill
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`/provider-post/${newsLetter?.userId}`)
                  }
                />
              </td>
              {active && (
                <td className="fontweight-bold h5  table-drop py-2 px-0">
                  <Dropdown className="text-end">
                    <Dropdown.Toggle className="threedrop " id="dropdown-basic">
                      <BsThreeDotsVertical className="text-body-tertiary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href=""
                        onClick={() => {
                          approve(newsLetter?._id, newsLetter);
                        }}
                      >
                        Approve
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ProvidersTable;

import { SlSettings } from "react-icons/sl";
import { FaRegComment } from "react-icons/fa";
import { TbSpeakerphone } from "react-icons/tb";
import { ReactComponent as User } from "../assets/images/Patient-Profile/1.svg";
import Education_Library from "../assets/images/Patient-Profile/6.svg";
import Pharmacy from "../assets/images/Patient-Profile/11.svg";
import { MdOutlineLocalPostOffice } from "react-icons/md";
import { GrCertificate } from "react-icons/gr";
import { ReactComponent as Home } from "../assets/images/active/home.svg";
import { ReactComponent as Patients } from "../assets/images/active/patients.svg";
import Appointment from "../assets/images/non-active/appointments.svg";
import Referrals from "../assets/images/non-active/referrals.svg";
import Schedule from "../assets/images/non-active/schedule.svg";
import { ReactComponent as Analytics } from "../assets/images/non-active/analytics.svg";

export const newProviderHeader = [
  {
    pathname: "/home",
    img: <Home className="fill-black" width="20px" height="20px" />,
    label: "Home",
  },
  {
    pathname: "/patient",
    img: <Patients className="fill-black" width="20px" height="20px" />,
    label: "Patients",
  },
  {
    pathname: "/providers",
    img: <User className="fill-black" width="20px" height="20px" />,
    label: "Providers",
  },
  {
    pathname: "/appointments",
    img: <img src={Appointment} width="20px" height="20px" alt="home-icon" />,
    label: "Appointments",
  },
  {
    pathname: "/referrals",
    img: <img src={Referrals} width="20px" height="20px" alt="home-icon" />,
    label: "Referrals",
  },
  {
    pathname: "/education",
    img: (
      <img src={Education_Library} width="20px" height="20px" alt="home-icon" />
    ),
    label: "Education",
  },
  {
    pathname: "/pharmacies",
    img: <img src={Pharmacy} width="20px" height="20px" alt="home-icon" />,
    label: "Pharmacies",
  },
  {
    pathname: "/certification",
    img: <GrCertificate className="text-primary width-20px height-20px" />,
    label: "Certifications",
  },
  {
    pathname: "/newsroom",
    img: <TbSpeakerphone className="text-primary width-20px height-20px" />,
    label: "Newsroom",
  },
  {
    pathname: "/newsletter",
    img: (
      <MdOutlineLocalPostOffice className="text-primary width-20px height-20px" />
    ),
    label: "Newsletter",
  },
  {
    pathname: "/testimonies",
    img: <FaRegComment className="text-primary width-20px height-20px" />,
    label: "Testimonials",
  },
  {
    pathname: "/schedule",
    img: <img src={Schedule} width="20px" height="20px" alt="home-icon" />,
    label: "Schedule ",
  },
  {
    pathname: "/analytics",
    img: <Analytics className="fill-black" width="20px" height="20px" />,
    label: "Analytics ",
  },
  {
    pathname: "/admin-staff",
    img: <SlSettings className="text-primary width-20px height-20px" />,
    label: "Admin",
  },
];
export const createSuccessMessage = "Created Successfully";
export const updateSuccessMessage = "Updated Successfully";
export const deleteSuccessMessage = "Deleted Successfully";
export const unknownErrorMessage = "Something went wrong";
export const loginSuccessMessage = "Login Success";
export const timeArray = [
  "12:00 AM",
  "12:15 AM",
  "12:30 AM",
  "12:45 AM",
  "01:00 AM",
  "01:15 AM",
  "01:30 AM",
  "01:45 AM",
  "02:00 AM",
  "02:15 AM",
  "02:30 AM",
  "02:45 AM",
  "03:00 AM",
  "03:15 AM",
  "03:30 AM",
  "03:45 AM",
  "04:00 AM",
  "04:15 AM",
  "04:30 AM",
  "04:45 AM",
  "05:00 AM",
  "05:15 AM",
  "05:30 AM",
  "05:45 AM",
  "06:00 AM",
  "06:15 AM",
  "06:30 AM",
  "06:45 AM",
  "07:00 AM",
  "07:15 AM",
  "07:30 AM",
  "07:45 AM",
  "08:00 AM",
  "08:15 AM",
  "08:30 AM",
  "08:45 AM",
  "09:00 AM",
  "09:15 AM",
  "09:30 AM",
  "09:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "01:00 PM",
  "01:15 PM",
  "01:30 PM",
  "01:45 PM",
  "02:00 PM",
  "02:15 PM",
  "02:30 PM",
  "02:45 PM",
  "03:00 PM",
  "03:15 PM",
  "03:30 PM",
  "03:45 PM",
  "04:00 PM",
  "04:15 PM",
  "04:30 PM",
  "04:45 PM",
  "05:00 PM",
  "05:15 PM",
  "05:30 PM",
  "05:45 PM",
  "06:00 PM",
  "06:15 PM",
  "06:30 PM",
  "06:45 PM",
  "07:00 PM",
  "07:15 PM",
  "07:30 PM",
  "07:45 PM",
  "08:00 PM",
  "08:15 PM",
  "08:30 PM",
  "08:45 PM",
  "09:00 PM",
  "09:15 PM",
  "09:30 PM",
  "09:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM",
];

export const websiteRegex =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
export const FBurl = /^(https?:\/\/)?(www\.)?facebook\.com\/.*/;
export const TWurl = /^(https?:\/\/)?(www\.)?twitter\.com\/.*/;
export const insUrl = /^(https?:\/\/)?(www\.)?instagram\.com\/.*/;
export const youtubeUrl = /^(https?:\/\/)?(www\.)?youtube\.com\/.*/;
export const linkedinUrl =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9_-]+\/?/;
export const SocialmediaProfile = [
  { id: "facebook", Tooltip: "Facebook URL", label: "Facebook" },
  { id: "twitter", Tooltip: "Twitter URL", label: "Twitter" },
  { id: "instagram", Tooltip: "Instagram URL", label: "Instagram" },
  { id: "youtube", Tooltip: "Youtube URL", label: "Youtube" },
  { id: "linkedin", Tooltip: "LinkedIn URL", label: "LinkedIn" },
];
export const columns = [
  { dataField: "id", text: "Id", sort: false, hidden: true },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    formatter: (cell, row) => {
      return (
        <>
          <span>
            {row.first_name || row.firstName} {row.last_name || row.lastName}
          </span>
        </>
      );
    },
  },
  { dataField: "email", text: "Email", sort: true },
  {
    dataField: "active",
    text: "Active",
    headerStyle: { width: 100 },
    sort: true,
    formatter: (cell) => {
      return (
        <>
          {cell ? (
            <span className="badge badge-success">YES</span>
          ) : (
            <span className="badge badge-danger">NO</span>
          )}
        </>
      );
    },
  },
];

export const claimProfileColumns = [
  { dataField: "id", text: "Id", sort: false, hidden: true },
  {
    dataField: "first_name",
    text: "Name",
    sort: false,
    formatter: (cell, row) => {
      return (
        <>
          <span>
            {row.first_name} {row.last_name}
          </span>
        </>
      );
    },
  },
  { dataField: "email", text: "Email", sort: true },
  {
    dataField: "approved",
    text: "Approved",
    headerStyle: { width: 100 },
    sort: true,
    formatter: (cell) => {
      return (
        <>
          {cell ? (
            <span className="badge badge-success">YES</span>
          ) : (
            <span className="badge badge-danger">NO</span>
          )}
        </>
      );
    },
  },
];

export const FirstnameRequired = "First Name is a required field";
export const LastnameRequired = "Last Name is a required field";
export const EmailRequired = "Email is a required field";
export const WebsiteMessage = "Website must be a proper URL";
export const ValidURL = "Enter a valid url";
export const SuccessMessage = "Your changes have been saved";

export const Days = [
  {
    value: "Sunday",
  },
  {
    value: "Monday",
  },
  {
    value: "Tuesday",
  },
  {
    value: "Wednesday",
  },
  {
    value: "Thursday",
  },
  {
    value: "Friday",
  },
  {
    value: "Saturday",
  },
];

export const editorModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],

    ["link", "image"],
    ["clean"],
  ],

  clipboard: {
    matchVisual: false,
  },
};

export const editorFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
export const AuthUser =
  JSON.parse(localStorage.getItem("mop-user")) || undefined;
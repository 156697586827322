import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImCircleRight } from "react-icons/im";
import { Table } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import axios from "../../utils/axiosInst";
import Pagination from "../custom/Pagination";
import Patientpopup from "../Patientpopup";
import ProviderDetails from "../custom/ProviderDetails/ProviderDetails";
const Notifiation = () => {
  const navigate = useNavigate();

  const [patientpopup, setpatientpopup] = useState(false);
  const setpatientpopup_handleClose = () => {
    setpatientpopup(false);
  };
  const [providerpopup, setproviderpopup] = useState(false);
  const providerpopup_handleClose = () => {
    setproviderpopup(false);
    setproviderpopid();
  };

  const [notifications, setnotifications] = useState([]);
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(0);
  const [patientpopid, setpatientpopid] = useState();
  const [providerpopid, setproviderpopid] = useState();

  const getnotidata = async (e) => {
    const { data } = await axios.get(
      `/v1/notification?limit=10&sortBy=createdAt:desc&page=${e}`
    );
    setnotifications(data?.docs);
    setPagination(data);
    setPage(e - 1);
  };
  useEffect(() => {
    getnotidata(1);
  }, []);

  const formatDateTime = (inputDate) => {
    const currentDate = moment();
    const inputMoment = moment(inputDate);

    return inputMoment.isSame(currentDate, "day")
      ? inputMoment.format("H:mm a")
      : inputMoment.format("M/D/YY");
  };

  return (
    <>
      {notifications.length < 1 ? (
        <div className="bg-white mt-3 mx-3 px-3 rounded text-center">
          <h4 className="p-5">No Notifications found</h4>
        </div>
      ) : (
        <div className="bg-white mt-3 mx-3 px-3 rounded ">
          <Table>
            <tbody>
              {notifications.map((data, index) => (
                <>
                  {data.type === "appointment" && data.status && (
                    <tr
                      className="cursor-pointer"
                    >
                      <td className="py-1">
                        <ImCircleRight
                          className={`${data?.isUnRead ? "text-canvablue" : "text-black"
                            } margintop--7px h3`}
                        />
                      </td>
                      <td className="py-1">
                        <h6 className="margintop--12px fw-700">
                          <span
                            className="text-canvablue cursor-pointer"
                            onClick={() => {
                              setpatientpopid(
                                data?.messageDetail?.patientUserId
                              );
                              setpatientpopup(true);
                            }}
                          >
                            {data?.messageDetail?.patientName}
                          </span>{" "}
                          <span style={{ textTransform: "lowercase" }}>
                            {data?.status}
                          </span>{" "}
                          <span>an</span>{" "}
                          <span
                            className="cursor-pointer"
                            style={{ color: "rgb(0, 113, 255)" }}
                            onClick={() => navigate(`/appointments`)}
                          >
                            appointment
                          </span>
                        </h6>
                      </td>
                      <td className="text-end py-0">
                        <p className="text-muted m-0 mt-2">
                          {formatDateTime(data?.createdAt)}
                        </p>
                      </td>
                    </tr>
                  )}
                  {data.type === "referral" && (
                    <tr>
                      <td className="py-0">
                        <ImCircleRight
                          className="margintop--7px h3"
                          style={{ color: "rgb(0, 113, 255)" }}
                        />
                      </td>
                      <td className="py-0 ">
                        <h6 className="margintop--12px fw-700">
                          You received a{" "}
                          <span
                            className="cursor-pointer"
                            style={{ color: "rgb(0, 113, 255)" }}
                            onClick={() => navigate(`/referrals`)}
                          >
                            referral
                          </span>{" "}
                          from{" "}
                          <span
                            className="text-canvablue cursor-pointer"
                            onClick={() => {
                              setproviderpopid(data?.messageDetail?.slugId);
                              setproviderpopup(true);
                            }}
                          >
                            {data?.messageDetail?.providerName}
                          </span>
                        </h6>
                      </td>
                      <td className="text-end py-0">
                        <p className="text-muted m-0 mt-2">
                          {formatDateTime(data?.createdAt)}
                        </p>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </Table>
          {notifications?.length === 0 ? (
            <h3 className="text-center py-5">No Notifications</h3>
          ) : (
            <Pagination
              onPageChange={(event) => {
                window.scrollTo(0, 0);
                getnotidata(event.selected + 1);
              }}
              pageRangeDisplayed={window.innerWidth < 769 ? 2 : 5}
              totalPages={pagination?.totalPages}
              currentPageNo={page}
            />
          )}
          {patientpopup && (
            <Patientpopup
              show={patientpopup}
              handleClose={setpatientpopup_handleClose}
              id={patientpopid}
            />
          )}
          {providerpopup && (
            <Modal
              show={providerpopup}
              dialogClassName="modal-80w overflowX-hidden"
              scrollable
              aria-labelledby="example-custom-modal-styling-title"
              onHide={providerpopup_handleClose}
              title="Provider Info"
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title className="fontweight-bold h3">
                  Provider Info
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="overflowX-hidden">
                <ProviderDetails id={providerpopid} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={providerpopup_handleClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default Notifiation;

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "../utils/axiosInst";
import { Col, Row } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import moment from "moment";
import { getAge } from "../utils/utils";
import { PAGE_VARIABLES } from "../constants";

const Patientpopup = ({ show, handleClose, id }) => {
  const handleLinkClick = async (item) => {
    const result = await axios.get(`/v1/s3/signed/url?fileName=${item.file}`);
    window.open(result?.data?.url, "_blank");
  };
  const [patientdata, setpatientdata] = useState([]);
  const [healthfiles, sethealthfiles] = useState([]);
  const [profiledata, setprofiledata] = useState([]);

  const getpatientdata = async (id) => {
    const { data } = await axios.get(`/v1/healthProfile?userId=${id}`);
    setpatientdata(data);
  };

  const getHealthFiles = async (id) => {
    const { data } = await axios.get(`/v1/healthProfile/file?userId=${id}`);
    sethealthfiles(data);
  };
  const getprofiledata = async (id) => {
    const { data } = await axios.get(`/v1/patient/${id}`);
    setprofiledata(data);
  };
  useEffect(() => {
    getpatientdata(id);
    getHealthFiles(id);
    getprofiledata(id);
  }, []);

  return (
    <div className="patient-modal">
      <Modal
        show={show}
        onHide={handleClose}
        scrollable={true}
        style={{
          height: "100%",
        }}
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="d-flex justify-content-between align-items-start ms-2">
              <div className="d-flex flex-column">
                <h2 className="fontweight-bold mb-1">Patient Info</h2>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 pt-0">
          <div className={`px-3 text-center`}>
            <div className="">
              <div className="row ps-2">
                <div
                  className="col-3 text-start"
                  style={{ fontSize: "13px", fontWeight: "700" }}
                >
                  <p className="text-primary">Name :</p>
                  <p className="text-primary">Gender :</p>
                  <p className="text-primary">DOB :</p>
                  <p className="text-primary">Age :</p>
                  <p className="text-primary">Phone :</p>
                  <p className="text-primary">Email ID :</p>
                </div>
                <div className="col-9" style={{ fontSize: "13px" }}>
                  <p className="text-start">{profiledata?.name}</p>
                  <p className="text-start text-capitalize">
                    {profiledata?.gender
                      ? profiledata?.gender.toLowerCase()
                      : "-"}
                  </p>
                  <p className="text-start">
                    {moment(profiledata?.dob).format("MM/DD/YYYY")}
                  </p>
                  <p className="text-start">
                    {getAge(profiledata?.dob) ? getAge(profiledata?.dob) : "-"}
                  </p>
                  <p className="text-start">{profiledata?.phoneNumber}</p>
                  <p
                    className="text-start text-truncate"
                    title={profiledata?.email}
                  >
                    {profiledata?.email}
                  </p>
                </div>
              </div>
              <hr />
              {PAGE_VARIABLES?.HEALTH_PROFILE?.map((profile) => (
                <div className="border-bottom border-secondary-subtle py-2">
                  <div className="d-flex justify-content-between align-items-center padding-bottom-15px">
                    <div className="d-flex flex-column flex -fill w-100">
                      <p className="m-0 mt-1 mb-2 fw-bold ls-1px">
                        {profile?.name}
                      </p>
                      {profile?.name !== "HEALTH FILES" ? (
                        <Row>
                          {patientdata?.filter(
                            (data) =>
                              data.type === profile?.value &&
                              data?.period === "current"
                          ).length > 0 ? (
                            <Col md={6}>
                              <h6
                                className="m-0 mt-3 fw-bold ls-1px"
                                style={{ color: "#0071ff" }}
                              >
                                CURRENT
                              </h6>
                              {patientdata?.map((type) =>
                                type?.type === profile?.value &&
                                type?.period === "current" ? (
                                  <p className="m-0 mt-3">{type.record} </p>
                                ) : (
                                  ""
                                )
                              )}
                            </Col>
                          ) : (
                            <>
                              {patientdata?.filter(
                                (data) =>
                                  data.type === profile?.value &&
                                  data?.period === "past"
                              ).length > 0 ? (
                                <></>
                              ) : (
                                <Col md={6}>
                                  <h6 className="m-0 mt-3 fw-bold ls-1px">
                                    None
                                  </h6>
                                </Col>
                              )}
                            </>
                          )}
                          <Col md={profile.name === "SURGERIES" ? 12 : 6}>
                            {patientdata?.filter(
                              (data) =>
                                data.type === profile?.value &&
                                data?.period === "past"
                            ).length > 0 ? (
                              <>
                                {profile?.name !== "SURGERIES" && (
                                  <h6
                                    className="m-0 mt-3 fw-bold ls-1px"
                                    style={{ color: "#0071ff" }}
                                  >
                                    PAST
                                  </h6>
                                )}
                                {patientdata?.map((type) =>
                                  type?.type === profile?.value &&
                                  type?.period === "past" ? (
                                    <p className="m-0 mt-3">{type.record} </p>
                                  ) : (
                                    ""
                                  )
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      ) : healthfiles?.length ? (
                        healthfiles?.map((item) => (
                          <Row>
                            <Col
                              md={12}
                              className="shadow-sm me-3 p-3 mb-3 bg-white  border rounded"
                            >
                              <h4 className="fw-semibold fontsize-20px">
                                {item.fileName}
                              </h4>
                              <div className="float-right">
                                <span
                                  className="btn btn-outline-light text-black border-0"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleLinkClick(item)}
                                >
                                  <FaEye className="cursor-pointer" />
                                </span>
                              </div>
                              <p className="text-muted">
                                Uploaded on{" "}
                                {moment(item.createdAt).format("MMM Do YY")}
                                <br />
                                <small className="text-black">
                                  {item?.description
                                    ? item?.description
                                    : item.fileName}
                                </small>
                              </p>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <h6 className="m-0 mt-3 fw-bold ls-1px">None</h6>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Patientpopup;

import React from "react";

const AnalyticsTile = ({ type, arrow, percentage, value, onClick }) => {
  return (
    <div className="col-lg-3">
      <div className="bg-white pt-3 pb-1 rounded" onClick={onClick}>
        <h5 className="mb-1 mb-2  text-center">{type}</h5>
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-center">
            <p className="mb-0 text-center fw-800" style={{ fontSize: "40px" }}>
              {value}
            </p>
            {arrow ? (
              <img
                src={arrow}
                alt="upArrow"
                width="25px"
                height="25px"
                className="mt-3"
              />
            ) : (
              <></>
            )}
            <small className="my-4 fontsize-12px">
              {percentage.toString() === "Infinity" ? "" : `${percentage}%`}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsTile;

export const pharmacy = [
  {
    location: {
      type: "Point",
      coordinates: [-86.8461943, 33.3560865],
    },
    services: ["Joint Replacement", "Shoulder"],
    specilaizations: ["Knee", "Hand"],
    active: true,
    publish: true,
    education: [
      {
        degree: "MD",
        college: "University of South Alabama College of Medicine",
        completionyear: "1989",
      },
      {
        degree: "Residency",
        college: "University of South Alabama Hospitals",
        completionyear: "",
      },
    ],
    training: [],
    mopRecommended: false,
    deleted: false,
    askAnExpert: false,
    certifications: [],
    affiliations: [
      {
        hospital_name: "Brookwood Baptist",
        hospital_city: "",
      },
    ],
    payment_accepted_methods: [],
    leadership_title: [],
    leadership_first_name: [],
    leadership_last_name: [],
    service_lines: [],
    modalities_available: [],
    programs: [],
    leadership_team: [],
    trainers_available: [],
    teams_affiliated: [],
    schools_affiliated: [],
    profile_claimed_status: "created",
    isMOPAdminCreated: true,
    likedFMSExercises: [],
    sortingType: 1,
    planStatus: "inactive",
    isPaid: false,
    hide: false,
    slug_history: [],
    _id: "633f26257ad9d5ba4961bd2a",
    gender: "male",
    website: "precisionsportsortho.com",
    biography:
      "Robert Dean Lolley, MD, is a board-certified Orthopedic Surgeon with more than 20 years’ experience treating patients. He obtained his medical degree and completed his Orthopedic Surgery Residency at the University of South Alabama College of Medicine in Mobile, Alabama.",
    clinic: [
      {
        businessHours: [
          {
            startDay: "Monday",
            endDay: "Friday",
            startTime: "08:00 AM",
            endTime: "05:00 PM",
          },
        ],
        _id: "633f26257ad9d5ba4961bd2b",
        name: "Precision Sports Medicine and Orthopedics",
        addressLine: "5295 Preserve Parkway",
        city: "Hoover",
        state: "Alabama",
        country: "United States",
        zipcode: "35244",
      },
    ],
    firstName: "Robert",
    lastName: "Lolley",
    email: "tbdo3@tbd.com",
    prefix: "Dr",
    suffix: "MD",
    phoneNumber: 2053809761,
    userType: "orthopedic_surgeon",
    videoUrl: "https://youtu.be/yZI16I9PMCs",
    emailIds: ["", "", "", ""],
    googleProfile: "",
    years_of_experience: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
    photo:
      "https://prod-mop.s3.us-east-2.amazonaws.com//contents/c69d78e1642b8c5b84d69f438b6217ca?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUEZ5743M3SSO6SER%2F20221006%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20221006T185827Z&X-Amz-Expires=3600&X-Amz-Signature=1b2639c18482489b417e8301aaf185ea76fd7fd5b8e810984aa0e7a14818fdf0&X-Amz-SignedHeaders=host",
    userId: "633f26257ad9d5ba4961bd27",
    name: "Robert Lolley",
    practice_info: [],
    classes_info: [],
    createdAt: "2022-10-06T19:01:57.775Z",
    updatedAt: "2023-11-08T07:33:55.452Z",
    googlePlaceId: "ChIJt1ZoceMhiYgRzViMmfygTKM",
    googleRatingsCount: 0,
    hours_of_operation: [],
    rating: "0",
    slug: "robert-lolley",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    following: [],
    webLinks: [],
    id: "633f26257ad9d5ba4961bd2a",
  },
  {
    location: {
      type: "Point",
      coordinates: [-86.7877505, 33.4530812],
    },
    services: ["Hand", "Shoulder"],
    specilaizations: ["Orthopedic Surgery", "Sports Medicine"],
    active: true,
    publish: true,
    education: [
      {
        degree: "MD",
        college: "Harvard Medical School",
        completionyear: "2008",
      },
    ],
    training: [
      {
        trainingType: "Residency",
        location:
          "University of Iowa Hospitals and Clinics, Department of Orthopaedics and Rehabilitation",
        completionyear: "",
      },
    ],
    mopRecommended: false,
    deleted: false,
    askAnExpert: true,
    certifications: [],
    affiliations: [],
    payment_accepted_methods: [],
    leadership_title: [],
    leadership_first_name: [],
    leadership_last_name: [],
    service_lines: [],
    modalities_available: [],
    programs: [],
    leadership_team: [],
    trainers_available: [],
    teams_affiliated: [],
    schools_affiliated: [],
    profile_claimed_status: "created",
    isMOPAdminCreated: true,
    likedFMSExercises: [],
    sortingType: 1,
    planStatus: "inactive",
    isPaid: false,
    hide: false,
    slug_history: [],
    _id: "630f591b0e49dff0d6b4ae1f",
    emailIds: ["", "", "", ""],
    clinic: [
      {
        businessHours: [
          {
            startDay: "Monday",
            endDay: "Thursday",
            startTime: "08:00 AM",
            endTime: "05:00 PM",
          },
          {
            startDay: "Friday",
            endDay: "Friday",
            startTime: "08:00 AM",
            endTime: "02:00 PM",
          },
        ],
        _id: "637697ae507f68d9b31a9933",
        zipcode: "35216",
        country: "United States",
        state: "Alabama",
        city: "Birmingham",
        addressLine: "200 Montgomery Hwy Ste 125",
        name: "Precision Sports Medicine and Orthopedics",
      },
    ],
    videoUrl: "",
    photo:
      "https://prod-mop.s3.us-east-2.amazonaws.com//contents/31a6d200920550d3539ae07219f90c72?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUEZ5743M3SSO6SER%2F20221117%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20221117T201758Z&X-Amz-Expires=3600&X-Amz-Signature=c8c5f74d3a1171f8760dafd82a2038d22c298f15009289b78a0206d3c57acd70&X-Amz-SignedHeaders=host",
    biography:
      "Dr. Carlo graduated from the Harvard Medical School, Harvard Medical School in 2008. He works in Birmingham, AL and 1 other location and specializes in Hand Surgery and Orthopedic Surgery.",
    userType: "orthopedic_surgeon",
    website: "www.precisionsportsortho.com",
    phoneNumber: 2058224357,
    suffix: "MD",
    prefix: "Dr",
    email: "tbd130@tbd.com",
    lastName: "Carlo",
    firstName: "Julian",
    googleProfile: "",
    userId: "630f591b0e49dff0d6b4ae1b",
    name: "Julian Carlo",
    createdAt: "2022-08-31T12:50:35.450Z",
    updatedAt: "2023-11-08T07:30:26.567Z",
    dob: "",
    gender: "male",
    googlePlaceId: "ChIJI8xDussZiYgRjeRFUVjpYRU",
    googleRatingsCount: 5,
    rating: "2.8",
    classes_info: [],
    hours_of_operation: [],
    practice_info: [],
    slug: "julian-carlo",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    following: [],
    webLinks: [],
    id: "630f591b0e49dff0d6b4ae1f",
  },
  {
    location: {
      type: "Point",
      coordinates: [-86.9406164, 31.4309603],
    },
    services: [],
    specilaizations: [],
    active: true,
    publish: true,
    education: [],
    training: [],
    mopRecommended: false,
    deleted: false,
    askAnExpert: true,
    certifications: [],
    affiliations: [],
    payment_accepted_methods: [],
    leadership_title: [],
    leadership_first_name: [],
    leadership_last_name: [],
    service_lines: [],
    modalities_available: [],
    programs: [],
    leadership_team: [],
    trainers_available: [],
    teams_affiliated: [],
    schools_affiliated: [],
    profile_claimed_status: "created",
    isMOPAdminCreated: true,
    likedFMSExercises: [],
    sortingType: 3,
    planStatus: "paused",
    isPaid: true,
    hide: false,
    slug_history: ["william-roy-farmer-md"],
    _id: "643ea54a66d20267ccb81bb8",
    userType: "primary_care",
    firstName: "William Roy Farmer, MD",
    lastName: " ",
    email: "alabama_yellow_pages_searches_2412@mop.com",
    address: "101 Crestview Ave",
    city: "Evergreen",
    state: "AL",
    zipcode: "36401",
    country: "USA",
    membershipId: "633269d80a45dc563a819acf",
    phoneNumber: 2515782480,
    isEmailAutoGenerated: true,
    isScriptCreated: true,
    scriptName: "alabama_download_3_27_2023",
    scriptInitDateTime: "2023-04-18T13:53:21.544Z",
    website: "http://evergreenmedical.org/",
    clinic: [
      {
        businessHours: [],
        _id: "643ea54a66d20267ccb81bb9",
        addressLine: "101 Crestview Ave",
        city: "Evergreen",
        state: "AL",
        country: "USA",
        zipcode: "36401",
      },
    ],
    googlePlaceId: "ChIJC_swPHUtkIgRc9fiTl6Roi0",
    userId: "643ea54a66d20267ccb81bb6",
    name: "William Roy Farmer, MD",
    practice_info: [],
    classes_info: [],
    hours_of_operation: [],
    webLinks: [],
    following: [],
    slug: "william-roy-farmer-md",
    createdAt: "2023-04-18T14:12:26.920Z",
    updatedAt: "2023-11-02T07:09:09.402Z",
    planType: "premium",
    stripeCheckoutSessionId:
      "cs_test_a1vLOOo5KAhCoX27EigH0kg7CePQKheJl7E9co8FQfDzGntwM0Z3568Uno",
    stripeCustomerId: "cus_Ovmam3ltg5olhq",
    stripeInvoiceId: "in_1O7v1FCihAbo0Fr4mkZmscXv",
    stripePaymentStatus: "active",
    stripePriceId: "price_1NBb5GCihAbo0Fr4mH04hjy4",
    stripeSubscriptionId: "sub_1O7v1FCihAbo0Fr4SvW8ETjH",
    stripeDefaultPaymentMethod: "pm_1O7v1ECihAbo0Fr4C2pmxTyg",
    stripePaymentIntentId: "pi_3O7v1FCihAbo0Fr40HFf9nhm",
    planStatusAt: 1698908949,
    stripeProductId: "prod_NsBo9zdEAVyerN",
    id: "643ea54a66d20267ccb81bb8",
  },
  {
    location: {
      type: "Point",
      coordinates: [-122.433859, 37.8043976],
    },
    services: ["Knee", "Shoulder", "Ankle"],
    specilaizations: [],
    active: true,
    publish: true,
    education: [
      {
        degree: "MD",
        college: "The University of North Carolina School of Medicine",
        completionyear: "1981",
      },
    ],
    training: [
      {
        trainingType: "Residency",
        location: "Harvard University",
        completionyear: "1986",
      },
      {
        trainingType: "Residency",
        location: "Stanford University Hospital",
        completionyear: "1983",
      },
      {
        trainingType: "Intern",
        location: "Beth Israel Hospital",
        completionyear: "1982",
      },
    ],
    mopRecommended: false,
    deleted: false,
    askAnExpert: false,
    certifications: [],
    affiliations: [],
    payment_accepted_methods: [],
    leadership_title: [],
    leadership_first_name: [],
    leadership_last_name: [],
    service_lines: [],
    modalities_available: [],
    programs: [],
    leadership_team: [],
    trainers_available: [],
    teams_affiliated: [],
    schools_affiliated: [],
    profile_claimed_status: "created",
    isMOPAdminCreated: true,
    likedFMSExercises: [],
    sortingType: 1,
    planStatus: "inactive",
    isPaid: false,
    hide: false,
    slug_history: ["kevin-stone"],
    _id: "63b3037da09ad391d12d1f60",
    gender: "male",
    website: "stoneclinic.com",
    biography:
      "Dr. Stone was trained at Harvard University in internal medicine and orthopaedic surgery and at Stanford University in general surgery. His fellowship was in research at the Hospital for Special Surgery and in knee surgery in Lake Tahoe.  Dr. Stone is consistently listed as one of the country’s top 25 Ambulatory Surgery Center (ASC) leaders and ranks amongst the country’s top 75 knee surgeons.1\n\nHe is a physician for Smuin Ballet and has served as a physician for the U.S. Ski Team, the U.S. Pro Ski Tour, the Honda Ski Tour, the Jeep 48 Straight Tour, the Old Blues Rugby Club, Lawrence Pech Dance Company, Marin Ballet, the modern pentathlon at the U.S. Olympic Festival, the United States Olympic Training Center, and for the World Pro Ski Tour. He's been featured on Good Morning America, the Ologies podcast, and Star Talk podcast hosted by Neal deGrasse Tyson. He is the best-selling author of the book Play Forever. He also served as a columnist for the San Francisco Examiner and writes a weekly blog at www.stoneclinic.com/blog.\n\nDr. Stone enjoys skiing, windsurfing, and biking.",
    clinic: [
      {
        businessHours: [
          {
            startDay: "Monday",
            endDay: "Friday",
            startTime: "08:00 AM",
            endTime: "05:00 PM",
          },
        ],
        _id: "63b3037da09ad391d12d1f61",
        name: "The Stone Clinic",
        addressLine: "3727 Buchanan St #300",
        city: "San Francisco",
        state: "California",
        country: "United States",
        zipcode: "94123",
      },
    ],
    firstName: "Kevin",
    lastName: "Stone",
    email: "tbdoc1@tbd.com",
    prefix: "Dr",
    suffix: "MD",
    phoneNumber: 4155633110,
    userType: "orthopedic_surgeon",
    videoUrl: "https://youtu.be/3leB3B5R11c",
    emailIds: ["", "", "", ""],
    googleProfile: "",
    years_of_experience: "36",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
    userId: "63b3037da09ad391d12d1f5d",
    name: "Kevin Stone",
    practice_info: [],
    classes_info: [],
    hours_of_operation: [],
    webLinks: [],
    slug: "kevin-stone",
    createdAt: "2023-01-02T16:17:01.124Z",
    updatedAt: "2023-10-25T07:42:33.587Z",
    googlePlaceId: "ChIJDd8IQdiAhYARA_ZS9KALm4w",
    googleRatingsCount: 37,
    photo:
      "https://prod-mop.s3.us-east-2.amazonaws.com//contents/45f8d7069a2ac2d2e5abfd63b4b2367b?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUEZ5743M3SSO6SER%2F20230102%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230102T161713Z&X-Amz-Expires=3600&X-Amz-Signature=52b51b5b52c12cb6d419796e76b03f5e3359f185a8bae808e9eef8766ceaacc4&X-Amz-SignedHeaders=host",
    rating: "4.9",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    following: [],
    id: "63b3037da09ad391d12d1f60",
  },
  {
    location: {
      type: "Point",
      coordinates: [-86.77389459999999, 33.3601981],
    },
    services: ["Spine"],
    specilaizations: [],
    active: true,
    publish: true,
    education: [
      {
        degree: "MD",
        college: "University of Kentucky",
        completionyear: "",
      },
      {
        degree: "Residency",
        college: "University of South Alabama",
        completionyear: "",
      },
    ],
    training: [
      {
        trainingType: "Spine Fellowship",
        location: "Twin Cities Spine Center",
        completionyear: "",
      },
    ],
    mopRecommended: false,
    deleted: false,
    askAnExpert: false,
    certifications: [],
    affiliations: [],
    payment_accepted_methods: [],
    leadership_title: [],
    leadership_first_name: [],
    leadership_last_name: [],
    service_lines: [],
    modalities_available: [],
    programs: [],
    leadership_team: [],
    trainers_available: [],
    teams_affiliated: [],
    schools_affiliated: [],
    profile_claimed_status: "created",
    isMOPAdminCreated: true,
    likedFMSExercises: [],
    sortingType: 1,
    planStatus: "inactive",
    isPaid: false,
    hide: false,
    slug_history: [],
    _id: "633f395f7ad9d5ba4961bee8",
    gender: "",
    website: "www.southlakeorthopedics.com",
    biography:
      "We are thrilled to have Dr. Kim on board with us at Southlake Orthopaedics. He will see patients at both locations Grandview and at our MedPlex office off Valleydale Road. Dr. Kim’s specialty is spine surgery.  He most recently completed his fellowship with Twin Cities Spine Surgeons, one of the birthplaces of modern spine surgery.  Dr. Kim’s goal is to continue providing the best standards in spine care from spinal deformity surgery to minimally invasive procedures. Dr. Kim completed medical school from the University of Kentucky in Lexington, and then completed his orthopaedic residency training at the University of South Alabama, where he served as Academic Chief Resident.  He also holds a Master of Science in Physiology from Georgetown University, Washington, DC.",
    clinic: [
      {
        businessHours: [
          {
            startDay: "Monday",
            endDay: "Friday",
            startTime: "08:00 AM",
            endTime: "05:00 PM",
          },
        ],
        _id: "633f395f7ad9d5ba4961bee9",
        name: "Southlake Orthopaedics",
        addressLine: "4517 Southlake Parkway",
        city: "Hoover",
        state: "",
        country: "United States",
        zipcode: "35244",
      },
      {
        businessHours: [
          {
            startDay: "Monday",
            endDay: "Friday",
            startTime: "08:00 AM",
            endTime: "05:00 PM",
          },
        ],
        _id: "636157bf8744fb66b31b078c",
        name: "Southlake Orthopaedics",
        addressLine: "3686 Grandview Parkway, Suite 430",
        city: "Birmingham",
        state: "Alabama",
        country: "United States",
        zipcode: "35243",
      },
    ],
    firstName: "Daniel",
    lastName: "Kim",
    email: "tbdo12@tbd.com",
    prefix: "Dr",
    suffix: "MD",
    phoneNumber: 2059854111,
    userType: "orthopedic_surgeon",
    videoUrl: "https://youtu.be/khHyxwCm7KA",
    emailIds: ["", "", "", ""],
    googleProfile: "",
    years_of_experience: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
    photo:
      "https://prod-mop.s3.us-east-2.amazonaws.com//contents/b393c60e37edf9e1b90d658088754a32?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUEZ5743M3SSO6SER%2F20221006%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20221006T202058Z&X-Amz-Expires=3600&X-Amz-Signature=37ebcfde957f01101be9592f96dceae2a3249072d4e0894c0c0b8fd25f24f073&X-Amz-SignedHeaders=host",
    userId: "633f395f7ad9d5ba4961bee5",
    name: "Daniel Kim",
    practice_info: [],
    classes_info: [],
    createdAt: "2022-10-06T20:23:59.506Z",
    updatedAt: "2023-10-25T07:36:03.914Z",
    googlePlaceId: "ChIJv1jAhb0iiYgRj8Q6zEnYq-s",
    googleRatingsCount: 1210,
    rating: "4.8",
    hours_of_operation: [],
    slug: "daniel-kim",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    following: [],
    webLinks: [],
    id: "633f395f7ad9d5ba4961bee8",
  },
  {
    location: {
      type: "Point",
      coordinates: [-86.7180041, 33.4329505],
    },
    services: [],
    specilaizations: [],
    active: true,
    publish: true,
    education: [],
    training: [],
    mopRecommended: false,
    deleted: false,
    askAnExpert: true,
    certifications: [],
    affiliations: [],
    payment_accepted_methods: [],
    leadership_title: [],
    leadership_first_name: [],
    leadership_last_name: [],
    service_lines: [],
    modalities_available: [],
    programs: [],
    leadership_team: [
      {
        teamTitle: "CEO",
        firstName: "Drew",
        lastName: "Mason",
        serviceLines: "",
      },
    ],
    trainers_available: [],
    teams_affiliated: [],
    schools_affiliated: [],
    profile_claimed_status: "created",
    isMOPAdminCreated: true,
    likedFMSExercises: [],
    sortingType: 1,
    planStatus: "inactive",
    isPaid: false,
    hide: false,
    slug_history: [],
    _id: "63629fe4f70362581ee1afaf",
    biography:
      "At Grandview Medical Center, we believe in the power of people to create great care. Our dedicated patient care team consists of staff, volunteers and physicians representing all major specialties. We work hard every day to be a place of healing, caring and connection for patients and families in the community we call home.",
    firstName: "Grandview Medical Center",
    address: "3690 Grandview Parkway",
    city: "Birmingham",
    state: "Alabama",
    zipcode: "35243",
    phoneNumber: 2059711000,
    email: "tbdh1@tbd.com",
    userType: "hospital",
    videoUrl: "https://vimeo.com/141936733",
    facebook: "https://www.facebook.com/GrandviewMedical",
    twitter: "https://twitter.com/GrandviewMC",
    instagram: "",
    youtube: "",
    linkedin: "",
    photo:
      "https://prod-mop.s3.us-east-2.amazonaws.com//contents/4051539e7e8cb3b0312935dc007344ed?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUEZ5743M3SSO6SER%2F20221102%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20221102T163006Z&X-Amz-Expires=3600&X-Amz-Signature=739ccc7f35de6e73dace9022153f4c00f07db1a6b2049bc21cd37ab9e97824f3&X-Amz-SignedHeaders=host",
    lastName: "Hospital",
    userId: "63629fe4f70362581ee1afac",
    name: "Grandview Medical Center Hospital",
    clinic: [
      {
        businessHours: [
          {
            startDay: "",
            endDay: "",
            startTime: "",
            endTime: "",
          },
        ],
        _id: "64188f2b69021642258a50ec",
        name: "",
        addressLine: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
      },
    ],
    practice_info: [],
    classes_info: [],
    hours_of_operation: [],
    createdAt: "2022-11-02T16:50:44.325Z",
    updatedAt: "2023-10-25T07:32:56.150Z",
    googlePlaceId: "ChIJ1VTE7qMXiYgR-qjhoc-j9m8",
    googleRatingsCount: 6215,
    rating: "4.5",
    slug: "grandview-medical-center-hospital",
    following: [],
    webLinks: [],
    id: "63629fe4f70362581ee1afaf",
  },
  {
    location: {
      type: "Point",
      coordinates: [-86.8472319, 33.4985089],
    },
    services: ["Sports Medicine"],
    specilaizations: [],
    active: true,
    publish: true,
    education: [
      {
        degree: "MD",
        college: "Meharry Medical College",
        completionyear: "",
      },
      {
        degree: "Residency",
        college: "University of California School of Medicine",
        completionyear: "",
      },
    ],
    training: [
      {
        trainingType: "Fellowship",
        location: "American Sports Medicine Institute",
        completionyear: "",
      },
    ],
    mopRecommended: false,
    deleted: false,
    askAnExpert: false,
    certifications: [],
    affiliations: [
      {
        hospital_name: "Baptist",
        hospital_city: "",
      },
    ],
    payment_accepted_methods: [],
    leadership_title: [],
    leadership_first_name: [],
    leadership_last_name: [],
    service_lines: [],
    modalities_available: [],
    programs: [],
    leadership_team: [],
    trainers_available: [],
    teams_affiliated: [],
    schools_affiliated: [],
    profile_claimed_status: "created",
    isMOPAdminCreated: true,
    likedFMSExercises: [],
    sortingType: 1,
    planStatus: "inactive",
    isPaid: false,
    hide: false,
    slug_history: [],
    _id: "633f29f17ad9d5ba4961bdb9",
    gender: "male",
    website: "Precisionsportsortho.com",
    biography:
      "Dr. Robert Agee is a board-certified family practice physician who is fellowship trained in sports medicine.  Dr. Agee earned his bachelor's degree from Morehouse College and went on to complete his medical degree at Meharry Medical College.  He finished his family practice residency at UCSF Fresno and his sports medicine fellowship at the American Sports Medicine Institute in Birmingham.  During his career, Dr. Agee has served in clinical leadership roles as Chief of Primary Care/Sports Medicine, Medical Director, and Fellowship Director.  Dr. Agee has more than 10 years of experience caring for elite athletes from the NFL, MLB, NBA, and the NHL, and has been the past Medical Director for Legion Field and the NFL European League.",
    clinic: [
      {
        businessHours: [
          {
            startDay: "Monday",
            endDay: "Friday",
            startTime: "08:00 AM",
            endTime: "05:00 PM",
          },
        ],
        _id: "633f29f17ad9d5ba4961bdba",
        name: "Presicion Sports Medicine and Orthopedics - Princeton",
        addressLine: "801 Princeton Ave SW",
        city: "Birmingham",
        state: "Alabama",
        country: "United States",
        zipcode: "35211",
      },
      {
        businessHours: [
          {
            startDay: "Monday",
            endDay: "Friday",
            startTime: "08:00 AM",
            endTime: "05:00 PM",
          },
        ],
        _id: "636169ce8744fb66b31b082d",
        name: "Precision Sports Medicine and Orthopedics",
        addressLine: "200 Montgomery Highway Suite 225",
        city: "Vestavia",
        state: "Alabama",
        country: "United States",
        zipcode: "35216",
      },
    ],
    firstName: "Robert",
    lastName: "Agee",
    email: "tbdo6@tbd.com",
    prefix: "Dr",
    suffix: "MD",
    phoneNumber: 2052642816,
    userType: "orthopedic_surgeon",
    videoUrl: "",
    emailIds: ["", "", "", ""],
    googleProfile: "",
    years_of_experience: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
    photo:
      "https://prod-mop.s3.us-east-2.amazonaws.com//contents/23ce9db57eebe4c24079eff9280fabcd?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUEZ5743M3SSO6SER%2F20221006%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20221006T191536Z&X-Amz-Expires=3600&X-Amz-Signature=3b6a75e9a0178d4e439fe094dc281fc323194a1d5f1b989c809e28439351b0db&X-Amz-SignedHeaders=host",
    userId: "633f29f17ad9d5ba4961bdb6",
    name: "Robert Agee",
    practice_info: [],
    classes_info: [],
    createdAt: "2022-10-06T19:18:09.679Z",
    updatedAt: "2023-10-25T05:25:06.482Z",
    googlePlaceId: "ChIJT-cisiQciYgRQbq9xtrQtHg",
    googleRatingsCount: 0,
    rating: "0",
    hours_of_operation: [],
    slug: "robert-agee",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    following: [],
    webLinks: [],
    id: "633f29f17ad9d5ba4961bdb9",
  },
  {
    location: {
      type: "Point",
      coordinates: [0, 0],
    },
    services: [],
    specilaizations: [],
    active: true,
    publish: true,
    education: [],
    training: [],
    mopRecommended: false,
    deleted: false,
    askAnExpert: false,
    certifications: [
      {
        year: "",
      },
    ],
    affiliations: [],
    payment_accepted_methods: [],
    leadership_title: [],
    leadership_first_name: [],
    leadership_last_name: [],
    service_lines: [],
    modalities_available: [],
    programs: [],
    leadership_team: [],
    trainers_available: [],
    teams_affiliated: [],
    schools_affiliated: [],
    profile_claimed_status: "created",
    isMOPAdminCreated: true,
    likedFMSExercises: [],
    sortingType: 1,
    planStatus: "inactive",
    isPaid: false,
    hide: false,
    slug_history: ["velan-murugan"],
    _id: "652e5fda3e3ab57664baf89c",
    gender: "male",
    website: "",
    biography: "",
    clinic: [
      {
        businessHours: [
          {
            startDay: "",
            endDay: "",
            startTime: "",
            endTime: "",
          },
        ],
        _id: "652e5fda3e3ab57664baf89d",
        addressLine: "",
        name: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
      },
    ],
    firstName: "Velan",
    lastName: "Murugan",
    prefix: "Dr",
    suffix: "MD",
    phoneNumber: 8795462130,
    userType: "nurse_practitioner",
    npiId: "1043461445",
    videoUrl: "",
    emailIds: ["", "", "", ""],
    googleProfile: "",
    years_of_experience: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
    email: "velan_nurse_lfr1zf@provider.com",
    userId: "652e5fda3e3ab57664baf899",
    name: "Velan Murugan",
    practice_info: [],
    classes_info: [],
    hours_of_operation: [],
    webLinks: [],
    following: [],
    slug: "velan-murugan",
    createdAt: "2023-10-17T10:20:10.709Z",
    updatedAt: "2023-10-17T10:20:10.709Z",
    stripeCustomerId: "cus_Opq3mz4e4AKouB",
    id: "652e5fda3e3ab57664baf89c",
  },
  {
    rehab_info: {
      name: "MindBodyFit PHYSIOTHERAPY & WELLNESS CLINIC | Best Physiotherapy Clinic in Pune | Best Physiotherapist in Pune",
      address:
        "Office no 523, Vision One Mall, office tower, Bhumkar Chowk Rd, near ginger hotel, Tathawade, pune,",
      city: "Pimpri-Chinchwad,",
      state: "Maharashtra",
      zipcode: "41103",
      phoneNumber: null,
    },
    location: {
      type: "Point",
      coordinates: [0, 0],
    },
    services: [],
    specilaizations: [],
    active: true,
    publish: true,
    education: [],
    training: [],
    mopRecommended: false,
    deleted: false,
    askAnExpert: false,
    certifications: [
      {
        year: "",
      },
    ],
    affiliations: [],
    payment_accepted_methods: [""],
    leadership_title: [],
    leadership_first_name: [],
    leadership_last_name: [],
    service_lines: [],
    modalities_available: [],
    programs: [],
    leadership_team: [],
    trainers_available: [],
    teams_affiliated: [],
    schools_affiliated: [],
    profile_claimed_status: "created",
    isMOPAdminCreated: true,
    likedFMSExercises: [],
    sortingType: 1,
    planStatus: "inactive",
    isPaid: false,
    hide: false,
    slug_history: ["madhu-mitha-4"],
    _id: "65200dfb83739430a54436c8",
    biography: "",
    firstName: "Madhu",
    lastName: "Mitha",
    suffix: "",
    website: "",
    userType: "physical_therapist",
    philosophy: "",
    videoUrl: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
    years_of_experience: "",
    phoneNumber: null,
    email: "madhu_physical_dgb7lo@provider.com",
    userId: "65200dfb83739430a54436c5",
    name: "Madhu Mitha",
    clinic: [],
    practice_info: [],
    classes_info: [],
    hours_of_operation: [],
    webLinks: [],
    following: [],
    slug: "madhu-mitha-4",
    createdAt: "2023-10-06T13:39:07.742Z",
    updatedAt: "2023-10-16T09:53:36.863Z",
    stripeCustomerId: "cus_OllmPlDfOWSSYp",
    googlePlaceId: "ChIJqR3233q5wjsR87QddjIponQ",
    googleRatingsCount: 17,
    rating: "5",
    outscraperFetchAddress:
      "Madhu, Mitha, MindBodyFit PHYSIOTHERAPY & WELLNESS CLINIC | Best Physiotherapy Clinic in Pune | Best Physiotherapist in Pune, Office no 523, Vision One Mall, office tower, Bhumkar Chowk Rd, near ginger hotel, Tathawade, pune, , Pimpri-Chinchwad, ,Maharashtra, 41103",
    type: "physical_therapist",
    banner:
      "https://stage-mop.s3.us-east-2.amazonaws.com//contents/a985c75945ece608b37be9a96129bc5a?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUEZ5743M3SSO6SER%2F20231006%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20231006T134421Z&X-Amz-Expires=3600&X-Amz-Signature=82766e2746986036f1277773ae8f3cd8ac4b9dd4a275ee704e8f7503b4a2f6ff&X-Amz-SignedHeaders=host",
    photo:
      "https://stage-mop.s3.us-east-2.amazonaws.com//contents/9ce8b7ce60ac1b35e39891e42f5918ae?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUEZ5743M3SSO6SER%2F20231006%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20231006T134411Z&X-Amz-Expires=3600&X-Amz-Signature=c394431b8469199ebb7d7f69b41aeddd17a3209a01e2f3df0e1755dd4a512d75&X-Amz-SignedHeaders=host",
    id: "65200dfb83739430a54436c8",
  },
  {
    location: {
      type: "Point",
      coordinates: [0, 0],
    },
    services: [],
    specilaizations: [],
    active: true,
    publish: true,
    education: [],
    training: [],
    mopRecommended: false,
    deleted: false,
    askAnExpert: false,
    certifications: [
      {
        year: "",
      },
    ],
    affiliations: [],
    payment_accepted_methods: [],
    leadership_title: [],
    leadership_first_name: [],
    leadership_last_name: [],
    service_lines: [],
    modalities_available: [],
    programs: [],
    leadership_team: [],
    trainers_available: [],
    teams_affiliated: [],
    schools_affiliated: [],
    profile_claimed_status: "created",
    isMOPAdminCreated: true,
    likedFMSExercises: [],
    sortingType: 1,
    planStatus: "inactive",
    isPaid: false,
    hide: false,
    slug_history: ["sanjana-govindh"],
    _id: "652d03c13e3ab57664baf739",
    gender: "female",
    website: "",
    biography: "",
    clinic: [
      {
        businessHours: [
          {
            startDay: "",
            endDay: "",
            startTime: "",
            endTime: "",
          },
        ],
        _id: "652d03c13e3ab57664baf73a",
        addressLine:
          "Ascension St. Vincent's Birmingham 805 St. Vincents Drive Suite 100",
        name: "Andrews Sports Medicine - Birmingham",
        city: "Birmingham",
        state: "AL",
        country: "United States",
        zipcode: "35205",
      },
    ],
    firstName: "Sanjana",
    lastName: "Govindh",
    prefix: "Dr",
    suffix: "MD",
    phoneNumber: 8795462130,
    userType: "neuro_surgeon",
    npiId: "",
    videoUrl: "",
    emailIds: ["", "", "", ""],
    googleProfile: "",
    years_of_experience: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
    email: "sanjana_neuro_ic9zzt@provider.com",
    userId: "652d03c13e3ab57664baf736",
    name: "Sanjana Govindh",
    practice_info: [],
    classes_info: [],
    hours_of_operation: [],
    webLinks: [],
    following: [],
    slug: "sanjana-govindh",
    createdAt: "2023-10-16T09:34:57.917Z",
    updatedAt: "2023-10-16T09:36:30.759Z",
    stripeCustomerId: "cus_OpS6VqbCakrsNC",
    googlePlaceId: "ChIJ8XfWILYbiYgRsrf7SIldmfU",
    googleRatingsCount: 868,
    rating: "4.8",
    photo: "",
    type: "neuro_surgeon",
    id: "652d03c13e3ab57664baf739",
  },
];

export const testimonies = [
  {
    deleted: false,
    approved: false,
    _id: "651cf01719e0d8f25a01e460",
    email: "asdfas@gmail.com",
    userType: "chiropractor",
    firstName: "asdfasd",
    feedback: "asdfasd",
    createdAt: "2023-10-04T04:54:47.077Z",
    updatedAt: "2023-10-04T04:54:47.077Z",
    id: "651cf01719e0d8f25a01e460",
  },
  {
    deleted: false,
    approved: false,
    _id: "651c2d1919e0d8f25a01e06c",
    email: "madhu@gmail.com",
    userType: "orthopedic_surgeon",
    firstName: "madhu",
    feedback:
      "Execellent doctor my mom 68 yrs got her hip ball replacement done opertaion successful a very genuine dr always kept us updated as to wht is done he did biporal as that was required kept us totally stressfree n the recovery is also fast very happy with the doctor n would recommend all for any orthopedic things pls contact doctor Santosh Shetty.ajdsakdhjksadhsjkdbsjdbsjhdsuyhdsudsjhdbshjdbsjhdbshjbfsjhbsjhdb",
    createdAt: "2023-10-03T15:02:49.956Z",
    updatedAt: "2023-10-03T15:02:49.956Z",
    id: "651c2d1919e0d8f25a01e06c",
  },
  {
    deleted: false,
    approved: false,
    _id: "651c2cbe19e0d8f25a01e069",
    email: "madhu@gmail.com",
    userType: "orthopedic_surgeon",
    firstName: "madhu",
    feedback:
      "Execellent doctor my mom 68 yrs got her hip ball replacement done opertaion successful a very genuine dr always kept us updated as to wht is done he did biporal as that was required kept us totally stressfree n the recovery is also fast very happy with the doctor n would recommend all for any orthopedic things pls contact doctor Santosh Shetty.ajdsakdhjksadhsjkdbsjdbsjhdsuyhdsudsjhdbshjdbsjhdbshjbfsjhbsjhdb",
    createdAt: "2023-10-03T15:01:18.331Z",
    updatedAt: "2023-10-03T15:01:18.331Z",
    id: "651c2cbe19e0d8f25a01e069",
  },
  {
    deleted: false,
    approved: false,
    _id: "64801d0d5c1896dddb9d5170",
    email: "tamil.j+samb@concertidc.com",
    userType: "patient",
    firstName: "Nanji",
    lastName: "Samb",
    feedback: "This site is useful and informative",
    createdAt: "2023-06-07T06:00:45.641Z",
    updatedAt: "2023-06-07T06:00:45.641Z",
    id: "64801d0d5c1896dddb9d5170",
  },
];

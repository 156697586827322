import React, { useState, useEffect } from "react";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from "../../../util/axiosInst";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Pagination from "../custom/Pagination";
import { toast } from "react-toastify";
import { deleteSuccessMessage } from "../../../util/constants";

const Created = ({ search }) => {
  const navigate = useNavigate();
  const [newsLetters, setNewsLetter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const getEducation = (page) => {
    const searchCondition = search?.length ? `name=${search}` : "";
    axios.get(`/v1/blog-news/admin?deleted=false&page=${page}&limit=10&${searchCondition}&sortBy=updatedAt:desc`)
      .then((res) => {
        setCurrentPage(page)
        setNewsLetter(res?.data);
      });
  };
  const handleEditContent = async (id) => {
    navigate(`/edit-latest-blogs/${id}`);
  };
  const handleDeleteContent = async () => {
    if (deleteId) {
      try {
        await axios.delete(`v1/blog-news/${deleteId}`);
        toast.success(deleteSuccessMessage);
        getEducation(newsLetters?.results?.length === 1 ? currentPage - 1 : currentPage);
        handleClose();
      } catch (error) {
        getEducation(currentPage);
        handleClose();
      }
    }
  };
  useEffect(() => {
    getEducation(1);
  }, [search]);
  return (
    <div className="mt-3 mx-3 rounded px-3 bg-white">
      <Table className=" ms-1 fs-13px">
        <thead className="border-bottom">
          <th className="text-body-tertiary pt-4 ps-2">TITLE</th>
          <th className="text-body-tertiary">CATEGORY</th>
          <th className="text-body-tertiary pe-5">CREATED DATE</th>
          <th className="text-body-tertiary">ACTIVE</th>
          <th className="text-body-tertiary text-end">ACTION</th>
        </thead>
        <tbody style={{ borderTop: "0px" }} className="mt-5">
          {newsLetters && newsLetters?.docs && newsLetters?.docs.length ?
            (newsLetters && newsLetters?.docs && newsLetters?.docs.map((newsLetter) => (
              < tr >
                <td>{newsLetter?.title}</td>
                <td className="text-justify">{newsLetter?.category.name}</td>
                <td className="text-justify">
                  {moment(newsLetter?.createdAt).format("ll")}
                </td>
                <td className="text-primary">
                  {newsLetter.active ? (
                    <span className="text-green">YES</span>
                  ) : (
                    <span className="text-danger">NO</span>
                  )}
                </td>
                {/* <td className="text-end">
                  <BsThreeDotsVertical className="text-body-tertiary" />
                </td> */}
                < td className="fontweight-bold h5  table-drop py-2 px-0" >
                  <Dropdown className="text-end">
                    <Dropdown.Toggle className="threedrop " id="dropdown-basic">
                      <BsThreeDotsVertical className="text-body-tertiary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => { handleEditContent(newsLetter?._id) }}
                        className="cursor-pointer"
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        handleShow();
                        setDeleteId(newsLetter?._id);
                      }}
                        className="cursor-pointer">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>)))
            : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
        </tbody>
      </Table >
      <Pagination
        onPageChange={(event) => { getEducation(event.selected + 1) }}
        pageRangeDisplayed={10}
        totalPages={newsLetters?.totalDocs / 10}
        currentPageNo={currentPage - 1}
      />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteContent}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
};

export default Created;

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Pharmacy from "./components/pharmacies/Pharmacies";
import Login from "../pages/Login";
import Home from "./components/home/Home";
import "./assets/css/style.scss";
import Admin from "./components/admin/admin";
import NewPatient from "./components/patients/Patient";
import Appoinment from "./components/appointments/Appointments";
import Referrals from "./components/Referrals/Referrals";
import Analytics from "./components/Analytics/Analytics";
import Education from "./components/education/Education";
import Schedule from "./components/Schedule/Schedule";
import NewsRoom from "./components/news-room/NewsRoom";
import NewsLetter from "./components/new-letter/NewsLetter";
import Providers from "./pages/providers/Providers";
import Post from "./pages/providers/provider-post/Post";
import AdminForm from "./components/admin/adminForm";
import CertificationForm from "./components/Certifications/CertificationForm";
import Certifications from "./components/Certifications/Certifications";
import LatestBlogProfile from "./pages/LatestBlogProfile";
import AddLetterprofile from "./components/new-letter/AddLetterprofile";
import TestimoniesForm from "./components/testimonies/CreateTestimonies";
import PatientsForm from "./components/patients/PatientForm";
import Results from "./components/patients/Results";
import ContentForm from "./components/education/ContentForm";
import Testimonies from "./components/testimonies/Testimonies";




const App = () => {
  const RequireAuth = ({ children }) => {
    let isAuthenticated = localStorage.getItem("mop-admin-token");
    return isAuthenticated ? children : <Navigate to={"/login"} />;
  };

  const AuthRoute = ({ children }) => {
    let isAuthenticated = localStorage.getItem("mop-admin-token");
    return !isAuthenticated ? children : <Navigate to={"/"} />;
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <Routes>
        <Route
          path="/pharmacies"
          element={
            <RequireAuth>
              <Pharmacy />
            </RequireAuth>
          }
        />
        <Route
          path="/provider-post/:id"
          element={
            <RequireAuth>
              <Post />
            </RequireAuth>
          }
        />
        <Route
          path="/education"
          element={
            <RequireAuth>
              <Education />
            </RequireAuth>
          }
        />
        <Route
          path="/patient"
          element={
            <RequireAuth>
              <NewPatient />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-patient/:id"
          element={
            <RequireAuth>
              <PatientsForm />
            </RequireAuth>
          }
        />
        <Route
          path="/results/:id"
          element={
            <RequireAuth>
              <Results />
            </RequireAuth>
          }
        />
        <Route
          path="/admin-staff"
          element={
            <RequireAuth>
              <Admin />
            </RequireAuth>
          }
        />
        <Route
          path="/certifications"
          element={
            <RequireAuth>
              <Certifications />
            </RequireAuth>
          }
        />
        <Route
          path="/newsroom"
          element={
            <RequireAuth>
              <NewsRoom />
            </RequireAuth>
          }
        />
        <Route
          path="/add-latest-blogs"
          element={
            <RequireAuth>
              <LatestBlogProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-latest-blogs/:id"
          element={
            <RequireAuth>
              <LatestBlogProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/newsletter"
          element={
            <RequireAuth>
              <NewsLetter />
            </RequireAuth>
          }
        />
        <Route
          path="/add-newsletter"
          element={
            <RequireAuth>
              <AddLetterprofile />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-newsletter/:id"
          element={
            <RequireAuth>
              <AddLetterprofile />
            </RequireAuth>
          }
        />
        <Route
          path="/schedule"
          element={
            <RequireAuth>
              <Schedule />
            </RequireAuth>
          }
        />
        <Route
          path="/appointments"
          element={
            <RequireAuth>
              <Appoinment />
            </RequireAuth>
          }
        />
        <Route
          path="/referrals"
          element={
            <RequireAuth>
              <Referrals />
            </RequireAuth>
          }
        />
        <Route
          path="/analytics"
          element={
            <RequireAuth>
              <Analytics />
            </RequireAuth>
          }
        />
        <Route
          path="/home"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="/testimonies"
          element={
            <RequireAuth>
              <Testimonies />
            </RequireAuth>
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Navigate to={"/home"} />
            </RequireAuth>
          }
        />
        <Route
          path="/login"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          path="/providers"
          element={
            <RequireAuth>
              <Providers />
            </RequireAuth>
          }
        />
        <Route
          path="/certification"
          element={
            <RequireAuth>
              <Certifications />
            </RequireAuth>
          }
        />
        <Route
          path="/create-admin"
          element={
            <RequireAuth>
              <AdminForm />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-admin/:id"
          element={
            <RequireAuth>
              <AdminForm />
            </RequireAuth>
          }
        />
        <Route
          path="/createCertificate"
          element={
            <RequireAuth>
              <CertificationForm />
            </RequireAuth>
          }
        />
        <Route
          path="/editCertificate/:id"
          element={
            <RequireAuth>
              <CertificationForm />
            </RequireAuth>
          }
        />
        <Route
          path="/create-testimonies"
          element={
            <RequireAuth>
              <TestimoniesForm />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-testimonies/:id"
          element={
            <RequireAuth>
              <TestimoniesForm />
            </RequireAuth>
          }
        />
        <Route
          path="/contents"
          element={
            <RequireAuth>
              <Education />
            </RequireAuth>
          }
        />
        <Route
          path="/create-content"
          element={
            <RequireAuth>
              <ContentForm />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-content/:id"
          element={
            <RequireAuth>
              <ContentForm />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown, Table } from "react-bootstrap";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "../../../../util/axiosInst";
import NoDataRow from "../../../components/common/NoDataRow";
import Layout from "../../../components/layout/Layout";

const Post = () => {
  const params = useParams();
  const [blogArticles, setBlogArticles] = useState([]);

  const deleteBlog = async (id, event) => {
    event.preventDefault();
    await axios.delete(`/v1/blog/${id}`);
    Swal.fire({
      icon: "warning",
      title: "Deleted successfully",
    });
    getProviderPost();
  };

  const getProviderPost = () => {
    axios
      .get(`/v1/provider/blogs?limit=10&page=1&createdBy=${params.id}`)
      .then(({ data }) => {
        setBlogArticles(data?.results);
      });
  };

  useEffect(() => {
    getProviderPost();
  }, []);
  return (
    <Layout h1="Provider post">
      <Table className="bg-white ms-1 fs-13px">
        <thead className="border-bottom">
          <th className="text-body-tertiary pt-4 ps-3">TITLE</th>
          <th className="text-body-tertiary pt-4">CATEGORY</th>
          <th className="text-body-tertiary">CREATED DATE</th>
          <th className="text-body-tertiary text-end pe-3">ACTION</th>
        </thead>
        <tbody style={{ borderTop: "0px" }} className="mt-5">
          {blogArticles?.results ? (
            blogArticles?.results?.map((newsLetter) => (
              <tr>
                <td>{newsLetter.title}</td>
                <td>{newsLetter.blogcategories[0].name}</td>
                <td>
                  <span
                    className={
                      newsLetter?.active ? "text-green" : "text-danger"
                    }
                  >
                    {moment(newsLetter.createdAt).format("lll")}
                  </span>
                </td>
                <td className="text-end">
                  <Dropdown>
                    <Dropdown.Toggle className="threedrop" id="dropdown-basic">
                      <BsThreeDotsVertical className="text-body-tertiary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                      <Dropdown.Item
                        onClick={(event) => deleteBlog(newsLetter._id, event)}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : (
            <NoDataRow colSpan={6} data={"No post found"} />
          )}
        </tbody>
      </Table>
    </Layout>
  );
};

export default Post;

import React from "react";
import axios from "../utils/axiosInst";
import ProviderImg from "../assets/images/defaultUserMOP.svg";
import FemaleDrImage from "../images/Female-Dr-Avatar.jpg";
import MaleDrImage from "../images/Male-Dr-Avatar.jpg";
import Logo from "../assets/images/logo_v3.svg";

const Image = ({ src, type = "default", alt = "", gender, ...attributes }) => {
  const [images, setImages] = React.useState([]);
  const getDefaultImage = (type) => {
    if (type === "provider") {
      return gender === "MALE"
        ? MaleDrImage
        : gender === "FEMALE"
        ? FemaleDrImage
        : ProviderImg;
    }
    return attributes.profileType === "profile" ? "" : Logo;
  };

  const getImage = async (id) => {
    try {
      const result = await axios.get(`/v1/s3/signed/url?fileName=${id}`);
      return (result && result.data && result.data.url) || null;
    } catch (err) {
      return null;
    }
  };

  const getImages = async () => {
    const imageArray = [
      (src && (await getImage(src))) || getDefaultImage(type),
    ];
    setImages(imageArray);
  };

  React.useEffect(() => {
    getImages();
  }, [src, type]);
  return images.map((img, i) => {
    return (
      <img
        src={img}
        alt={alt || `mop-img-${i}`}
        key={i}
        {...attributes}
        onError={() => setImages([getDefaultImage(type)])}
      />
    );
  });
};

export default Image;

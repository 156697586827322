import React, { useEffect, useState } from "react";
import Uparrow from "../../assets/images/UpArrow.svg";
import Downarrow from "../../assets/images/DownArrow.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import PatientOverviewTable from "../provider/PatientOverviewTable";
import "react-calendar/dist/Calendar.css";
import axios from "../../utils/axiosInst";
import { Dropdown } from "react-bootstrap";
import moment from "moment/moment";
import CalenderPopup from "../Analytics/CalenderPopup";
import AnalyticsTile from "../custom/AnalyticsTile";

const Overview = ({ search }) => {
  const handleCloserange = () => {
    setrange((values) => ({
      ...values,
      flag: false,
      dateRange: [new Date(), new Date()],
    }));
  };

  const tweleve = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const [range, setrange] = useState({
    flag: false,
    dateRange: [new Date(), new Date()],
  });
  const [tile, setTile] = useState({});
  const [graph, setgraph] = useState([]);
  const [timeFrame, setTimeFrame] = useState({
    value: "This Week",
    time: "week",
    startDate: moment().startOf("week").add(1, "day").toISOString(),
    endDate: moment().endOf("week").add(1, "day").toISOString(),
  });
  const [type, setType] = useState({
    label: "Revenue",
    value: "provider-revenue",
  });

  const getGraphData = async (startDate, endDate, timeFrame) => {
    const { data } = await axios.get(
      `/v1/analytics/admin/graph?startDate=${startDate}&endDate=${endDate}&type=${type.value}&durationType=${timeFrame}`
    );
    setgraph(
      timeFrame === "year"
        ? data.analytics[0]?.counts?.length
          ? data.analytics[0].counts.map((map) => {
              return {
                [type.label]: map.count ? map.count : 0,
                name: map.month,
              };
            })
          : tweleve.map((map) => {
              return {
                [type.label]: 0,
                name: moment()
                  .month(map - 1)
                  .format("MMM"),
              };
            })
        : data.analytics.map((map) => {
            return {
              [type.label]: map.count ? map.count : 0,
              name: moment(map.createdAt).format("D"),
            };
          })
    );
  };
  useEffect(() => {
    axios.get("/v1/analytics/admin").then((res) => {
      setTile(res.data);
    });
  }, []);

  useEffect(() => {
    getGraphData(timeFrame.startDate, timeFrame.endDate, timeFrame.time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <div className="bg-super-light mb-5">
      <div className="bg-white p-3 mt-3 rounded d-flex flex-column px-4 w-100 mb-4">
        <div className="table-drop">
          <div className="d-flex" style={{ float: "right" }}>
            <p className="mt-2">{timeFrame?.value}</p>
            <Dropdown>
              <Dropdown.Toggle className="threedrop " id="dropdown-basic">
                <GiHamburgerMenu
                  className="text-primary"
                  style={{ width: "30px", height: "30px" }}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    getGraphData(
                      moment().startOf("week").add(1, "day").toISOString(),
                      moment().endOf("week").add(1, "day").toISOString(),
                      "week"
                    );
                    setTimeFrame({
                      value: "This Week",
                      time: "week",
                      startDate: moment()
                        .startOf("week")
                        .add(1, "day")
                        .toISOString(),
                      endDate: moment()
                        .endOf("week")
                        .add(1, "day")
                        .toISOString(),
                    });
                  }}
                >
                  This Week
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    getGraphData(
                      moment()
                        .subtract(1, "week")
                        .startOf("week")
                        .add(1, "day")
                        .toISOString(),
                      moment()
                        .subtract(1, "week")
                        .endOf("week")
                        .add(1, "day")
                        .toISOString(),
                      "week"
                    );
                    setTimeFrame({
                      value: "Last Week",
                      startDate: moment()
                        .subtract(1, "week")
                        .startOf("week")
                        .add(1, "day")
                        .toISOString(),
                      time: "week",
                      endDate: moment()
                        .subtract(1, "week")
                        .endOf("week")
                        .add(1, "day")
                        .toISOString(),
                    });
                  }}
                >
                  Last Week
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    getGraphData(
                      moment().startOf("month").add(1, "day").toISOString(),
                      moment().endOf("month").add(1, "day").toISOString(),
                      "month"
                    );
                    setTimeFrame({
                      value: "This Month",
                      startDate: moment()
                        .startOf("month")
                        .add(1, "day")
                        .toISOString(),
                      time: "month",

                      endDate: moment()
                        .endOf("month")
                        .add(1, "day")
                        .toISOString(),
                    });
                  }}
                >
                  This Month
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    getGraphData(
                      moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .add(1, "day")
                        .toISOString(),
                      moment()
                        .subtract(1, "month")
                        .endOf("month")
                        .add(1, "day")
                        .toISOString(),
                      "month"
                    );
                    setTimeFrame({
                      value: "Last Month",
                      startDate: moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .add(1, "day")
                        .toISOString(),
                      time: "month",
                      endDate: moment()
                        .subtract(1, "month")
                        .endOf("month")
                        .add(1, "day")
                        .toISOString(),
                    });
                  }}
                >
                  Last Month
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    getGraphData(
                      moment().startOf("year").add(1, "day").toISOString(),
                      moment().endOf("year").subtract(1, "day").toISOString(),
                      "year"
                    );
                    setTimeFrame({
                      value: "This Year",
                      startDate: moment()
                        .startOf("year")
                        .add(1, "day")
                        .toISOString(),
                      time: "year",
                      endDate: moment()
                        .endOf("year")
                        .subtract(1, "day")
                        .toISOString(),
                    });
                  }}
                >
                  This Year
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    getGraphData(
                      moment()
                        .subtract(1, "year")
                        .startOf("year")
                        .add(1, "day")
                        .toISOString(),
                      moment()
                        .subtract(1, "year")
                        .endOf("year")
                        .subtract(1, "day")
                        .toISOString(),
                      "year"
                    );
                    setTimeFrame({
                      value: "Last Year",
                      time: "year",
                      startDate: moment()
                        .subtract(1, "year")
                        .startOf("year")
                        .add(1, "day")
                        .toISOString(),
                      endDate: moment()
                        .subtract(1, "year")
                        .endOf("year")
                        .subtract(1, "day")
                        .toISOString(),
                    });
                  }}
                >
                  Last Year
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setrange((values) => ({
                      ...values,
                      flag: true,
                    }));
                  }}
                >
                  Select Range
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <h4 className="mb-1 mb-5 fontweight-bold ">{type?.label}</h4>
        </div>
        <span className="d-block pt-2 w-100">
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              data={graph}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid stroke="#e5e5e5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey={type?.label}
                stroke="#6CE5E8"
                fill="none"
                strokeWidth={4}
              />
            </AreaChart>
          </ResponsiveContainer>
        </span>
      </div>
      <div className="row">
        <AnalyticsTile
          type={"Revenue"}
          onClick={() =>
            setType({ label: "Revenue", value: "provider-revenue" })
          }
          value={`$${tile?.providerTotalRevenue}`}
          arrow={
            tile?.providerRevenuePercentage === "Infinity"
              ? ""
              : Number(tile?.providerRevenuePercentage?.replace("%", "")) > 0
              ? Uparrow
              : Downarrow
          }
          percentage={
            tile?.providerRevenuePercentage > 0
              ? tile?.providerRevenuePercentage
              : -1 * tile?.providerRevenuePercentage
          }
        />
        <AnalyticsTile
          type={"Patients Treated"}
          onClick={() =>
            setType({ label: "Patients Treated", value: "appointment" })
          }
          value={tile?.patientTreatedCount}
          arrow={
            tile?.patientTreatedCountPercentage === "Infinity"
              ? ""
              : Number(tile?.patientTreatedCountPercentage?.replace("%", "")) >
                0
              ? Uparrow
              : Downarrow
          }
          percentage={
            tile?.patientTreatedCountPercentage > 0
              ? tile?.patientTreatedCountPercentage
              : -1 * tile?.patientTreatedCountPercentage
          }
        />
        <AnalyticsTile
          type={"Referrals Given"}
          onClick={() =>
            setType({ label: "Referrals Given", value: "referralGiven" })
          }
          value={tile?.referralGivenCount}
          arrow={
            tile?.referralGivenCountPercentage === "Infinity"
              ? ""
              : Number(tile?.referralGivenCountPercentage?.replace("%", "")) > 0
              ? Uparrow
              : Downarrow
          }
          percentage={
            tile?.referralGivenCountPercentage > 0
              ? tile?.referralGivenCountPercentage
              : -1 * tile?.referralGivenCountPercentage
          }
        />
        <AnalyticsTile
          type={"Referrals Received"}
          onClick={() =>
            setType({ label: "Referrals Received", value: "referralReceived" })
          }
          value={tile?.referralReceivedlCount}
          arrow={
            tile?.referralReceivedCountPercentage === "Infinity"
              ? ""
              : Number(
                  tile?.referralReceivedCountPercentage?.replace("%", "")
                ) > 0
              ? Uparrow
              : Downarrow
          }
          percentage={
            tile?.referralReceivedCountPercentage > 0
              ? tile?.referralReceivedCountPercentage
              : -1 * tile?.referralReceivedCountPercentage
          }
        />
      </div>
      <PatientOverviewTable type={type} search={search} />
      {console.log(range)}
      {range.flag && (
        <CalenderPopup
          show={range}
          onHide={handleCloserange}
          setrange={setrange}
          setTimeFrame={setTimeFrame}
          getGraphData={getGraphData}
        />
      )}
    </div>
  );
};

export default Overview;

import React, { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "../../../util/axiosInst";
import {
  createSuccessMessage,
  unknownErrorMessage,
  updateSuccessMessage,
} from "../../../util/constants";
import Layout from "../layout/Layout";

const CertificationForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [editId, setEditId] = useState(null);
  const schema = yup
    .object({
      governingBody: yup.string().required("Governing Body is a required field"),
      certification: yup.string().required("Certification is a required field"),
    })
    .required();
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const { isValid } = useFormState({
    control,
  });
  const getCertificationDetails = async (userId) => {
    setEditId(userId);
    const { data } = await axios.get(`v1/certification/${userId}`);
    delete data.id;
    reset(data);
  };
  const onSubmit = async (formData) => {
    const { governingBody, certification } = formData;

    try {
      if (editId) {
        const { data } = await axios.patch(`/v1/certification/${editId}`, {
          governingBody,
          certification,
        });

        toast.success(updateSuccessMessage);
        navigate("/certifications");

      } else {
        const { data } = await axios.post(`/v1/certification`, {
          governingBody,
          certification,
        });
        toast.success(createSuccessMessage);

        navigate("/certifications");

      }
    }
    catch (e) {
      if (e.response?.data?.message) {
        toast.error(e.response?.data?.message);
      } else {
        toast.error(unknownErrorMessage);
      }
    }
  };
  useEffect(() => {
    if (params?.id) {
      getCertificationDetails(params?.id);
    } else {
      unregister("active");
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout h1={editId
      ? "Edit Certification"
      : "Create Certification"
    }>
      <div className="py-4">
        <div className="container border bg-white py-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="governingBody" className="form-label">
                  Governing Body *
                </label>

                <div>
                  <input
                    className="form-control "
                    placeholder="Enter Governing Body"
                    name="governingBody"
                    {...register("governingBody")}
                  />
                  {errors.governingBody?.message ?
                    <label className="text-danger">
                      {errors.governingBody?.message}
                    </label> : <></>}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="certification" className="form-label">
                  Certification *
                </label>
                <div>
                  <input
                    className="form-control"
                    placeholder="Enter Certification"
                    name="certification"
                    {...register("certification")}
                  />
                  {errors.certification?.message ?
                    <label className="text-danger">
                      {errors.certification?.message}
                    </label> : <></>}
                </div>
              </div>
              <div class="d-flex justify-content-end mt-0">
                <button type="reset" class="mx-2 btn btn-outline-secondary"
                  onClick={() => {
                    navigate("/certifications");
                  }}
                >Cancel</button>
                <button type="submit" class="mx-2 text-white btn btn-primary" disabled={!isValid}>{editId ? "Update" : "Create"}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout >
  )
};

export default CertificationForm;

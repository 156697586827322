import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Layout from "../layout/Layout";
import { FaSearch } from "react-icons/fa";
import ReferralTable from "./ReferralTable";
import { useNavigate } from "react-router-dom";

const Referrals = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Layout h1="Referrals">
      <div
        className="float-right bg-white px-2"
        style={{ paddingTop: "8px", paddingBottom: "6px" }}
      >
        <div className="d-flex me-4">
          {searchTerm.length < 1 && (
            <FaSearch
              style={{
                transform: "translate(93px,5px)",
                color: "#AFAFAF",
              }}
            />
          )}
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="ms-2 px-3 bg-super-light border-0 text-center borderradius-22px me-2"
          />
        </div>
      </div>
      <Tabs
        defaultActiveKey="Created"
        className="pt-1 bg-white fontweight-bold text-black"
        variant="pills"
      >
        <Tab eventKey="Created" title="Referral List">
          <ReferralTable type="create" search={searchTerm} />
        </Tab>
        <Tab eventKey="Completed" title="Completed ">
          <ReferralTable type="completed" search={searchTerm} />
        </Tab>
      </Tabs>
    </Layout>
  );
};

export default Referrals;

import React, { useState } from "react";
import Layout from "../layout/Layout";
import { Tab, Tabs } from "react-bootstrap";
import Created from "./Created";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "../../utils/axiosInst";
import * as XLSX from "xlsx";
import { map } from "lodash";

const NewsRoom = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const downloadExcel = async () => {
    let response;
    response = await axios.get(
      `/v1/blog-news/admin?deleted=false&page=1&limit=100000&sortBy=updatedAt:desc`
    );
    const worksheet = XLSX.utils.json_to_sheet(
      map(response?.data?.docs, ({ title, category, active, createdAt }) => ({
        Title: title,
        Category: category?.name,
        Active: active === true ? "YES" : "NO",
        "Created Date": moment(createdAt).format("ll"),
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `newsroom${moment().format("YYYY_MM_DD")}.xlsx`);
  };
  return (
    <Layout h1="Newsroom">
      <div className="float-right bg-white py-2">
        <div className="d-flex me-4">
          {searchTerm.length < 1 && (
            <FaSearch
              style={{
                transform: "translate(93px,5px)",
                color: "#AFAFAF",
              }}
            />
          )}
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="ms-2 px-3 bg-super-light border-0 text-center borderradius-22px me-2"
          />
          <button
            type="button"
            className="bg-green fontweight-bold btn text-white me-4"
            onClick={() => {
              navigate("/add-latest-blogs");
            }}
          >
            Add
          </button>
          <button
            type="button"
            className="bg-primary fontweight-bold btn text-white"
            onClick={() => {
              downloadExcel();
            }}
          >
            Download
          </button>{" "}
        </div>
      </div>
      <Tabs
        defaultActiveKey="created"
        className="pt-1 bg-white fontweight-bold text-black"
        variant="pills"
      >
        <Tab eventKey="created" title="Created">
          <div className="bg-white mt-3 mx-3 rounded">
            <Created search={searchTerm} />
          </div>
        </Tab>
      </Tabs>
    </Layout>
  );
};

export default NewsRoom;

import React from "react";
import { Modal } from "react-bootstrap";
import { FaRegFilePdf } from "react-icons/fa";
import ProviderImage from "../../assets/images/provider-profile/Dr.BramlettHeadshot.webp";

const Patient = ({ show, handleClose, type }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-between align-items-start ms-2">
            <div className="d-flex flex-column">
              <h2 className="fontweight-bold mb-1">
                {type === "Patient" ? "Patient Info" : "Provider Info"}
              </h2>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-3 pt-0">
        <div
          className={`px-3 text-center`}
          style={{
            height: "500px",
          }}
        >
          <div className="avatar">
            <img
              src={ProviderImage}
              alt="Chat0"
              className="rounded-circle"
              style={{ width: "30%" }}
              loading="lazy"
            />
          </div>
          <h4>Taylor Bramlett</h4>
          <div className="pt-4">
            <div className="row ps-5">
              <div
                className="col-5 text-start"
                style={{ fontSize: "13px", fontWeight: "700" }}
              >
                <p className="text-primary">Individual ID :</p>
                <p className="text-primary">Gender :</p>
                <p className="text-primary">DOB :</p>
                <p className="text-primary">Age :</p>
                <p className="text-primary">Phone :</p>
                <p className="text-primary">Email ID :</p>
                <p className="text-primary">Condition :</p>
                <p className="text-primary">Documents :</p>
              </div>
              <div className="col-7" style={{ fontSize: "13px" }}>
                <p className="text-start">P7908797</p>
                <p className="text-start">Male</p>
                <p className="text-start">11/12/2001</p>
                <p className="text-start">30</p>
                <p className="text-start">+1203323278</p>
                <p className="text-start">taylorbramlett@gmail.com</p>
                <p className="text-start">Knee pain</p>
                <p className="text-start">
                  {" "}
                  <FaRegFilePdf className="width-25px height-25px text-primary ms-2" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Patient;

import React, { useState, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "../../utils/axiosInst";
import moment from "moment";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import Pagination from "../custom/Pagination";

import { PAGE_VARIABLES } from "../../constants/index";
import MOPModal from "../custom/Modal";
import Patientpopup from "../Patientpopup";
import DefaultUserLogo from "../DefaultUserLogo";
import ProviderDetails from "../custom/ProviderDetails/ProviderDetails";

const ReferralTable = ({ type, search }) => {
  // USESTATES
  const [providerPopUp, setProviderPopUp] = useState(false);
  const [myReferrals, setMyReferrals] = useState([]);
  const [refferalDetails, setRefferalDetails] = useState({});
  const [patientpopup, setpatientpopup] = useState(false);
  const [patientpopid, setpatientpopid] = useState();

  // CONSTANT HOOKS
  const navigate = useNavigate();

  // FUNCTIONS

  const handleClose = (setClose) => {
    setClose(false);
    setRefferalDetails({});
  };

  const setpatientpopup_handleClose = () => setpatientpopup(false);

  const getReceivedReferrals = async (page) => {
    const typeCondition = type === "create" ? "" : `&type=${type}`;
    const searchCondition = search?.length ? `&search=${search}` : "";
    const { data } = await axios(
      `/v1/referral/list?limit=10&page=${page}${typeCondition}${searchCondition}`
    );
    setMyReferrals(data);
  };

  const closeReferral = async (id) => {
    const response = await axios.put(`/v1/referral/complete/${id}`);
    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Referral Closed Successfully.",
        text: "Your Referral has been Successfully closed.",
      });
    }
  };

  //USEEFFECT

  useEffect(() => {
    getReceivedReferrals(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="mt-3 mx-3 rounded px-3 bg-white">
      <Table className="ms-1 rounded">
        <thead className="border-bottom fontsize-14px">
          {PAGE_VARIABLES?.REFERRALS_HEAD?.map((head, index) => (
            <th
              className={`text-body-tertiary pb-10px ${head === "NOTE" ? "width-300px" : ""} ${head === "PATIENT" ? "pt-3 ps-4 rounded" : "text-center"}`}
            >
              {head === "REFERRED TO" && type === "received"
                ? "REFERRED BY"
                : head}
            </th>
          ))}
        </thead>
        <tbody style={{ borderTop: "0px" }} className="fontsize-14px">
          {myReferrals && myReferrals?.results?.length ? (
            myReferrals?.results?.map((appointment, index) => (
              <tr
                className={
                  myReferrals.length - 1 !== index ? "border-bottom" : ""
                }
              >
                <td className="rounded py-0 w-25">
                  <div
                    className="cursor-pointer margintop-10px d-flex align-items-center "
                    onClick={() => {
                      setRefferalDetails(appointment);
                      setpatientpopid(appointment?.patientUserId);
                      setpatientpopup(true);
                    }}
                  >
                    <DefaultUserLogo />
                    <p className="mb-2">{appointment?.patientName}</p>
                  </div>
                </td>
                <td className="text-center py-0 align-middle">
                  <p className="margintop--14px mb-0">{appointment?.referralNo}</p>
                </td>
                <td className="text-center py-0 align-middle">
                  <p className="margintop--14px mb-0">
                    {moment(appointment?.date).format("MM/DD/YYYY")}
                  </p>
                </td>
                <td
                  className=" text-center py-0 align-middle"
                  style={{ width: "160px" }}
                  onClick={() => {
                    setProviderPopUp(true);
                    setRefferalDetails(appointment);
                  }}
                >
                  <p className="margintop--14px mb-0 cursor-pointer text-canvablue text-capitalize">
                    {appointment?.referredTo?.name.toLowerCase() ||
                      appointment?.referredBy?.name.toLowerCase()}
                  </p>
                </td>
                <td className=" text-center py-0 align-middle">
                  <p
                    className="margintop--14px mb-0 width-300px text-truncate"
                    title={appointment?.note}
                  >
                    {appointment?.note}
                  </p>
                </td>
                {/* <td className="fontweight-bold h5  table-drop py-2 px-0">
                  <Dropdown className="text-end">
                    <Dropdown.Toggle className="threedrop " id="dropdown-basic">
                      <BsThreeDotsVertical className="text-body-tertiary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          navigate(`/view-referral/${appointment?._id}`, {
                            state: type,
                          })
                        }
                      >
                        {PAGE_VARIABLES?.COMMON?.VIEW}
                      </Dropdown.Item>
                      {type !== "completed" && (
                        <Dropdown.Item
                          onClick={() => closeReferral(appointment?._id)}
                        >
                          {PAGE_VARIABLES?.COMMON?.MARK_COMPLETE}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td> */}
              </tr >
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No referrals
              </td>
            </tr>
          )}
        </tbody >
      </Table >
      <Pagination
        onPageChange={(event) => getReceivedReferrals(event.selected + 1)}
        pageRangeDisplayed={10}
        totalPages={myReferrals?.pagination?.totalCount / 10}
        forcePage={myReferrals?.pagination?.page}
      />
      {
        patientpopup && (
          <Patientpopup
            show={patientpopup}
            handleClose={setpatientpopup_handleClose}
            id={patientpopid}
          />
        )
      }
      <MOPModal
        show={providerPopUp}
        onHide={() => handleClose(setProviderPopUp)}
        dialogClassName="modal-80w overflowX-hidden"
        title="Provider Info"
        size="xl"
        content={
          <ProviderDetails
            id={
              refferalDetails?.referredTo?.slug ||
              refferalDetails?.referredBy?.slug
            }
          />
        }
      />
    </div >
  );
};

export default ReferralTable;

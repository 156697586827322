import React from "react";
import ReactStars from "react-rating-stars-component";
import { BsChat, BsTelephone, BsThreeDotsVertical } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import Pagination from "../custom/Pagination";
import { Dropdown, Table } from "react-bootstrap";
import axios from "../../utils/axiosInst";
import { useState } from "react";
import DefaultUserLogo from "../DefaultUserLogo";
import dayjs from "../../utils/dayjs";
import Patientpopup from "../Patientpopup";
import DownloadApptPDF from "../DownloadApptPDF";
import { useNavigate } from "react-router-dom";

const Completed = ({
  completedppointments,
  getcompletedppointments,
  searchTerm,
}) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("mop-user"));

  const [patientpopid, setpatientpopid] = useState();
  const [patientpopup, setpatientpopup] = useState(false);
  const setpatientpopup_handleClose = () => {
    setpatientpopup(false);
  };

  const [page, setPage] = useState(0);

  const getappointmentdata = async (e) => {
    getcompletedppointments(e, searchTerm);
    setPage(e - 1);
  };

  const message_redirection = async (e) => {
    const { data } = await axios.post("/v1/chat/initiate", {
      userIds: [e?.userId],
      type: "patient-to-provider",
      roomType: "chat",
    });
    navigate("/pro-provider-messages", {
      state: {
        roomId: data.chatRoom.chatRoomId,
        user: {
          ...e,
          userName: e.name,
        },
      },
    });
  };

  return (
    <React.Fragment>
      <div className="row rounded shadow-md p-4 mb-4">
        <Table className="table-borderless mb-5">
          <thead className="border-bottom fs-6 text-center">
            <th className="text-start p-2 ps-4 text-muted"
              style={{ width: "30%" }}>PATIENT</th>
            <th className="p-2 text-muted">TYPE</th>
            {/* PDF file code/* <th className="p-2 text-muted">FILE</th> */}
            <th className="p-2 text-muted">REQUEST DATE & TIME</th>
            <th className="p-2 text-muted text-start">RATINGS</th>
            <th className="p-2 text-muted">STATUS</th>
          </thead>
          <tbody style={{ borderTop: "0px", fontSize: "13px" }}>
            {completedppointments?.docs?.map((appointment, index) => (
              <tr
                className={`${completedppointments?.docs?.length - 1 !== index
                  ? "border-bottom"
                  : ""
                  }`}
              >
                <td className="rounded py-0">
                  <div
                    className="cursor-pointer d-flex align-items-center"
                    style={{ marginTop: "10px" }}
                    onClick={() => {
                      setpatientpopid(appointment?.patient?.userId);
                      setpatientpopup(true);
                    }}
                  >
                    <DefaultUserLogo />
                    <p className="mb-0">{appointment?.patient?.name}</p>
                  </div>
                </td>
                <td className="text-primary text-center p-0">
                  <p
                    style={{ background: "#EBF5FF", color: "#0071FF" }}
                    className="p-1 rounded-pill mt-3 m-0"
                  >
                    {appointment?.contactMode === "VIDEO"
                      ? "Video"
                      : appointment?.contactMode === "AUDIO"
                        ? "Phone"
                        : appointment?.contactMode === "CHAT"
                          ? "Chat"
                          : "Office"}
                  </p>
                </td>
                {/* PDF file code/* <td className="text-primary fontweight-bold text-center p-0 ps-3">
                  <DownloadApptPDF appointment={appointment} />
                </td> */}
                <td className="text-muted fontweight-bold text-center p-0">
                  <p className="margintop--14px pt-3 px-0">
                    {dayjs(appointment?.appointmentDateTime).format(
                      "M/D/YY h:mm A"
                    )}
                  </p>
                </td>
                <td className="text-body-tertiary fontweight-bold text-center p-0">
                  <p className="margintop--14px pt-3">
                    {appointment?.contactMode === "VIDEO" && (
                      <ReactStars
                        stars={5}
                        value={appointment?.rating}
                        isHalf={true}
                        size={20}
                        activeColor="#ffd700"
                        edit={false}
                      />
                    )}
                  </p>
                </td>
                <td className="text-center p-0 text-green">
                  <p className="margintop--14px pt-3 px-0">
                    <span style={{ fontWeight: 700 }}>{appointment?.status}</span>
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {completedppointments?.docs?.length === 0 ? (
          <h3 className="text-center py-5">No Completed Appointments</h3>
        ) : (
          <Pagination
            onPageChange={(event) => {
              window.scrollTo(0, 0);
              getappointmentdata(event.selected + 1);
            }}
            pageRangeDisplayed={window.innerWidth < 769 ? 2 : 5}
            totalPages={completedppointments?.totalPages}
            currentPageNo={page}
          />
        )}
        {patientpopup && (
          <Patientpopup
            show={patientpopup}
            handleClose={setpatientpopup_handleClose}
            id={patientpopid}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Completed;

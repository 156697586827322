import React, { useState } from "react";
import Layout from "../layout/Layout";
import { FaSearch } from "react-icons/fa";
import { Tab, Tabs } from "react-bootstrap";
import axios from "../../utils/axiosInst";
import Pagination from "../custom/Pagination";

const TabComponent = ({
  download,
  add,
  tabs,
  heading,
  defaultActiveKey,
  AddHandler,
  setData,
  search,
  data,
  setSearch,
}) => {
  const [current, setCurrent] = useState("overview");
  const tabswitchfuc = (tab, page, search) => {
    setCurrent(tab);
    if (tab === "request")
      axios
        .get(
          `${tabs[1].apiData}&page=${page}&limit=10${
            search?.length ? "&name=" + search : ""
          }`
        )
        .then((res) => setData(res.data));
    else if (tab === "created")
      axios
        .get(
          `${tabs[2].apiData}&page=${page}&limit=10${
            search?.length ? "&name=" + search : ""
          }`
        )
        .then((res) => setData(res.data));
    else if (tab === "pending")
      axios
        .get(
          `${tabs[3].apiData}&page=${page}&limit=10${
            search?.length ? "&name=" + search : ""
          }`
        )
        .then((res) => setData(res.data));
    else
      axios
        .get(
          `${tabs[4].apiData}&page=${page}&limit=10${
            search?.length ? "&name=" + search : ""
          }`
        )
        .then((res) => setData(res.data));
  };
  return (
    <Layout h1={heading}>
      <div className="float-right bg-white pt-2 pb-1">
        <div className="d-flex">
          <FaSearch
            style={{
              transform: "translate(93px,5px)",
              color: "#AFAFAF",
            }}
          />
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              if (current !== "overview")
                tabswitchfuc(current, 1, e.target.value);
              setSearch(e.target.value);
            }}
            className="ms-2 px-3 bg-super-light border-0 text-center borderradius-22px"
          />
          {add && (
            <button
              type="button"
              className="bg-green fontweight-bold btn text-white ms-3"
            >
              Add
            </button>
          )}
          {download && (
            <button
              type="button"
              className="bg-primary fontweight-bold btn text-white"
              onClick={AddHandler}
            >
              Download
            </button>
          )}
        </div>
      </div>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        className="pt-1 bg-white fontweight-bold fs-18px text-black"
        variant="pills"
        onSelect={(e) => {
          tabswitchfuc(e, 1);
          setSearch("");
        }}
      >
        {tabs.map((map) => (
          <Tab
            eventKey={map.key}
            title={map.title}
            className="fs-6"
          >
            <div className="bg-white mt-3 mx-3 rounded">
              <>
                {map.component}
                {data.totalPages && map.key !== "overview" ? (
                  <Pagination
                    onPageChange={(event) =>
                      tabswitchfuc(map.key, event.selected + 1)
                    }
                    pageRangeDisplayed={10}
                    totalPages={data?.totalPages}
                    currentPageNo={data.page - 1}
                  />
                ) : (
                  <></>
                )}
              </>
            </div>
          </Tab>
        ))}
      </Tabs>
    </Layout>
  );
};

export default TabComponent;

import moment from "moment";
import React from "react";
import { Dropdown, Table } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import Pagination from "../custom/Pagination";

const TableComponent = ({ tableSkeleton, tableData }) => {
  return (
    <div className="bg-white my-3 mx-3 rounded p-3 fs-13px">
      <Table className=" ms-1">
        <thead className="border-bottom">
          {tableSkeleton?.map((column) => (
            <th
              className={`text-body-tertiary text-wrap text-uppercase ${column.thclass || ""
                }`}
            >
              {column?.label}
            </th>
          ))}
        </thead>
        <tbody style={{ borderTop: "0px" }} className="mt-5">
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableSkeleton.map((column, colIndex) => (
                <td
                  key={colIndex}
                  onClick={column.onclick}
                  className={`${column.tdclass || ""} pb-0`}
                >
                  {column.temp ? (
                    column.temp
                  ) : column.clue === "bool" ? (
                    row[column.key] ? (
                      <span className="text-green">YES</span>
                    ) : (
                      <span className="text-danger">NO</span>
                    )
                  ) : column.clue === "moment" ? (
                    moment(row[column.key]).format(column.format)
                  ) : column.key === "action" ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        className="threedrop"
                        id="dropdown-basic"
                      >
                        <BsThreeDotsVertical className="text-body-tertiary" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {column.array.map((map) => (
                          <Dropdown.Item
                            onClick={() => map.action(row.id)}
                          >
                            {map.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    row[column.key]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        // onPageChange={(event) => { getEducation(event.selected + 1) }}
        pageRangeDisplayed={10}
        totalPages={tableData.results?.totalResults / 10}
        forcePage={tableData?.page}
      />
    </div>
  );
};

export default TableComponent;

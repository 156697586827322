import React from "react";

const NoDataRow = ({ colSpan, data }) => {
  return (
    <tr>
      <td colSpan={colSpan} className="text-center">
        {data ? data : "No data found"}
      </td>
    </tr>
  );
};

export default NoDataRow;

import moment from "moment";
import { isUndefined } from "lodash";
const libphonenumber = require("google-libphonenumber");
export const imgAccess = () => {
  return;
};
export const getAge = (dateString) => {
  if (dateString) {
    const today = moment();
    const birthDate = moment(dateString);
    const years = today.diff(birthDate, "years");
    const days = today.diff(birthDate, "days");
    return years === 0 ? `${days} days` : years;
  }
};
export const getName = (provider) => {
  return isUndefined(provider.prefix)
    ? isUndefined(provider.suffix)
      ? provider?.firstName + " " + provider?.lastName
      : provider?.suffix === ""
      ? provider?.firstName + " " + provider?.lastName
      : provider?.firstName +
        " " +
        provider?.lastName +
        " , " +
        provider?.suffix
    : isUndefined(provider.suffix)
    ? provider?.prefix + ". " + provider?.firstName + " " + provider?.lastName
    : provider?.suffix === ""
    ? provider?.prefix + ". " + provider?.firstName + " " + provider?.lastName
    : provider?.prefix +
      ". " +
      provider?.firstName +
      " " +
      provider?.lastName +
      " , " +
      provider?.suffix;
};

export const getProviderName = (provider) => {
  return (
    <span>
      {provider.prefix &&
      provider.prefix.length &&
      provider.prefix.includes(".")
        ? provider.prefix
          ? provider.prefix
          : ""
        : provider.prefix
        ? provider.prefix + ". "
        : ""}
      {provider.firstName &&
        provider.firstName.length &&
        provider.firstName + " "}
      {provider.lastName && provider.lastName.length && provider.lastName}
      {provider.suffix && provider.suffix.length && " , " + provider.suffix}
      {provider.npiId && <> ({provider?.npiId})</>}
    </span>
  );
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);
  if (match) return "1-" + match[1] + "-" + match[2] + "-" + match[3];
  return phoneNumber;
};

export const formatIntlPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber;
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const number = phoneUtil.parse(`+${numericPhoneNumber}`, null);
  const intlFormat = phoneUtil.format(
    number,
    libphonenumber.PhoneNumberFormat.INTERNATIONAL
  );
  return intlFormat;
};
export const getDateFormat = (date) => {
  const todayDate = new Date();
  const days_since_commented = Math.round(
    (todayDate - new Date(date)) / 86400000
  );
  var days_ago_text;
  switch (days_since_commented) {
    case 0:
      days_ago_text = "Today";
      break;
    case 1:
      days_ago_text = "1 day ago";
      break;
    default:
      days_ago_text = `${days_since_commented} days ago`;
  }
  return days_ago_text;
};


export const PAGE_VARIABLES = {
  REFERRALS_HEAD: ["PATIENT", "ID", "DATE", "REFERRED TO", "NOTE"],
  AUTH: JSON.parse(localStorage.getItem("mop-user")),
  COMMON: {
    VIEW: "View",
    MARK_COMPLETE: "Mark Complete",
    CANCEL: "Cancel",
    SUBMIT: "Submit",
  },
  HEALTH_PROFILE: [
    {
      name: "HEALTH CONDITIONS",
      value: "conditionSymptoms",
    },
    {
      name: "HEALTH FILES",
    },
    {
      name: "ALLERGIES",
      value: "allergies",
    },
    {
      name: "CURRENT MEDICATIONS",
      value: "medications",
    },
    {
      name: "SURGERIES",
      value: "treatmentProcedure",
    },
  ],
  BUTTON: {
    TYPE: {
      SUBMIT: "submit",
      RESET: "reset",
    },
    VARIANT: {
      SUCCESS: "success",
      PRIMARY: "primary",
      OUTLINE_SECOUNDARY: "outline-secondary",
    },
  },
};

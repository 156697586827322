import React, { useState } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import MenuBar from "../../assets/images/menu-bars.svg";
import Logo from "../../assets/images/logo_v3 2.svg";
import Logout from "../../assets/images/12.svg";
import { unknownErrorMessage } from "../../../util/constants";
import axios from "../../../util/axiosInst";
import { FaRegComment } from "react-icons/fa";
import { TbSpeakerphone } from "react-icons/tb";
import { ReactComponent as User } from "../../assets/images/Patient-Profile/1.svg";
import { ReactComponent as EducationLibrary } from "../../assets/images/Patient-Profile/6.svg";
import { ReactComponent as Pharmacy } from "../../assets/images/Patient-Profile/11.svg";
import { MdOutlineLocalPostOffice } from "react-icons/md";
import { GrCertificate } from "react-icons/gr";
import { ReactComponent as Home } from "../../assets/provider-icons/1.svg";
import { ReactComponent as Patients } from "../../assets/provider-icons/4.svg";
import { ReactComponent as Appointment } from "../../assets/provider-icons/3.svg";
import { ReactComponent as Referrals } from "../../assets/provider-icons/8.svg";
import { ReactComponent as Schedule } from "../../assets/provider-icons/5.svg";
import { ReactComponent as Analytics } from "../../assets/provider-icons/9.svg";
import { ReactComponent as Certifications } from "../../assets/provider-icons/certifcations.svg";
import { ReactComponent as Newsroom } from "../../assets/provider-icons/horn.svg";
import { ReactComponent as Newsletter } from "../../assets/provider-icons/newsletter.svg";
import { ReactComponent as Testimonies } from "../../assets/provider-icons/testimonials.svg";
import { ReactComponent as Admin } from "../../assets/provider-icons/11.svg";

const SideBar = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const newProviderHeader = [
    {
      pathname: "/home",
      img: (
        <Home
          style={{
            stroke: window.location.pathname.includes("home")
              ? "#0071FF" : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Home",
    },
    {
      pathname: "/patient",
      img: (
        <Patients
          style={{
            stroke: window.location.pathname.includes("patient")
              ? "#0071FF" : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Patients",
    },
    {
      pathname: "/providers",
      img: (
        <User
          style={{
            stroke: window.location.pathname.includes("providers")
              ? "#0071FF" : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Providers",
    },
    {
      pathname: "/appointments",
      img: (
        <Appointment
          style={{
            stroke: window.location.pathname.includes("appointments") ? '#0071FF' : 'black',
            fill: window.location.pathname.includes("appointments") ? '#0071FF' : 'black',
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Appointments",
    },
    {
      pathname: "/referrals",
      img: (
        <Referrals
          style={{
            stroke: window.location.pathname.includes("referrals")
              ? "#0071FF" : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Referrals",
    },
    {
      pathname: "/education",
      img: (
        <EducationLibrary
          style={{
            stroke: window.location.pathname.includes("education")
              ? "#0071FF" : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Education",
    },
    // {
    //   pathname: "/pharmacies",
    //   img: (
    //     <Pharmacy
    //       className={
    //         location.pathname !== "/pharmacies"
    //           ? "fill-black width-20px height-20px"
    //           : "fill-primary width-20px height-20px"
    //       }
    //     />
    //   ),
    //   label: "Pharmacies",
    // },
    {
      pathname: "/certification",
      img: (
        <Certifications
          style={{
            stroke: window.location.pathname.includes("certification")
              ? "#0071FF" : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Certifications",
    },
    {
      pathname: "/newsroom",
      img: (
        <Newsroom
          style={{
            stroke: window.location.pathname.includes("newsroom") ? '#0071FF' : 'black',
            fill: window.location.pathname.includes("newsroom") ? '#0071FF' : 'black',
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Newsroom",
    },
    {
      pathname: "/newsletter",
      img: (
        <Newsletter
          style={{
            stroke: window.location.pathname.includes("newsletter")
              ? "#0071FF" : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Newsletter",
    },
    {
      pathname: "/testimonies",
      img: (
        <Testimonies
          style={{
            stroke: window.location.pathname.includes("testimonies")
              ? "#0071FF" : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Testimonies",
    },
    {
      pathname: "/schedule",
      img: (
        <Schedule
          style={{
            stroke: window.location.pathname.includes("schedule")
              ? "#0071FF" : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Schedule ",
    },
    {
      pathname: "/analytics",
      img: (
        <Analytics
          style={{
            stroke: window.location.pathname.includes("/analytics")
              ? "#0071FF" : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Analytics ",
    },
    {
      pathname: "/admin-staff",
      img: (
        <Admin
          style={{
            stroke: window.location.pathname.includes("admin-staff")
              ? "#0071FF"
              : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Admin",
    },
  ];
  const [collapse, setCollapse] = useState(true);

  const handleLogout = async () => {
    try {
      await axios.post("/v1/auth/logout", {
        refreshToken: JSON.parse(localStorage.getItem("mop-admin-token"))
          .refresh.token,
      });
      localStorage.removeItem("mop-admin-token");
      localStorage.removeItem("mop-admin-user");
      window.location.href = "/login";
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };

  return (
    <div className="d-flex flex-column bg-white border border-top-0 border-bottom-0 border-left-0">
      {collapse ? (
        <img
          alt="mop-logo"
          src={Logo}
          className="mt-3 ms-2"
          width="150px"
          onClick={() => setCollapse(false)}
        />
      ) : (
        <img
          alt="menu-bars"
          src={MenuBar}
          className="mt-3 ms-2 cursor-pointer"
          style={{ marginLeft: "10px" }}
          local={true}
          width={"30px"}
          onClick={() => setCollapse(true)}
        />
      )}
      <div className="d-flex flex-column justify-content-between  overflow-auto h-100">
        <div>
          <ul className="nav nav-pills text-nowrap d-sm-flex d-none flex-column mb-auto">
            {newProviderHeader.map((item, index) => (
              <li className="nav-item p-0">
                <NavLink
                  className="d-flex my-1 align-items-center fw-light"
                  onClick={() => navigate(item?.pathname)}
                  style={{ margin: "0.10rem 0 0.10rem 0" }}
                >
                  {item?.img}
                  {collapse && (
                    <h6
                      className={`ms-3 my-auto fs-6 fontweight-bold ${location.pathname.includes(item?.pathname)
                        ? "text-primary fontweight-bold"
                        : ""
                        }`}
                    >
                      {item.label}
                    </h6>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="nav-item p-0">
          <NavLink
            onClick={handleLogout}
            className="d-flex align-items-center fw-light mt-5"
          >
            <img
              src={Logout}
              local={true}
              width="20px"
              height="20px"
              alt="logout"
            />
            {collapse && (
              <h6 className="ms-3 mt-2 fs-6 fontweight-bold">Log out</h6>
            )}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SideBar;

import React, { useState } from "react";
import Layout from "../layout/Layout";
import { Tab, Tabs } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import * as XLSX from "xlsx";
import { map } from "lodash";
import axios from "../../utils/axiosInst";
import TestimoniesTable from "./TestimoniesTable";

const Testimonies = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const handleTabClick = (index) => {
    setSelectedTab(index);
  };
  const downloadExcel = async () => {
    if (selectedTab === 0) {
      let response;
      response = await axios.get(
        `/v1/testimonial/admin?approved=false&limit=10000&sortBy=updatedAt:desc`
      );
      const worksheet = XLSX.utils.json_to_sheet(
        map(
          response?.data?.results,
          ({ firstName, email, feedback, approved }) => ({
            Name: firstName,
            Email: email,
            FeedBack: feedback,
            Approved: approved === true ? "YES" : "NO",
          })
        )
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `testimonies_signUp_${moment().format("YYYY_MM_DD")}.xlsx`
      );
    } else if (selectedTab === 1) {
      let response;
      response = await axios.get(
        `/v1/testimonial/admin?approved=true&limit=100000&sortBy=updatedAt:desc`
      );
      const worksheet = XLSX.utils.json_to_sheet(
        map(
          response?.data?.results,
          ({ firstName, email, feedback, approved }) => ({
            Name: firstName,
            Email: email,
            FeedBack: feedback,
            Approved: approved === true ? "YES" : "NO",
          })
        )
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `testimonies_approved_${moment().format("YYYY_MM_DD")}.xlsx`
      );
    }
  };
  return (
    <Layout h1="Testimonies">
      <div className="float-right bg-white py-2">
        <div className="d-flex me-4">
          {searchTerm.length < 1 && (
            <FaSearch
              style={{
                transform: "translate(93px,5px)",
                color: "#AFAFAF",
              }}
            />
          )}
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="ms-2 px-3 bg-super-light border-0 text-center borderradius-22px me-2"
          />
          <button
            type="button"
            className="bg-green fontweight-bold btn text-white me-4"
            onClick={() => {
              navigate("/create-testimonies");
            }}
          >
            Add
          </button>
          <button
            type="button"
            className="bg-primary fontweight-bold btn text-white"
            onClick={() => {
              downloadExcel();
            }}
          >
            Download
          </button>
        </div>
      </div>
      <Tabs
        defaultActiveKey={
          location?.state?.length ? location?.state : "Submitted"
        }
        className="pt-1 bg-white fontweight-bold text-black"
        variant="pills"
        onSelect={(key) => setSelectedTab(key === "Submitted" ? 0 : 1)}
      >
        <Tab
          eventKey="Submitted"
          title="Submitted"
          selected={selectedTab === 0}
          onClick={() => handleTabClick(0)}
        >
          <div className="bg-white mt-3 mx-3 rounded">
            <TestimoniesTable
              approved={false}
              search={searchTerm}
              eventKey="Submitted"
            />
          </div>
        </Tab>
        <Tab
          eventKey="Approved"
          title="Approved"
          selected={selectedTab === 1}
          onClick={() => handleTabClick(1)}
        >
          <div className="bg-white mt-3 mx-3 rounded">
            <TestimoniesTable
              approved={true}
              search={searchTerm}
              eventKey="Approved"
            />
          </div>
        </Tab>
      </Tabs>
    </Layout>
  );
};

export default Testimonies;

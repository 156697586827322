import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { loadProgressBar } from "axios-progress-bar";
// import Swal from "sweetalert2";

const url = process.env.REACT_APP_SERVER_URL;
let mopTokenData;
try {
  mopTokenData = JSON.parse(localStorage.getItem("mop-admin-token"));
} catch (error) {}

const getAccessToken = () => {
  return mopTokenData?.access?.token;
};

const instance = axios.create({
  baseURL: url,
  timeout: 30000,
});

instance.interceptors.request.use((request) => {
  request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  return request;
});

const refreshAuthLogic = async (failedRequest) => {
  try {
    const tokenRefreshResponse = await axios.post(
      `${url}/v1/auth/refresh-tokens`,
      { refreshToken: mopTokenData?.refresh?.token },
      {
        headers: {
          Authorization: "Bearer " + mopTokenData?.access?.token,
        },
      }
    );

    if (!tokenRefreshResponse?.data?.access?.token) {
      throw new Error("No JWT");
    }

    localStorage.setItem(
      "mop-admin-token",
      JSON.stringify(tokenRefreshResponse.data)
    );

    mopTokenData = tokenRefreshResponse.data;

    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + tokenRefreshResponse.data.access?.token;
  } catch (error) {
    localStorage.removeItem("mop-admin-token");
    window.location.href = "/";
  }
};

loadProgressBar({}, instance);

createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;

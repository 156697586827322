import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Layout from "../layout/Layout";
import Subscribed from "./Subscribed";
import { FaSearch } from "react-icons/fa";
import Created from "./Created";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import * as XLSX from "xlsx";
import { map } from "lodash";
import axios from "../../utils/axiosInst";
const NewsLetter = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState("subscribe");
  const navigate = useNavigate();
  const handleTabSelect = (tabKey) => {
    setSelectedTab(tabKey);
  };
  const downloadExcel = async () => {
    if (selectedTab === "subscribe") {
      let response;
      response = await axios.get(
        `/v1/news-letter/subscriptions?isSubscribed=true&limit=100000&sortBy=updatedAt:desc`
      );
      const worksheet = XLSX.utils.json_to_sheet(
        map(response?.data?.results, ({ email, isSubscribed, createdAt }) => ({
          Email: email,
          Approved: isSubscribed === true ? "YES" : "NO",
          "Created Date": moment(createdAt).format("ll"),
        }))
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `subscribe_${moment().format("YYYY_MM_DD")}.xlsx`
      );
    } else if (selectedTab === "unsubscribe") {
      let response;
      response = await axios.get(
        `/v1/news-letter/subscriptions?isSubscribed=false&limit=10000&sortBy=updatedAt:desc`
      );
      const worksheet = XLSX.utils.json_to_sheet(
        map(response?.data?.results, ({ email, isSubscribed, createdAt }) => ({
          Email: email,
          Approved: isSubscribed === true ? "YES" : "NO",
          "Created Date": moment(createdAt).format("ll"),
        }))
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `unsubscribe_${moment().format("YYYY_MM_DD")}.xlsx`
      );
    } else {
      let response;
      response = await axios.get(
        `/v1/news-letter/admin?deleted=false&limit=10000&sortBy=updatedAt:desc`
      );
      console.log(response, "erss");
      const worksheet = XLSX.utils.json_to_sheet(
        map(
          response?.data?.results,
          ({ title, categoryId, active, createdAt }) => ({
            Title: title,
            Category: categoryId.name,
            Active: categoryId?.active === true ? "YES" : "NO",
            "Created Date": moment(createdAt).format("ll"),
          })
        )
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `news-letter_${moment().format("YYYY_MM_DD")}.xlsx`
      );
    }
  };
  return (
    <Layout h1="Newsletter">
      <div className="float-right bg-white py-2">
        <div className="d-flex me-4">
          {searchTerm.length < 1 && (
            <FaSearch
              style={{
                transform: "translate(93px,5px)",
                color: "#AFAFAF",
              }}
            />
          )}
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="ms-2 px-3 bg-super-light border-0 text-center borderradius-22px me-2"
          />
          <button
            type="button"
            className="bg-green fontweight-bold btn text-white me-4"
            onClick={() => navigate("/add-newsletter")}
          >
            Add
          </button>
          <button
            type="button"
            className="bg-primary fontweight-bold btn text-white"
            onClick={() => {
              downloadExcel();
            }}
          >
            Download
          </button>{" "}
        </div>
      </div>
      <Tabs
        defaultActiveKey="subscribe"
        className="pt-1 bg-white fontweight-bold text-black"
        variant="pills"
        activeKey={selectedTab}
        onSelect={handleTabSelect}
      >
        <Tab eventKey="subscribe" title="Subscribed">
          <div className="bg-white mt-3 mx-3 rounded">
            <Subscribed isSubscribed={true} search={searchTerm} />
          </div>
        </Tab>
        <Tab eventKey="unsubscribe" title="Unsubscribed">
          <div className="bg-white mt-3 mx-3 rounded">
            <Subscribed isSubscribed={false} search={searchTerm} />
          </div>
        </Tab>
        <Tab eventKey="news" title="Newsletter">
          <div className="bg-white mt-3 mx-3 rounded">
            <Created search={searchTerm} />
          </div>
        </Tab>
      </Tabs>
    </Layout>
  );
};

export default NewsLetter;

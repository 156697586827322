import axios from "../../util/axiosInst";

import { CanvasPreview } from "./CanvasPreview";

let previewUrl = "";

const toBlob = (canvas) => {
  return new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
};

export const ImgPreview = async (
  image,
  crop,
  name,
  scale = 1,
  rotate = 0,
  setUrl,
  setLoading
) => {
  const canvas = document.createElement("canvas");
  CanvasPreview(image, canvas, crop, scale, rotate);
  const blob = await toBlob(canvas);

  if (!blob) {
    console.error("Failed to create blob");
    return "";
  }
  const myFile = new File([blob], name, {
    type: blob.type,
  });

  const params = {
    fileName: `contents/${myFile.name}`,
  };

  const putPreSignedUrlFromAws = (url, file) => {
    fetch(`${url}`, {
      method: "PUT",
      body: file,
    }).then(async () => {
      await getPreSignedUrlFromAws();
    });
  };
  const getPreSignedUrlFromAws = async () => {
    const { data } = await axios.get("/v1/s3/get-presigned-url", { params });
    setUrl(data.url);
    setLoading(false);
  };
  axios
    .get("/v1/s3/put-presigned-url", { params })
    .then(({ data: { url } }) => {
      putPreSignedUrlFromAws(url, myFile);
    });

  return previewUrl;
};

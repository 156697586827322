import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import axios from "../../utils/axiosInst"
import {
  unknownErrorMessage,
} from "../../utils/constants";
import { toast } from "react-toastify";
import Pagination from "../custom/Pagination";

const Subscribed = ({ isSubscribed, search }) => {
  const [contents, setContents] = useState([]);
  const fetchContents = async (page) => {
    try {
      const searchCondition = search?.length ? `email=${search}` : "";
      const { data } = await axios.get(`/v1/news-letter/subscriptions?isSubscribed=${isSubscribed}&${searchCondition}&page=${page}&limit=10&sortBy=updatedAt:desc`)
      setContents(data)
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };
  useEffect(() => {
    fetchContents(1);
  }, [search]);
  return (
    <div>
      <Table className=" ms-1 fs-13px">
        <thead className="border-bottom">
          <th className="text-body-tertiary pt-4 ps-2">EMAIL</th>
          <th className="text-body-tertiary">CREATED DATE</th>
        </thead>
        <tbody style={{ borderTop: "0px" }} className="mt-5">
          {contents && contents?.results && contents && contents?.results.length ? (
            contents && contents?.results.map((newsLetter) => (
              <tr>
                <td>{newsLetter?.email}</td>
                <td>
                  {moment(newsLetter?.createdAt).format("ll")}
                </td>
              </tr>
            )))
            : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data Found
                </td>
              </tr>
            )
          }
        </tbody>
      </Table>
      <Pagination
        onPageChange={(event) => fetchContents(event.selected + 1)}
        pageRangeDisplayed={10}
        totalPages={contents?.totalResults / 10}
        forcePage={contents?.page}
      />
    </div>
  );
};

export default Subscribed;

import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import google from "../../../assets/images/google_reviews_logo.svg";
import axios from "../../../utils/axiosInst";
import Image from "../../custom/Image";
const ProfileTab = ({ providerDetail: { googlePlaceId, userId } }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (userId !== undefined) {
      axios
        .get(`v1/provider/reviews/${userId}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.isReviewsFound !== false) {
              setLoading(false);
              setData(res.data.data);
            } else setLoading(false);
          } else setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId !== undefined]);
  return (
    <div className="p-4 container bg-muted-25 p-md-5 mt-4 p-1 card-scroll ">
      <div className="row mb-3 bg-white rounded-1 ">
        <div className="col-12 pt-md-0 pt-4">
          <div className=" d-md-flex row  py-md-3 px-0 mb-4 rounded">
            <div className="col-md-6 col-7 d-md-flex d-block">
              <Image
                local={true}
                src={google}
                className="w-75  d-md-block d-none mt-2 height-80"
                alt="Google"
              />
              <Image
                local={true}
                src={google}
                className="w-100 d-md-none d-block "
                alt="Google"
              />
            </div>
            {googlePlaceId && data?.rating && (
              <>
                <div className="col-md-6 col-4 d-md-flex  d-none pt-md-3 justify-content-end  flex-grow-1 align-items-center pe-md-4 mt-md-0 ">
                  <a
                    className="btn btn-outline-primary bg-primary  fs-5 text-white fw-semibold py-2 margintop--10px"
                    href={`https://search.google.com/local/writereview?placeid=${googlePlaceId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Write a Review
                  </a>
                </div>
                <div className="col-md-6 d-md-none d-flex col-5 pt-md-3 justify-content-end  flex-grow-1 align-items-center pe-md-4 mt-md-0 ">
                  <a
                    className="btn btn-outline-primary bg-primary  fs-6 text-white fw-semibold"
                    href={`https://search.google.com/local/writereview?placeid=${googlePlaceId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Write a Review
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-12 mb-5 ">
          {loading ? (
            <div className="profile-tab-loading">
              <Spinner animation="border width-3rem height-3rem" />
              <h5 className="my-3">
                Please wait while the google reviews are loading...
              </h5>
            </div>
          ) : data.reviews && data.reviews.length ? (
            <>
              {data?.reviews?.map((values, index) => (
                <div className="row px-3" key={index}>
                  <div className="col-sm-1 col-4 pt-2">
                    <Image
                      local={true}
                      src={values?.author_image}
                      className="rounded-circle w-100"
                      alt={`Providers ${index}`}
                    />
                  </div>
                  <div className="col-sm-11 col-8 ">
                    <div className="d-flex row justify-content-between mb-2">
                      <div className="me-3 col-md-8 col-12">
                        <div className="d-flex  col-12 align-self-center">
                          <div className="d-md-flex d-block">
                            <h5 className="me-2 pt-3 fw-semibold">
                              {values?.author_title || ""}
                            </h5>
                            <div className="pt-md-2">
                              <ReactStars
                                count={5}
                                edit={false}
                                isHalf={true}
                                value={values?.review_rating || 10}
                                size={24}
                                activeColor="#ffd700"
                              />
                            </div>
                          </div>
                        </div>
                        <p className=" mb-0 small text-black">
                          {values?.review_text || ""}
                          <div className="col-sm-11 border-start  ms-3 ps-2 border-secondary my-2   offset-sm-1">
                            <p className="text-black fs-6">
                              {values?.owner_answer || " "}
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="text-center mt-5">
                <a href={data.reviewLink} target="_blank" rel="noreferrer">
                  <button
                    type="button"
                    className="jump-button btn btn-light btn-lg bg-primary text-center rounded-pill border-0  fs-16 text-white padding-12px-35px"
                  >
                    Click here to see more reviews
                  </button>
                </a>
              </div>
            </>
          ) : error ? (
            <div className="text-center fw-bold fs-5 mx-5">
              Please wait retrieving the reviews . If it takes longer time then
              Please refresh the page and retry
            </div>
          ) : (
            <p className="text-center">No Reviews Found</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default ProfileTab;

import React, { createRef, useEffect, useState } from "react";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Image from "../../../utils/Image";
import ContactTab from "./ContactTab";
import HomeTab from "./HomeTab";
import ProfileTab from "./ProfileTab";
import axios from "../../../utils/axiosInst";
import { getProviderName } from "../../../utils/utils";
import withReactContent from "sweetalert2-react-content";
import { isEmpty } from "lodash";
import LoadingScreen from "../LoadingScreen";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

export const MySwal = withReactContent(Swal);
const ProviderDetails = ({ id }) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const ref = createRef();
  const [membershipDetail, setMembershipDetail] = useState({});
  const [providerDetail, setProviderDetail] = useState({});
  const [moreMenu, setMoreMenu] = useState("");
  const [loader, setLoader] = useState(false);
  const [tabKey, settabKey] = useState("");
  const getProvider = async (id) => {
    setProviderDetail({});
    setLoader(true);
    const { data } = await axios.get("/v1/public/provider/" + id + "/detail");
    if (isEmpty(data)) navigate("/404");
    else if (data && data.hasOwnProperty("ratings")) data.rating = data.ratings;
    else if (data && !data.hasOwnProperty("rating")) data.rating = 0;
    if (data.planType !== "premium") {
      settabKey("home");
      setMoreMenu("home");
    } else {
      if (
        data?.userType === "clinic" &&
        data.profile_claimed_status !== undefined &&
        data.profile_claimed_status !== "approved"
      )
        settabKey("contact");
      else settabKey("home");
    }
    setLoader(false);
    setProviderDetail(data);
  };
  const getMembershipDetails = async () => {
    const { data } = await axios.get(
      "/v1/public/membership/plan/" + providerDetail?.membershipId
    );
    setMembershipDetail(data);
  };

  const websiteOpening = (website) => {
    if (!website.includes("https") || !website.includes("http"))
      window.open(`https://${website}`, "_blank").focus();
    else window.open(website, "_blank").focus();
  };

  useEffect(() => {
    sessionStorage.setItem("provider", id);
    getProvider(id);
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    if (providerDetail?.membershipId) getMembershipDetails();
    // eslint-disable-next-line
  }, [providerDetail]);

  const renderContent = (type) => {
    switch (type) {
      case "home":
        return <HomeTab providerDetail={providerDetail} />;
      case "reviews":
        return <ProfileTab providerDetail={providerDetail} />;
      case "contact":
        return <ContactTab providerDetail={providerDetail} />;
      default:
        return <></>;
    }
  };
  return (
    <div className="mt-3" ref={ref}>
      {loader ? (
        <LoadingScreen color="primary" />
      ) : (
        <>
          <section>
            <div className="container px-sm-5">
              <div className="row">
                <div className="col-sm-12">
                  <div className="provider-bg">
                    {providerDetail.banner || providerDetail?._id ? (
                      <Image
                        src={providerDetail.banner}
                        alt="Find a Provider Banner"
                        className={`w-100 banner-image ${
                          providerDetail.banner ? "" : "p-5"
                        } rounded shadow-sm bg-muted-25 p-3`}
                      />
                    ) : (
                      <> </>
                    )}
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="provider-pic w-100">
                      <div className="img-provider ms-md-0 ms-3">
                        {providerDetail?.photo?.includes(
                          "https://dev-mop.s3.us-east-2.amazonaws.com//"
                        ) || !providerDetail?.photo?.length ? (
                          providerDetail?.userId?.length && (
                            <Image
                              src={providerDetail?.photo}
                              type="provider"
                              gender={providerDetail?.gender?.toUpperCase()}
                              alt="Find a Provider"
                            />
                          )
                        ) : (
                          <Image
                            src={providerDetail?.photo}
                            type="provider"
                            gender={providerDetail?.gender?.toUpperCase()}
                            alt="Find a Provider"
                          />
                        )}
                      </div>
                      <div className="profile-provider w-100 mt-2 mb-0">
                        <div className="content ms-md-1 ms-2">
                          <div className="heading fw-bold user-name d-md-flex">
                            {providerDetail?.userType === "hospital" ||
                            providerDetail?.userType === "clinic" ||
                            providerDetail?.userType === "gym" ||
                            providerDetail?.userType === "rehabilitation_center"
                              ? providerDetail?.firstName
                              : getProviderName(providerDetail)}
                            {providerDetail?.mopRecommended && (
                              <i className="fa fa-check-circle text-primary small ms-lg-2 ms-1 mt-2" />
                            )}
                            <div className="heading fw-bold d-flex user-name p-0 ps-md-2">
                              {(providerDetail.profile_claimed_status ===
                                "rejected" ||
                                providerDetail.profile_claimed_status ===
                                  "created") && (
                                <Link
                                  to={"/provider-claim-your-profile"}
                                  className=" text-decoration-none "
                                  state={{
                                    id: providerDetail._id,
                                    userId: providerDetail.userId,
                                    evaluation: location.state?.evaluation,
                                    condition: location.state?.evaluation,
                                    pathName: "/providers/" + params.id,
                                  }}
                                >
                                  Claim Your Profile?
                                </Link>
                              )}
                            </div>
                          </div>
                          <p className="text-muted text-capitalize mb-0">
                            {providerDetail?.userType
                              ? providerDetail?.userType !== "neuro_surgeon"
                                ? "" +
                                  providerDetail?.userType
                                    ?.split("_")
                                    .join(" ") +
                                  ""
                                : "" +
                                  providerDetail?.userType
                                    ?.split("_")
                                    .join("") +
                                  ""
                              : ""}
                          </p>
                          <div className="d-flex">
                            {providerDetail &&
                              providerDetail.hasOwnProperty("rating") && (
                                <ReactStars
                                  count={5}
                                  edit={false}
                                  isHalf={true}
                                  value={Number(providerDetail?.rating) || 0}
                                  size={24}
                                  activeColor="#ffd700"
                                />
                              )}
                            <p className="align-self-center mb-0 text-muted">
                              {providerDetail?.googleRatingsCount
                                ? `(${providerDetail?.googleRatingsCount})`
                                : ""}
                            </p>
                          </div>
                          <div className="d-md-flex d-none align-items-center btn_provider">
                            <div className="d-flex justify-content-between align-items-center text-primary me-4">
                              {providerDetail?.facebook && (
                                <FaFacebook
                                  className="fs-3 me-4 cursor-pointer"
                                  onClick={() => {
                                    websiteOpening(providerDetail?.facebook);
                                  }}
                                />
                              )}
                              {providerDetail?.instagram && (
                                <FaInstagram
                                  className="fs-3 me-4 cursor-pointer"
                                  onClick={() => {
                                    websiteOpening(providerDetail?.instagram);
                                  }}
                                />
                              )}
                              {providerDetail?.youtube && (
                                <FaYoutube
                                  className="fs-3 me-4 cursor-pointer"
                                  onClick={() => {
                                    websiteOpening(providerDetail?.youtube);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container px-4 mt-5">
            <hr
              className={`mx-md-4 ${
                providerDetail.planType === "premium" ? "my-0" : "mt-4"
              } `}
            />
          </div>
          <section>
            <div className="container px-md-5">
              <div className="row">
                <div className="col-12 bg-super-light pt-2  provider-tabs d-md-block d-none">
                  {JSON.stringify(membershipDetail) === "{}" ||
                  membershipDetail?.blogs ? (
                    <Tabs
                      className="mb-3"
                      variant="pills"
                      fill
                      defaultActiveKey={tabKey}
                      onSelect={(key) => {
                        document.getElementById("root").scrollTo(0, 0);
                        settabKey(key);
                      }}
                    >
                      <Tab eventKey="home" title="About">
                        <HomeTab providerDetail={providerDetail} />
                      </Tab>

                      {providerDetail.planType === "premium" && (
                        <Tab eventKey="reviews" title="Reviews">
                          <ProfileTab providerDetail={providerDetail} />
                        </Tab>
                      )}
                      <Tab eventKey="contact" title="Contact & Location">
                        <ContactTab providerDetail={providerDetail} />
                      </Tab>
                    </Tabs>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-12 bg-white pt-2 provider-tabs d-md-none d-block">
                  {JSON.stringify(membershipDetail) === "{}" ||
                  membershipDetail?.blogs ? (
                    providerDetail.planType === "premium" ? (
                      <div className="container-sticky">
                        <div className="w-100">
                          <Tabs
                            className="mb-3 me-5"
                            variant="pills"
                            fill
                            activeKey={tabKey}
                            onSelect={(key) => {
                              document.getElementById("root").scrollTo(0, 0);
                              settabKey(key);
                              setMoreMenu("");
                            }}
                          >
                            <Tab
                              eventKey="home"
                              title="About"
                              className={`${tabKey === "home" ? "active" : ""}`}
                            />
                            {providerDetail.planType === "premium" && (
                              <Tab
                                eventKey="reviews"
                                title="Reviews"
                                className={`${
                                  tabKey === "reviews" ? "active" : ""
                                }`}
                              ></Tab>
                            )}
                          </Tabs>
                        </div>
                        <div className="fw-bold  text-muted p-2 more-button ">
                          <Dropdown>
                            <Dropdown.Toggle
                              className="bg-white text-muted fw-bold border-white p-0 profile-more fs--18"
                              id="dropdown-basic"
                            >
                              More
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="px-2">
                              <Dropdown.Item
                                onClick={() => {
                                  settabKey("");
                                  setMoreMenu("contact");
                                  document
                                    .getElementById("root")
                                    .scrollTo(0, 0);
                                }}
                                className="bg-white text-muted border-white"
                              >
                                <div
                                  className={`fw-bold text-muted ${
                                    moreMenu === "contact"
                                      ? "border-5-bottom"
                                      : ""
                                  }`}
                                >
                                  Contact & Location
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex position-relative">
                        <div className="w-100">
                          <Tabs
                            className="mb-3 me-5"
                            variant="pills"
                            fill
                            activeKey={tabKey}
                            onSelect={(key) => {
                              settabKey(key);
                              document.getElementById("root").scrollTo(0, 0);
                              setMoreMenu("");
                            }}
                          >
                            <Tab
                              eventKey="contact"
                              title="Contact & Location"
                            ></Tab>
                            <Tab eventKey="" title=""></Tab>
                            <Tab eventKey="" title=""></Tab>
                          </Tabs>
                        </div>
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {tabKey !== ""
                    ? renderContent(tabKey)
                    : renderContent(moreMenu)}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};
export default ProviderDetails;

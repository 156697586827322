import React, { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "../../../util/axiosInst";
import {
  createSuccessMessage,
  unknownErrorMessage,
  updateSuccessMessage,
} from "../../../util/constants";
import Layout from "../layout/Layout";

const AdminForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [editId, setEditId] = useState(null);
  const schema = yup
    .object({
      email: yup.string().email("Please enter the email correctly").required("Email is required"),
      password: params?.id ? yup.string() : yup.string().required("Password is required").min(8).matches(
        /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
      firstName: yup.string().required("First name is required").matches(
        /^(?=.*[a-zA-Z])[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
        "Please enter the first name correctly"
      ),
      lastName: yup.string().required("Last name is required").matches(
        /^(?=.*[a-zA-Z])[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
        "Please enter the last name correctly"
      ),
      isEmailVerified: yup.boolean(),
      active: yup.boolean(),
    })
    .required();
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const { isValid } = useFormState({
    control,
  });
  const [active, setactive] = useState(false);
  const getUserDetails = async (userId) => {
    setEditId(userId);
    const { data } = await axios.get(`/v1/user/${userId}`);
    setactive(data.active);
    delete data.id;
    reset(data);
  };
  const onSubmit = async (formData) => {
    const dataToSend = {
      ...formData,
    };
    if (!dataToSend.password) {
      delete dataToSend.password;
    }
    try {
      if (editId) {
        const { data } = await axios.patch(`/v1/user/${editId}`, dataToSend);
        if (data && data.id) {
          toast.success(updateSuccessMessage);

          navigate("/admin-staff");
        }
      } else {
        const { data } = await axios.post("/v1/user/admin", dataToSend);
        toast.success(createSuccessMessage);
        if (data && data.id) {
          navigate("/admin-staff");
        }
      }
    }
    catch (e) {
      if (e.response?.data?.message) {
        toast.error(e.response?.data?.message);
      } else {
        toast.error(unknownErrorMessage);
      }
    }
  };
  useEffect(() => {
    if (params?.id) {
      getUserDetails(params?.id);
    } else {
      unregister("active");
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout h1={editId
      ? "Edit Admin"
      : "Add Admin"
    }>
      <div className="py-4">
        <div className="container border bg-white py-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="firstName" className="form-label">
                  First Name *
                </label>
                <div>
                  <input
                    className="form-control "
                    placeholder="Enter First Name"
                    name="firstName"
                    {...register("firstName")}
                  />
                  {errors.firstName?.message ?
                    <label className="text-danger">
                      {errors.firstName?.message}
                    </label> : <></>}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="lastName" className="form-label">
                  Last Name *
                </label>
                <div>
                  <input
                    className="form-control"
                    placeholder="Enter Last Name"
                    name="lastName"
                    {...register("lastName")}
                  />
                  {errors.lastName?.message ?
                    <label className="text-danger">
                      {errors.lastName?.message}
                    </label> : <></>}
                </div>
              </div>
              <div className="col-md-6 mb-3 position-relative">
                <label htmlFor="email" className="form-label">
                  Email *
                </label>
                <div>
                  <input
                    className="form-control"
                    label="Email"
                    name="email"
                    placeholder="Enter Email"
                    {...register("email")}
                  />
                  {errors.email?.message ?
                    <label className="text-danger">
                      {errors.email?.message}
                    </label> : <></>}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="Password" className="form-label">
                  Password {params?.id ? "" : "*"}
                </label>
                <div>
                  <input
                    className="form-control"
                    placeholder="Enter Password"
                    name="Password"
                    {...register("password")}
                  />
                  {errors.password?.message ?
                    <label className="text-danger">
                      {errors.password?.message}
                    </label> : <></>}
                </div>
              </div>
              {editId && (
                <>
                  <div class="form-check form-switch ps-0">
                    <input class="form-check-input ms-3" type="checkbox"
                      onClick={(e) => {
                        setValue("active", e.target.checked);
                        setactive(e.target.checked);
                      }}
                      checked={active} />
                    <label class="form-check-label" for="flexSwitchCheckChecked"> Active </label>
                  </div>
                </>
              )}
              <div class="d-flex justify-content-end mt-0">
                <button type="reset" class="mx-2 btn btn-outline-secondary"
                  onClick={() => {
                    navigate("/admin-staff");
                  }}
                >Cancel</button>
                {editId ? <button type="submit" class="mx-2 text-white btn btn-primary"> Update</button> : <button type="submit" class="mx-2 text-white btn btn-primary" disabled={!isValid}>Create</button>}

              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout >
  )
};

export default AdminForm;

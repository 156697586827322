import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import axios from "../../../util/axiosInst";
import Layout from "../layout/Layout";
import { FaSearch } from "react-icons/fa";
import Pagination from "../custom/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
const Results = ({ }) => {
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const params = useParams();
  const [page, setPage] = useState(1)
  const getEducation = (page) => {
    axios.get(`/v1/evaluation/history?patientId=${params.id}&limit=10&page=${page}&sortBy=createdAt`)
      .then((res) => {
        setPage(res?.data?.page)
        setCurrentPage(page)
        setResults(res?.data);
      });
  };
  useEffect(() => {
    getEducation(1);
  }, []);
  return (
    <Layout h1="Symptom Checker Results">
      <IoMdArrowRoundBack className="cursor-pointer ms-3 h3 mt-2" onClick={() => {
        navigate("/patient");
      }} />
      <div className="mt-3 mx-3 rounded px-3 bg-white">
        <Table className=" ms-1 fs-13px">
          <thead className="border-bottom">
            <th className="text-body-tertiary pt-4 ps-2 text-uppercase">Evaluation Muscle Group</th>
            <th className="text-body-tertiary text-uppercase">Age</th>
            <th className="text-body-tertiary text-uppercase">Gender</th>
            <th className="text-body-tertiary text-uppercase">Conditions & Confidence</th>
            <th className="text-body-tertiary pe-5 text-uppercase">Evaluation Date</th>
          </thead>
          <tbody style={{ borderTop: "0px" }} className="mt-5">
            {results && results?.results && results?.results.length ?
              (results && results?.results && results?.results.map((results) => (
                < tr >
                  <td className="text-justify">{results?.result?.label}</td>
                  <td>{results?.request?.responses?.age}</td>
                  <td className="text-justify text-capitalize">{results?.request?.responses?.gender}</td>
                  <td className="text-justify">   {results?.result?.conditions?.map((i) => (
                    <>
                      {i.name} - {i.confidence}%<br />
                    </>
                  ))}</td>
                  <td className="text-justify">
                    {moment(results?.createdAt).format("ll")}
                  </td>
                  {/* <td className="text-end">
                  <BsThreeDotsVertical className="text-body-tertiary" />
                </td> */}
                </tr>)))
              : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
          </tbody>
        </Table >
        <Pagination
          onPageChange={(event) => { getEducation(event.selected + 1) }}
          pageRangeDisplayed={10}
          totalPages={results?.totalPages}
          currentPageNo={currentPage - 1}
        />
      </div >
    </Layout>
  );
};
export default Results;
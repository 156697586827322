import React from "react";
import { Modal } from "react-bootstrap";
import Calendar from "react-calendar";

const CalenderPopup = ({
  show,
  setrange,
  onHide,
  setTimeFrame,
  getGraphData,
}) => {
  const handleDateChange = (date) => {
    setrange((values) => ({
      ...values,
      dateRange: date,
    }));
  };

  const customRange = (startDate, endDate) => {
    setTimeFrame("Selected Range");
    let sdate = new Date(startDate);
    let ldate = new Date(endDate);

    sdate.setDate(sdate.getDate() + 1);
    ldate.setDate(ldate.getDate() + 1);
    getGraphData(sdate.toISOString(), ldate.toISOString(), "month");
    onHide();
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body className="px-3 pt-0">
        <div className="p-2">
          <Calendar
            onChange={handleDateChange}
            value={show.dateRange}
            selectRange={true}
            className={"w-100 border-0"}
          />
          <div className="mt-3 text-center text-primary fw-700">
            {show.dateRange[0].toLocaleDateString()} -{" "}
            {show.dateRange[1].toLocaleDateString()}
          </div>
        </div>
        <div className="mt-3">
          <div className="d-flex flex-wrap w-100">
            <div className="d-flex flex-column me-1" style={{ width: "47.5%" }}>
              <button
                className=" form-control bg-primary text-white fw-600"
                type="button"
                onClick={() => {
                  customRange(show.dateRange[0], show.dateRange[1]);
                }}
              >
                Get data
              </button>
            </div>
            <div
              className="d-flex flex-column ms-lg-1"
              style={{ width: "47.5%" }}
            >
              <button
                className=" form-control fw-600"
                type="button"
                onClick={onHide}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CalenderPopup;

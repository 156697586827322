import React, { useState } from "react";
import ProvidersTable from "../../components/provider/ProvidersTable";
import TabComponent from "../../components/common/TabComponent";
import Overview from "../../components/provider/Overview";
import { useNavigate } from "react-router-dom";

const Providers = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const tabs = [
    {
      key: "overview",
      title: "Overview",
      component: <Overview search={search} />,
    },
    {
      key: "request",
      title: "Request",
      apiData:
        "v1/provider/adminlist?planType=premium&active=false&sortBy=updatedAt:desc",
      component: <ProvidersTable active={true} data={data} />,
    },
    {
      key: "created",
      title: "Created",
      apiData:
        "v1/provider/adminlist?isMOPAdminCreated=true&profile_claimed_status=created,rejected&deleted=false&sortBy=updatedAt:desc",
      component: <ProvidersTable data={data} />,
    },
    {
      key: "pending",
      title: "Pending",
      apiData: "v1/provider/adminlist?active=true&sortBy=updatedAt:desc",
      component: <ProvidersTable data={data} />,
    },
    {
      key: "active",
      title: "Active User",
      apiData:
        "v1/provider/adminlist?deleted=false&pending_singup=true&profile_claimed_status=approved&sortingType=1&sortBy=updatedAt:desc",
      component: <ProvidersTable data={data} />,
    },
  ];

  return (
    <TabComponent
      defaultActiveKey="overview"
      heading="Providers"
      tabs={tabs}
      add={true}
      setSearch={setSearch}
      setData={setData}
      data={data}
      search={search}
      AddHandler={() => navigate("/add-providers")}
    />
  );
};

export default Providers;

import React from "react";
import SideBar from "./Sidebar";

const Layout = ({ children, h1, download, downloadOnclick }) => {
  return (
    <div className="d-flex m-0 h-100 bg-f5f5f7">
      <SideBar />
      <div className={`flex-fill overflow-auto vh-100`}>
        <div className="referral-tabs">
          {download && (
            <div
              className="float-right bg-white px-2 pt-3"
              style={{ paddingTop: "11.5px", paddingBottom: "11px" }}
            >
              <button
                type="button"
                className="btn btn-primary text-white  px-3"
                onClick={downloadOnclick}
              >
                Download
              </button>
            </div>
          )}
          <h3
            className="fontweight-bold px-4 py-3 bg-white"
            style={{
              marginBottom: "0",
              borderBottom: "1px solid rgb(235 235 235)",
            }}
          >
            {h1}
          </h3>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;

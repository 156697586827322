import React, { useEffect, useState } from "react";
import { useForm, useFormState, useWatch } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "../../utils/axiosInst";
import Swal from "sweetalert2";
import {
  createSuccessMessage,
  unknownErrorMessage,
  updateSuccessMessage,
} from "../../../util/constants";
import Layout from "../layout/Layout";
import Image from "../custom/Image"
import ImageCropModal from "../../../Components/image/ImageCropModal";

const TestimoniesForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const [imgSrc, setImgSrc] = useState("");
  const [fileSrc, setFileSrc] = useState("");
  const schema = yup.object({
    email: params?.id ? yup.string() : yup.string().email("Please enter the email correctly").required("email is required").nullable(),
    feedback: yup.string().nullable(),
    name: yup.string().required("name is required").matches(
      /^(?=.*[a-zA-Z])[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
      "Please enter the name correctly"
    ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  console.log(errors, "errorserrorserrors");
  useEffect(() => {
    if (params.id) {
      getProvider();
    }
    // eslint-disable-next-line
  }, []);

  const getProvider = async () => {
    const { data: claimRequestData } = await axios.get(
      `v1/testimonial/${params.id}`
    );

    setValue("name", claimRequestData?.firstName);
    setValue("feedback", claimRequestData?.feedback);
    setValue("banner", claimRequestData?.image);
    setValue("approved", claimRequestData.approved);
  };

  const onSubmit = async (formData) => {
    try {
      if (params?.id) {
        const { status } = await axios.patch(`v1/testimonial/${params.id}`, {
          approved: getValues("approved"),
          feedback: getValues("feedback"),
          image: getValues("banner"),
        });
        if (status === 200 || status === 201) {
          navigate(`/testimonies`, { state: location?.state });
          Swal.fire({
            icon: "success",
            title: `Updated Successfully.`,
            // text: "Your changes have been saved",
          });
        }
      } else {
        const name = getValues("name")
        const image = getValues("banner")
        setValue('firstName', name)
        setValue('image', image)
        const { status } = await axios.post("/v1/testimonial", formData);
        if (status === 200 || status === 201) {
          navigate(`/testimonies`, { state: location?.state });
          Swal.fire({
            icon: "success",
            title: "Created successfully.",
            wordbreak: "break-word",
          });
        }
      }
    } catch (e) {
      if (e.response?.data?.message) {
        toast.error(e.response?.data?.message);
      } else {
        toast.error(unknownErrorMessage);
      }
    }
  };
  const BannerImage = ({ control }) => {
    const banner = useWatch({
      control,
      name: "banner",
    });
    return (
      <div>
        <div>
          {banner && (
            <span role="button">
              <Image
                src={banner}
                alt={banner}
                width={"100%"}
                height={"300px"}
              />
            </span>
          )}
          <div>
            {!banner && (
              <label
                htmlFor="banner"
                className={`w-100 ${banner ? "" : "min-h-75"}`}
              >
                <span
                  role="button"
                  type="button"
                  className={`w-100 ${banner ? "" : "min-h-75"}`}
                >
                  <>
                    <label className="mb-1 w-100" style={{ cursor: "pointer" }}>
                      Set Thumnail
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept={"image/*"}
                        onChange={(e) => onSelectFile(e)}
                      />
                      <div className="border-dotted min-h-75  border rounded p-3 d-flex align-items-center justify-content-center flex-fill">
                        <div className="text-center text-light">
                          <h1>
                            <i className="fa fa-image" />
                          </h1>
                          <p className="mb-0">Upload an image</p>
                        </div>
                      </div>
                    </label>
                  </>
                </span>
              </label>
            )}
            {banner && (
              <button
                className={`btn
                  btn-danger mt-3
               `}
                onClick={() => {
                  setValue("banner", "");
                }}
                type={"button"}
              >
                Remove
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      setFileSrc(e.target.files[0]);
      document.querySelector("#imgCrop").classList.add("show");
      document.querySelector("#imgCrop").style.display = "block";
    }
  };
  return (
    <Layout h1="Testimonies">
      <div className="py-4">
        <section className="pb-3 h-100">
          <ImageCropModal
            setValue={setValue}
            imgSrc={imgSrc}
            fileSrc={fileSrc}
          />
          <form>
            <div className="container">
              <div className="row mt-3">
                <div className="col-md-9">
                  <div className="bg-white border rounded p-3">
                    <div className="row mt-3">
                      <div className="col-md-4 mb-3">
                        <label for="" className="form-label fw-semibold">
                          Name *
                        </label>
                        <div>
                          <input
                            className="form-control"
                            {...register("name")}
                            label="NAME"
                            mandatory={true}
                            disabled={params?.id ? true : false}
                            name="name"
                          />
                          {errors.name?.message ?
                            <label className="text-danger">
                              {errors.name?.message}
                            </label> : <></>}
                        </div>
                      </div>
                      {!params?.id && <div className="row mt-3">
                        <div className="col-md-4 mb-3">
                          <label for="" className="form-label fw-semibold">
                            Email *
                          </label>
                          <div>
                            <input
                              className="form-control"
                              errors={errors}
                              {...register("email")}
                              label="EMAIL"
                              name="email"
                            />
                            {errors.email?.message ?
                              <label className="text-danger">
                                {errors.email?.message}
                              </label> : <></>}
                          </div>
                        </div>
                      </div>}
                      <div className="col-md-12 col-sm-12 mb-3 fw-semibold col-12 align-self-center">
                        <label for="" className="form-label fw-semibold">
                          Feedback Message *
                        </label>
                        <textarea
                          name=""
                          id=""
                          cols="4"
                          rows="5"
                          className="form-control flex-fill"
                          {...register("feedback")}
                        ></textarea>
                      </div>
                      <div className="col-3">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            {...register("approved")}
                            type="checkbox"
                            id="approved"
                            className="custom-control-input"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="approved"
                          >
                            Approved
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-5"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="border rounded bg-white mb-3 p-3">
                    <div className="d-flex flex-column">
                      <BannerImage control={control} />
                      <button
                        className={`btn
                            btn-outline-danger
                         mt-4`}
                        onClick={() => {
                          navigate("/testimonies", { state: location?.state })
                        }}
                        type={"button"}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary mt-4"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Publish
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>

    </Layout >
  );
};

export default TestimoniesForm;